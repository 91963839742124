<app-header></app-header>
<div [appViewController]="{report:'firstmileFailed'}">
<img src="/assets/img/bg3.jpg" alt="" class="bg2">

<div class="row">
    <div class="col-12">
        <form [formGroup]="reportForm" class="container mat-card mb-6 mt-4 shadow bg-white rounded">
            <br>
            <p class="h4" style="text-align: center; margin-top: 10px; margin-bottom: 18px;">{{'First Mile Failed'|translate}}</p>
            <br>
            <div class="form-row">
                <div class="form-group col-md-4">
                    <label for="lockername">{{'Country' | translate}}</label>
                    <select name="city" class="form-control" formControlName='country'
                        (change)="onChange($event.target.value)">
                        <option value="" disabled selected>{{'Choose an option' | translate}}</option>
                        <option *ngFor="let country of countries" [value]="country.id">{{country.name}}</option>
                    </select>
                    <small *ngIf="submitted &&  formValidators.country.errors?.required"
                        class="form-text text-muted col-sm-8" style="padding-left: 0px;">{{'Please Fill Country'|translate}}</small>
                </div>
                <div class="form-group col-md-4">
                    <label for="address">{{'Parcel Aborted'|translate}}</label>
                    <select name="city" class="form-control" formControlName='softReservation'
                        (change)="onChange($event.target.value)">
                        <option value="" disabled selected>{{'Choose an option' | translate}}</option>
                        <option *ngFor="let reserve of reserves" [value]="reserve.name">{{reserve.name}}</option>
                    </select>
                    <small *ngIf="submitted &&  formValidators.softReservation.errors?.required"
                        class="form-text text-muted col-sm-8" style="padding-left: 0px;">{{'Please fill Reserve Lm in Fm safe'|translate}}</small>
                </div>
                <div class="form-group col-md-4">
                    <label for="acCode">{{'Business Partners'|translate}}</label>
                    <select name="city" class="form-control" formControlName='businessesPartner'
                        (change)="onChange($event.target.value)">
                        <option value="" disabled selected>{{'Choose an option' | translate}}</option>
                        <option *ngFor="let business of partnerNamedropdownList" [value]="business.id">{{business.name}}
                        </option>
                    </select>
                    <small *ngIf="submitted &&  formValidators.businessesPartner.errors?.required"
                        class="form-text text-muted col-sm-8" style="padding-left: 0px;">{{'Please fill Business
                        Partners'|translate}}</small>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-4">
                    <label for="lockername">{{'From Date' | translate}}</label>
                    <!-- <input type="date" [max]="maxDate" formControlName="fromDate" class="form-control"> -->
                    <div class="container">
                        <div class="row">
                          <div class="input-group">
                            <input
                              style="height: 35px;"
                              type="text"
                              placeholder="dd-mm-yyyy"
                              class="form-control"
                              formControlName="fromDate"
                              [maxDate]="maxDate"
                              #fromDateInput
                              bsDatepicker
                              [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY'}">
                              <!-- bsDaterangepicker -->
                              <div class="input-group-append">
                                <span class="input-group-text">
                                  <i class="fas fa-calendar-alt" (click)="openDatePicker('fromDateInput')"></i>
                                </span>
                              </div>
                          </div>
                        </div>
                      </div>
                    <small *ngIf="submitted &&  formValidators.fromDate.errors?.required"
                        class="form-text text-muted col-sm-8">{{'Please fill From Date'|translate}}</small>
                </div>
                <div class="form-group col-md-4">
                    <label for="lockername">{{'To Date' | translate}}</label>
                    <!-- <input type="date" [max]="maxDate" formControlName="toDate" class="form-control"> -->
                    <div class="container">
                        <div class="row">
                          <div class="input-group">
                            <input
                              style="height: 35px;"
                              type="text"
                              placeholder="dd-mm-yyyy"
                              class="form-control"
                              formControlName="toDate"
                              [maxDate]="maxDate"
                              #toDateInput
                              bsDatepicker
                              [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY'}">
                              <!-- bsDaterangepicker -->
                              <div class="input-group-append">
                                <span class="input-group-text">
                                  <i class="fas fa-calendar-alt" (click)="openDatePicker('toDateInput')"></i>
                                </span>
                              </div>
                          </div>
                        </div>
                      </div>
                    <small *ngIf="submitted &&  formValidators.toDate.errors?.required"
                        class="form-text text-muted col-sm-8">{{'Please Fill To Date'|translate}}</small>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col text-center">
                        <button class="btn btn-danger mb-3 mr-3"
                            (click)="exportButton()">{{'Export'|translate}}</button>
                        <button class="btn btn-outline-danger mb-3" (click)="onReset()">{{'Reset'|translate}}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
</div>
