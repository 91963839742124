import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PartnerService } from 'src/app/Service/partner.service';
import { FormGroup, FormControl, FormBuilder, FormArray, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr'
import { LangtransService } from '../../Service/langtrans.service';
import { AppService } from '../../Service/app.service';
@Component({
  selector: 'app-partner-create-new-order',
  templateUrl: './partner-create-new-order.component.html',
  styleUrls: ['./partner-create-new-order.component.scss']
})
export class PartnerCreateNewOrderComponent implements OnInit {

  public newOrderForm
  submitted:boolean=false
  lang: any;
  constructor(public router: Router,
    private partnerservice : PartnerService,
    private toastr: ToastrService,
    private appService: AppService,
    private langtransService: LangtransService,) { }

  ngOnInit(): void {
    this.newOrderForm= new FormGroup({
      email: new FormControl('',[Validators.required,Validators.email]),
      emailConfirm:new FormControl('',[Validators.required,Validators.email])
    })
    this.appService.languagetoggle$.subscribe((data: any) => {
      this.lang = data
    })
  }

  get formValidators() {
    return this.newOrderForm.controls;
  }

  partnerOrder()
  {
    this.submitted=true
    if(this.newOrderForm.valid)
    {
    if(this.newOrderForm.value.email!=this.newOrderForm.value.emailConfirm)
    {
      this.langaugeFetch(this.lang,'Email mismatch, Please try again','warning')
      //this.toastr.warning("Email mismatch, Please try again","Sorry")
    }
    else{
    this.partnerservice.setEmail(this.newOrderForm.value.email)
    this.router.navigate(['partnertools/partnerOrder/','add'])
    }
  }
}
langaugeFetch(lang, key, toastrstatus) {
  this.langtransService.TranslateData(lang, key).subscribe(
    res => {
      if (toastrstatus == 'warning') {
        this.toastr.warning(res)
      }
    }
  )
}
}
