import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { DeliverypointsService } from 'src/app/Service/deliverypoints.service';
import { PartnerService } from 'src/app/Service/partner.service';
import { UserService } from 'src/app/Service/user.service';
import { SpinnerService } from 'src/app/Service/spinner.service';
@Component({
  selector: 'app-partner-new-order',
  templateUrl: './partner-new-order.component.html',
  styleUrls: ['./partner-new-order.component.scss']
})
export class PartnerNewOrderComponent implements OnInit {
  storedData: any
  result: any
  userInfo: any;
  commonObj: any;
  locationData: any = [];
  lockerNotFree: boolean = false;
  loadingData: boolean = true
  disableButton:boolean=true
  constructor(
    public router: Router,
    private partnerservice: PartnerService,
    private _userService: UserService,
    private deliverypointservice: DeliverypointsService,
    private spinner: SpinnerService,
    private partnerService: PartnerService,
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.commonObj = JSON.parse(localStorage.getItem('partnerDetails'))?.name;
    this.storedData = JSON.parse(window.localStorage.getItem('storedValue'))
    this.getPartnerResponse()
    this.userInfo = JSON.parse(localStorage.getItem('userData'))
    this.setLocation()
    // this.parterCheck()
    //this.fetchLocation()

  }
  fetchLocation(){
    this.partnerService.fetchPartnerDeliverypointDetailsDefaultPartnerId().subscribe((res:any)=>{

      if(res.hasCapacity){
        this.disableButton=false;
      }
      else{
        this.disableButton=true;
        this.loadingData=false;
      }
    },(error)=>{
      this.loadingData=false
      this.disableButton = true
    })
  }
 
  getPartnerResponse() {
    this.partnerservice.getPartnerInfo().pipe(map((orders: Array<any>) => {
      return orders.map((order: any) => {
        let retObj = { ...order }
        retObj.orderDate = order.orderDate ? order.orderDate : '';
        retObj.reservePeriodFrom = order.reservePeriodFrom ? order.reservePeriodFrom : '';
        retObj.reservePeriodTo = order.reservePeriodTo ? order.reservePeriodTo : '';
        return retObj;
      })
    }))
      .subscribe(res => {
        this.result = res;
      })
  }
  newOrder() {
    if (this.commonObj && this.commonObj == "J&J") {
      this.router.navigate(['partnertools/createBarcode/'])
    } else {
      // this.router.navigate(['partnertools/createBarcode'])
      this.router.navigate(['partnertools/createOrder/'])
    }
  }
  parterCheck() {
    this._userService.fetchMany({}).subscribe((res: any) => {
      this.commonObj = res.find(item => item.email == this.userInfo.email)
    })
  }
  editOrder(response: any) {
   
    this.router.navigate(['partnertools/partnerOrder/', 'edit'],{ 
      state: { data: response } 
    })


  }
  goToOrderDetails(index: any) {
    this.router.navigate(['partnertools/partnerOrder/', index + 1])
  }

  setLocation() {
  let obj = {checkCapacity:1}
  this.spinner.show();
  this.partnerservice.fetchPartnerDeliverypointDetailswithPartnerId(obj).subscribe(
      (res: any) => {
      this.spinner.hide();
      if(res.hasCapacity){
        this.lockerNotFree=false;
        this.disableButton=false;
      }
      else{
        this.lockerNotFree=true;
        this.disableButton=true;
      }
      this.loadingData=false;
      },
      err => {
        this.spinner.hide();
        console.error(err);
        this.loadingData=false;
        this.disableButton=false;
        if(err.error.code==="DDS_NOT_FOUND"){
        this.lockerNotFree=true;
      }
      }
    )
  }

  // async checkLockerSpace(val: any) {
  //   let promises = val.map(value => this.deliverypointservice.fetchwithId(value,
  //     {
  //       includeTerminals: true,
  //       includeCompartments: true
  //     }))

  //   Promise.all(promises.map(p => p.then(value => ({ status: 'fulfilled', value })).catch(error => ({ status: 'rejected', reason: error }))))
  //     .then((res: any) => {
  //       let successfullResponse = res.filter(res => res.status == 'fulfilled')
  //       let successVal = successfullResponse.map(res => res.value)
  //       let count = 0;
  //       for (let a = 0; a < successVal.length; a++) {
  //         let terminals = successVal[a].terminals

  //         for (let i = 0; i < terminals.length; i++) {
  //           for (let j = 0; j < terminals[i].compartments.length; j++) {
  //             if (terminals[i].compartments[j].active) {
  //               if (terminals[i].compartments[j].status == null || terminals[i].compartments[j].status == 'free' || terminals[i].compartments[j].status == '') {
  //                 count = count + 1;
  //                 break
  //               }
  //             }
  //           }
  //           if (count != 0) {
  //             break
  //           }
  //         }
  //         if (count != 0) {
  //           break
  //         }
  //       }
  //       if (count == 0) {
  //         this.lockerNotFree = true
  //       }
  //       else {
  //         this.lockerNotFree = false
  //       }
  //       this.loadingData = false
  //       this.spinner.hide();
  //     })
  // }

}
