import { Directive, Input, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appLengthLimit]'
})
export class LengthLimitDirective {
  // @Input() maxLength: number = 4;
  constructor(private el: ElementRef) { }
  // @HostListener('input') onInput() {
  //   let inputValue: string = this.el.nativeElement.value;
  //   if (inputValue.length > this.maxLength) {
  //     this.el.nativeElement.value = inputValue.substr(0, this.maxLength);
  //   }
  // }
  @HostListener('input', ['$event']) onInput(event: any): void {
    const initialValue: string = this.el.nativeElement.value;
    const cleanValue: string = initialValue.replace(/[^0-9]/g, '').slice(0, 3);
    this.el.nativeElement.value = cleanValue;
    event.preventDefault();
  }
 }
