import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PartnerService } from 'src/app/Service/partner.service';
import { ParcelService } from 'src/app/Service/parcel.service';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { LangtransService } from 'src/app/Service/langtrans.service'
import { AppService } from 'src/app/Service/app.service';
import { SpinnerService } from 'src/app/Service/spinner.service';

@Component({
  selector: 'app-partner-orderto-process',
  templateUrl: './partner-orderto-process.component.html',
  styleUrls: ['./partner-orderto-process.component.scss']
})
export class PartnerOrdertoProcessComponent implements OnInit {
  orderToProcess: any
  translateData: any;
  orderFound: boolean = true;
  constructor(
    private partnerservice: PartnerService,
    private router: Router,
    private ParcelService: ParcelService,
    private toastr: ToastrService,
    private langtransService: LangtransService,
    private appService: AppService,
    private spinner: SpinnerService

  ) { }

  ngOnInit(): void {
    this.getpartnerOrderToProcess();
    this.appService.languagetoggle$.subscribe((data: any) => {
      this.translateData = data
    })
  }

  getpartnerOrderToProcess() {
    this.spinner.show();
    this.partnerservice.getPartnerOrderToProcess()
      .pipe(map((orders: Array<any>) => {
        this.spinner.hide();
        return orders.map((order: any) => {
          let retObj = { ...order }
          retObj.reservePeriodFrom = order.reservePeriodFrom ? order.reservePeriodFrom.split('Z').join('') : '';
          retObj.reservePeriodTo = order.reservePeriodTo ? order.reservePeriodTo.split('Z').join('') : '';
          retObj.loadedAt = order.loadedAt ? order.loadedAt.split('Z').join('') : '';
          return retObj;
        })
      }))
      .subscribe(res => {
        if (res.length == 0) {
          this.orderFound = false
        }
        else {
          this.orderFound = true
        }
        this.orderToProcess = res
      }, err => {
        console.error(err);
        this.spinner.hide();
      })
  }
  viewPage(obj) {
    this.partnerservice.setEmail(obj.email)
    this.partnerservice.setEdit(obj)
    this.router.navigate(["/partnertools/partnerOrder/process", +obj.reserveid])

  }
  removeOrder(val) {
    let cancelOrderBody = {
      action: "CANCEL"
    }
    this.spinner.show();
    this.ParcelService.postRetrievalCode(val.reserveid, cancelOrderBody).subscribe(
      res => {
        this.spinner.hide();
        if (res) {
          const index = this.orderToProcess.indexOf(val)
          if (index !== -1) {
            this.orderToProcess.splice(index, 1)
          }
          this.langaugeFetch(this.translateData, 'ParcelRemoved', 'success')
        }
      },
      err => {
        this.spinner.hide();
        console.error(err);
        this.langaugeFetch(this.translateData, 'FailedRemove', 'error');

      }
    )
  }
  langaugeFetch(lang, key, toastrstatus) {
    this.langtransService.TranslateData(lang, key).subscribe(
      res => {
        if (toastrstatus == 'success') {
          this.toastr.success(res)
        }
        if (toastrstatus == 'error') {
          this.toastr.error(res)
        }
      }
    )
  }
}
