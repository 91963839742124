import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ddsEnvironment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class LogsService {

  constructor(
    private http: HttpClient
  ) { }

  fetchLogs(id: any) {
    return this.http.get(
      `${ddsEnvironment.baseUrl}/deliverypoints/` + id + `/logs`)
  }
  filterLogs(id: any, queryString?: any) {
    Object.keys(queryString).forEach(key => queryString[key] === undefined ? delete queryString[key] : {});
    return this.http.get(
      `${ddsEnvironment.baseUrl}/deliverypoints/` + id + `/logs`, {
      params: queryString
    })
  }
  lockerDown(id: any, body: any) {
    return this.http.post(
      `${ddsEnvironment.baseUrl}/deliverypoints/` + id+`/logs`, body)
  }
}
