import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { SpinnerService } from 'src/app/Service/spinner.service';
import { UserService } from 'src/app/Service/user.service';
@Component({
  selector: 'app-usergroupaddedit',
  templateUrl: './usergroupaddedit.component.html',
  styleUrls: ['./usergroupaddedit.component.scss']
})
export class UsergroupaddeditComponent implements OnInit {
  title: any = ''
  checklist: any
  titleKeys: any;
  groupHeading: any;
  selectAll: any = false;
  constructor(
    private user: UserService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private spinner: SpinnerService
  ) { }
  ngOnInit(): void {
    let currentAction = this.activatedRoute.snapshot.paramMap.get('action')
    if (currentAction == 'edit') {
      let group = this.activatedRoute.snapshot.paramMap.get('group')
      this.user.getUsergroup().subscribe(res => {
        let groupData: any = res
        let filteredVal = groupData.filter((element) => {
          if (element.userGroups != null) {
            if (element.userGroups.title == group) {
              return element
            }
          }
        })
        this.checklist = filteredVal[0].userGroups.checklistData;
        this.title = filteredVal[0].userGroups.title
        this.setTitleKeys()
      },
        err => {
          console.error("THE ERROR IS", err);
        })
    }
    else {
      console.log(this.user.checklistData)
      this.checklist = this.user.checklistData;
      this.setTitleKeys()
    }
  }
  setTitleKeys() {
    this.titleKeys = this.checklist.map((obj) => {
      for (let i in obj) {
        return i
      }
    })
    this.groupHeading = {}
    for (let i = 0; i < this.titleKeys.length; i++) {
      this.groupHeading[this.titleKeys[i]] = false
    }

    this.checklist.forEach((object, index) => {
      let falseCount = 0
      for (let key in object[this.titleKeys[index]]) {
        if (object[this.titleKeys[index]][key] == false) {
          falseCount = falseCount + 1
        }

      }
      if (falseCount == 0) {
        this.groupHeading[this.titleKeys[index]] = true
      }

    })

    this.checkForSelectAll()


  }

  checkForSelectAll() {
    let checkFalse = 0;
    for (let key in this.groupHeading) {
      if (this.groupHeading[key] == false) {
        checkFalse = checkFalse + 1
      }
    }
    if (checkFalse == 0) {
      this.selectAll = true
    }
  }
  innerGroup(value: any, index: any, event: any) {
    if (!event.target.checked) {
      this.groupHeading[value] = false
      this.selectAll = false;
    }
    else {
      let falseCount = 0;
      for (let key in this.checklist[index][value]) {
        if (this.checklist[index][value][key] == false) {
          falseCount = falseCount + 1;
        }
      }
      if (falseCount == 0) {
        this.groupHeading[value] = true
      }
      this.checkForSelectAll()
    }
  }
  selectGroup(value: any, index: any, event: any) {
    if (event.target.checked) {
      for (let key in this.checklist[index][value]) {
        this.checklist[index][value][key] = true
      }
      this.checkForSelectAll()
    }
    else {
      this.selectAll = false
      for (let key in this.checklist[index][value]) {
        this.checklist[index][value][key] = false
      }
    }
  }
  getObjectKeys(item: any, index: any): any[] {
    return Object.keys(item[this.titleKeys[index]])
  }
  selectall(event: any) {
    if (event.target.checked) {
      this.checklist.forEach((obj, index) => {
        for (let key in obj[this.titleKeys[index]]) {
          obj[this.titleKeys[index]][key] = true
        }
      })
      for (let j in this.groupHeading) {
        this.groupHeading[j] = true;
      }
    }
    else {
      this.checklist.forEach((obj, index) => {
        for (let key in obj[this.titleKeys[index]]) {
          obj[this.titleKeys[index]][key] = false
        }
      })
      for (let j in this.groupHeading) {
        this.groupHeading[j] = false;
      }
    }
  }
  saveDetails() {
    let falseKey = true;
    let trueCount = 0
    this.checklist.forEach((object, index) => {

      for (let key in object[this.titleKeys[index]]) {
        if (object[this.titleKeys[index]][key] == true) {
          trueCount = trueCount + 1
        }
      }

    })
    if (trueCount == 0) {
      falseKey = false
    }
    let body = {
      title: this.title || null,
      checklistData: this.checklist,
      allPrivilegesFalse: falseKey
    }
    this.spinner.show();
    this.user.addUsergroup(body).subscribe(
      res => {
        window.localStorage.removeItem('currentRoles')
        window.localStorage.removeItem('currentGroups')
        this.setGroups();
        this.spinner.hide();
        // this.router.navigate(['user/usergroup'])
      },
      err => {
        this.spinner.hide();
        console.error("THE ERROR IS", err)

      })
  }
  setGroups() {
    let userEmail: any
    let userData = JSON.parse(window.localStorage.getItem('userData'))
    if (userData.email == null || userData.email == undefined) {
      userEmail = userData.mail
    }
    else {
      userEmail = userData.email
    }
    let filterVal= {
      email: userEmail.toLowerCase(),
      name: undefined,
      groups: undefined,
    }
    this.spinner.show();
    forkJoin([this.user.fetchMany(filterVal), this.user.getUsergroup()]).subscribe((res: any[]) => {
      this.spinner.hide();
      let userRole: any = res[0]
      let userDetails = userRole.find(o => o.email === userEmail);

      let currentUserRoles = userDetails.groups.map(element => element.name)
      window.localStorage.setItem('currentRoles', JSON.stringify(currentUserRoles))
      window.localStorage.setItem('currentGroups', JSON.stringify(res[1]))
      this.router.navigate(['user/usergroup'])
      //  if(currentUserRoles.includes("partnertoolgroup"))
      // {
      //   if(currentUserRoles.length==1)
      //   {
      //     this.router.navigate(['partnertools']);
      //   }
      //   else{
      //     this.router.navigate(['parcels']);
      //   }

      // }
      // else{
      //   this.router.navigate(['parcels']);
      // }
    },err => {
      console.error(err);
      this.spinner.hide();
    })
  }
  cancel = () => {
    this.router.navigate(['/user/usergroup'])
  }
}