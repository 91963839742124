<app-header></app-header>
<form id="form-area" class="login-form">
  <span style="font-size:20px;color:#fff">
    <span>
      <img class="logo" alt="" src="/assets/img/Bpost_icon.ico" style="display: inline-block;">
    </span>
  </span><br><br>
  <br />
</form>

