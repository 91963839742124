<div class="mx-5 mt-5"  [appViewController]="{partnerTool:'viewPartnerTool'}">
    <h2 style="color: red;" > {{'New Orders'|translate}}</h2>
    <div *ngIf="!loadingData && !lockerNotFree" style="background-color: #d4e9b1;" class="px-3 py-3">
        <h5>{{'Lockers Available'|translate}}</h5>
        <p>{{'Lockers are available for new orders. Click on one of the buttons below to create or place orders.'|translate}}</p>
        </div>
     <div *ngIf="!loadingData && lockerNotFree" style="background-color: red;" class="px-3 py-3">
          <h5>{{'Sorry! lockers are not available'|translate}}</h5>
          <p>{{'Currently lockers are not available, try again later'|translate}}</p>

          </div>

        <div *ngIf="loadingData" style="background-color: lightgray;" class="px-3 py-3">
          <h5>{{'checking For locker Availability'|translate}}</h5>
          <p>{{'Please wait we are checking on the availibility of locker'|translate}}</p>
          </div>
        <button class="btn btn-danger mt-3" (click)="newOrder()" [disabled]="disableButton">{{'Create New Order'|translate}}</button>



    <div class="mt-5 mb-5">
      <div class="table-responsive">
        <table class="table table-bordered">
          <caption></caption>
            <thead>
              <tr>
                <th scope="col"  colspan="5">{{'Overview of new orders'|translate}}</th>

              </tr>
            </thead>
            <thead>
                <tr class="table-info">
                  <th scope="col">{{'Order Date'|translate}}</th>
                  <th scope="col">{{'When to collect'|translate}}</th>
                  <th scope="col">{{'Name'|translate}}</th>
                  <th scope="col">{{'Status'|translate}}</th>
                  <th scope="col">{{'Options'|translate}}</th>
                </tr>
              </thead>
            <tbody>
              <tr *ngFor="let value of result; let i = index">
                <td>{{value.orderDate | date:'dd-MM-yyyy'}}</td>
                <td>{{value.reservePeriodFrom | date:'dd-MM-yyyy'}} - {{value.reservePeriodTo | date:'dd-MM-yyyy'}}</td>
                <td>Anonymous, anonymous customer</td>
                <td>Registered</td>
                <td><button class="btn btn-danger" (click)="editOrder(value)">{{'See More'|translate}}</button></td>

                <!-- api here -->

              </tr>
              <tr *ngIf="!result">
                <td style="text-align: center;" colspan="5">
                  {{'No Records Found'|translate}}
                </td>
              </tr>

            </tbody>
          </table>
        </div>


    </div>

</div>
