<app-header></app-header>

<img src="/assets/img/bg2.jpg" alt="" class="bg2">
<div [appViewController]="{report:'downTimeReport'}">

  <!-- <div class="card shadow mt-4 mb-4 bg-white rounded" style="width: 105%; height: 110%; margin-right: 20px;"> -->
  <!-- <h2 style="margin-left: 6%; margin-top: 2%;">Downtime Report</h2> -->
  <form [formGroup]="lockerdownForm" class="container mat-card mb-6 mt-4 shadow bg-white rounded">
    <br>
    <p class="h4" style="text-align: center; margin-top: 10px; margin-bottom: 18px;">{{'Downtime Report'|translate}}</p>
    <div class="form-row">
      <div class="form-group col-md-3 dropdown" style="margin-left: 12%;">
        <label>{{'Location'|translate}}</label>
        <ng-multiselect-dropdown class="form-control-sm" formControlName="dropDown"
                [placeholder]="'-All-'|translate" [settings]="locationDropdownSetttings" [data]="locationdropdownList"
                [(ngModel)]="locationSelectedItems" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
          </ng-multiselect-dropdown>
        <small *ngIf="formValidators.dropDown.errors?.required" class="form-text text-muted col-sm-8"
          style="padding-left: 0px;">{{'Please select the location'|translate}}</small>
      </div>
      <div class="form-group col-md-6">
        <div>
          <div class="form-row card-body p-0">
            <div class="form-group col-md-6 mb-0">
              <label for="orderDate">{{'Log date from'|translate}}</label>
              <!-- <input class="form-control input-width" formControlName="logDateFrom" name="logDateFrom" type="date"
                [max]="getToday()"> -->
                <div class="container">
                  <div class="row">
                    <div class="input-group">
                      <input
                        style="height: 35px;"
                        type="text"
                        placeholder="dd-mm-yyyy"
                        class="form-control"
                        formControlName="logDateFrom"
                        [maxDate]="maxDate"
                        #logDateFromInput
                        bsDatepicker
                        [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY'}">
                        <!-- bsDaterangepicker -->
                        <div class="input-group-append">
                          <span class="input-group-text">
                            <i class="fas fa-calendar-alt" (click)="openDatePicker('logDateFromInput')"></i>
                          </span>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="form-group col-md-6 mb-0">
              <label for="orderDate">{{'Log date to'|translate}}</label>
              <!-- <input class="form-control input-width" formControlName="logDateTo" name="logDateTo" type="date"
                [max]="getToday()"> -->
                <div class="container">
                  <div class="row">
                    <div class="input-group">
                      <input
                        style="height: 35px;"
                        type="text"
                        placeholder="dd-mm-yyyy"
                        class="form-control"
                        formControlName="logDateTo"
                        [maxDate]="maxDate"
                        #logDateToInput
                        bsDatepicker
                        [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY'}">
                        <!-- bsDaterangepicker -->
                        <div class="input-group-append">
                          <span class="input-group-text">
                            <i class="fas fa-calendar-alt" (click)="openDatePicker('logDateToInput')"></i>
                          </span>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    
    </div>
    <div class="container">
      <div class="row">
        <div class="col text-center mb-3">
          <button class="btn btn-danger mb-3 mr-3" type="submit"
            (click)="runExport()">{{'Export'|translate}}</button>
          <button class="btn btn-outline-danger mb-3" (click)="onReset()">{{'Reset'|translate}}</button>
        </div>
      </div>
    </div>
  </form>
</div>
