import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LogsService } from '../../../Service/logs.service';
import { ActivatedRoute, Router } from '@angular/router'
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment'
import { DatePipe } from '@angular/common';
import { SpinnerService } from 'src/app/Service/spinner.service';
import { AppService } from '../../../Service/app.service';
import { LangtransService } from '../../../Service/langtrans.service';
@Component({
  selector: 'app-logs',
  templateUrl: './connection-logs.component.html',
  styleUrls: ['./connection-logs.component.scss']
})
export class ConnectivityLogsComponent implements OnInit {
  id: any
  queryStringEvent: any
  logdetails: any
  //dtOptions: DataTables.Settings = { pagingType: 'full_numbers', pageLength: 50, destroy: true, search: false, stateSave: true, order: [] };
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  startDownData: any[];
  endDownData: any[];
  startDown: any[];
  endDown: any[];
  showSpinner: boolean = true
  differenceArray: number[] = [];
  duration: any[]
  mergedArray: string[][]
  showUnauthorized: boolean = false;
  @ViewChild('myDiv', { static: true }) myDiv: ElementRef;

  displayArr: any = [];
  lang: any;

  constructor(
    public activatedRoute: ActivatedRoute,
    private logservice: LogsService,
    public router: Router,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private spinner: SpinnerService,
    private langtransService: LangtransService,
    private appService: AppService,
  ) {
  }

  ngOnInit() {
    this.appService.languagetoggle$.subscribe((data: any) => {
      this.lang = data
    })
    this.displayArr = [];
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      stateSave: true,
      order: [],
      // columnDefs: [{type: 'date',targets: [0,1]}]
      // destroy: true,
    };

    this.id = this.activatedRoute.snapshot.paramMap.get('id')
    this.queryStringEvent = "CONNECTION";
    let params = {
      event: this.queryStringEvent || undefined
    }
    this.spinner.show();
    this.logservice.filterLogs(this.id, params).subscribe(
      (res: any) => {
        if (res) {
          this.spinner.hide();

          // if res.$.downtime_seconds is bigger than 300, run 
          // let durationval = this.convertSeconds(this.differenceArray[ind]);

          this.displayArr = res.map(e => {
            return {
              ...e,
              startDown: e.disconnected_at ? moment(e.disconnected_at).format('YYYY-MM-DD HH:mm:ss') : null,
              endDown: e.reconnected_at ? moment(e.reconnected_at).format('YYYY-MM-DD HH:mm:ss') : null,
              duration: e.downtime_seconds ? this.convertSeconds(e.downtime_seconds) : null
            }
          })
        
        } else {
          this.langaugeFetch(this.lang,'No Matching Data Found !!','info')
          //this.toastr.info('No Matching Data Found !!', 'Not Found')
          this.spinner.hide();
        }
      },
      err => {
        console.error(err);
        this.spinner.hide();
      }
    )

  }

  ngAfterViewInit() {
    let divElement = this.myDiv.nativeElement;
    let divstyle = window.getComputedStyle(divElement)
    let display = divstyle?.display;
    if (display == 'none') {
      this.showUnauthorized = true
    }
  }

  convertSeconds(seconds: number): string {
    let days = null
    if (seconds > 86400) {
      days = Math.floor(seconds / 86400);
      days = days + ' days,';
    }
    const date = new Date(null);
    date.setSeconds(seconds);
    date.toISOString().substr(11, 8);
    //return date.toISOString().substr(11, 8)
    if (days) {
      return days + ' ' + date.toISOString().substr(11, 8)
    } else {
      return date.toISOString().substr(11, 8)
    }
  }

  convert(): string[] {
    return this.differenceArray.map((seconds => this.convertSeconds(seconds)))
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  langaugeFetch(lang, key, toastrstatus) {
    this.langtransService.TranslateData(lang, key).subscribe(
      res => {
        if (toastrstatus == 'info') {
          this.toastr.info(res)
        }
      }
    )
  }


}
