import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { PartnerService } from 'src/app/Service/partner.service';
import { SpinnerService } from 'src/app/Service/spinner.service';

@Component({
  selector: 'app-partner-order-vault',
  templateUrl: './partner-order-vault.component.html',
  styleUrls: ['./partner-order-vault.component.scss']
})
export class PartnerOrderVaultComponent implements OnInit {

  vaultResponse: any
  orderFound: boolean = true;
  constructor(
    private partnerservice: PartnerService,
    private router: Router,
    private spinner: SpinnerService

  ) { }

  ngOnInit(): void {
    this.getPartnerVault();
  }
  getPartnerVault() {
    this.spinner.show();
    this.partnerservice.getPartnerVault()
      .pipe(map((orders: Array<any>) => {
        this.spinner.hide();
        return orders.map((order: any) => {
          let retObj = { ...order }
          retObj.reservePeriodFrom = order.reservePeriodFrom ? order.reservePeriodFrom.split('Z').join('') : '';
          retObj.reservePeriodTo = order.reservePeriodTo ? order.reservePeriodTo.split('Z').join('') : '';
          retObj.loadedAt = order.loadedAt ? order.loadedAt.split('Z').join('') : '';
          return retObj;
        })
      }))
      .subscribe(res => {
        if (res.length == 0) {
          this.orderFound = false
        }
        else {
          this.orderFound = true
        }
        this.vaultResponse = res;
      }, err => {
        this.spinner.hide();
      })
  }

  viewPage(obj) {
    this.partnerservice.setEmail(obj.email)
    this.partnerservice.setEdit(obj)
    this.router.navigate(["/partnertools/partnerOrder/vault", +obj.id])

  }

}
