import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router'
import { UserService } from '../../../Service/user.service';
import { FormGroup, FormControl, FormBuilder, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {Location} from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-details-preview',
  templateUrl: './user-details-preview.component.html',
  styleUrls: ['./user-details-preview.component.scss']
})
export class UserDetailsPreviewComponent implements OnInit {
  userId:any
  userdetails:any
  deleteId:any
  id:any
  constructor(
    private _location: Location,
    public activatedRoute: ActivatedRoute,
    private userService: UserService,
    public router: Router,
    private FB: FormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.userId = this.activatedRoute.snapshot.paramMap.get('id')
    console.log(this.userId)
    this.fetchUserDetails(this.userId)
  }
  fetchUserDetails(userId) {
    this.userService.fetchById(userId).subscribe(
      res => {
        this.userdetails = res
      },
      err => {
        console.error(err);
      }
    )
  }
  onDeleteConfirm(){
    this.userService.deleteUser(this.userId).subscribe(
      res => {
        this._location.back();
      },
      err => {
        this.toastr.error(this.translate.instant('Deleting User Failed !!'),this.translate.instant('Failed'));
        console.error(err);
      }
    )
  }
  onEdit(){
      this.router.navigate(['/updateuser/' + this.userId]);
  }
  goToUsersList() {
    this.router.navigate(['/user/search']);
  }
}



