<app-header></app-header>
<img src="/assets/img/bg3.jpg" alt="" class="bg2">
<br>
<div *ngIf="showLoader" class="text-center">
    <div class="text-center">
        <app-loader></app-loader>
    </div>
</div>

<form [formGroup]="addUserForm" *ngIf="groups" class="pl-4 pr-4" [appViewController]="{users:'addUser'}">
   
    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 mx-auto">
        <div class="card shadow p-3 mb-5 bg-white rounded" style="width: 100%; margin: 10px;">
            <div class="card-body p-3">
                <div>
                    <div class="col text-right">
                        <h2 class=" card-title col text-center">{{'Add User'|translate}}</h2>
                    </div>

                </div>
                <div class="col-12 text-secondary">
                    <hr>

                    <div class="row ">
                        <div class="col-6 pr-0">Type*</div>
                        <div class="col-6 text-right pl-0">
                            <select class="form-control" name="type" formControlName="userType" [(ngModel)]="selectedUserType" (change)="onTypeChange()">
                                <option  value="internal">Internal</option>
                                <option value="external">External</option>
                               
                            </select>
                            
                            
                        </div>
                    </div>
                    <!-- <div *ngIf="selectedUserType=='internal'">
                    <hr>
                    

                    <div class="row ">
                        <div class="col-6 pr-0">{{'Name'|translate}}*</div>
                        <div class="col-6 text-right pl-0">
                            <input [ngClass]="F.userName.errors?.required  &&  F.userName.touched ? 'error':'' "
                                type="text" name="name" class="form-control" formControlName="userName" id="userName"
                                placeholder="{{'enter user name'|translate}}">
                            <span class="help-block">
                                <span *ngIf="submitted && addUserForm.controls.userName.errors">
                                    <div *ngIf="addUserForm.controls.userName.errors.required"
                                        class="error-text text-danger mt-1">{{'name is required'|translate}}</div>
                                </span>
                            </span>
                        </div>
                    </div>
                </div> -->
                <!-- *ngIf="selectedUserType=='external'" -->
                <div >
                    <hr>
                    

                    <div class="row ">
                        <div class="col-6 pr-0">{{'First name'|translate}}*</div>
                        <div class="col-6 text-right pl-0">
                            <input 
                                type="text" name="userfirstName" class="form-control" formControlName="userfirstName"  id="userfirstName"
                                placeholder="{{'enter first name'|translate}}">
                            <span class="help-block">
                                <span *ngIf="submitted && addUserForm.controls.userfirstName.errors">
                                    <div *ngIf="addUserForm.controls.userfirstName.errors.required"
                                        class="error-text text-danger mt-1">{{'FirstName is required'|translate}}</div>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
                <!-- *ngIf="selectedUserType=='external'" -->
                <div >
                    <hr>
                    <div class="row ">
                        <div class="col-6 pr-0">{{'Last name'|translate}}*</div>
                        <div class="col-6 text-right pl-0">
                            <input 
                                type="text" name="userlastName" class="form-control"  id="userlastName"
                                placeholder="{{'enter last name'|translate}}" formControlName="userlastName">
                            <span class="help-block">
                                <span *ngIf="submitted && addUserForm.controls.userlastName.errors">
                                    <div *ngIf="addUserForm.controls.userlastName.errors.required"
                                        class="error-text text-danger mt-1">{{'LastName is required'|translate}}</div>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>

                

                    <hr>
                    <div class="row  ">
                        <div class="col-6 pr-0">{{'Email'|translate}}*</div>
                        <div class="col-6 text-right pl-0">

                            <input [ngClass]="F.userEmail.errors?.required  &&  F.userEmail.touched ? 'error':'' "
                                type="email" name="email" ngModel [email]="true" class="form-control"
                                formControlName="userEmail" id="userEmail" placeholder="eg: user@bpost.be">
                            <span class="help-block">
                                <span *ngIf="submitted && addUserForm.controls.userEmail.errors">
                                    <div *ngIf="addUserForm.controls.userEmail.errors.required"
                                        class="error-text text-danger mt-1">{{'email is required'|translate}}</div>
                                    <div *ngIf="addUserForm.controls.userEmail.errors.pattern"
                                        class="error-text text-danger mt-1">{{'please enter a valid email address'|translate}}</div>
                                </span>
                            </span>
                        </div>
                    </div>
                    <!-- <div *ngIf="selectedUserType=='external'">
                    <hr>
                  
                    <div class="row  "  >
                        <div class="col-6 pr-0">Confirm Email</div>
                        <div class="col-6 text-right pl-0">

                            <input 
                                type="email" name="Confirmemail"  class="form-control"
                                formControlName="userConfirmEmail" id="userConfirmEmail" placeholder="eg: user@bpost.be">
                            <span class="help-block">
                                <span *ngIf="submitted && addUserForm.controls.userConfirmEmail.errors">
                                    <div *ngIf="addUserForm.controls.userConfirmEmail.errors.required"
                                        class="error-text text-danger mt-1">{{'email is required'|translate}}</div> -->
                                    <!-- <div *ngIf="addUserForm.controls.userEmail.errors.pattern"
                                        class="error-text text-danger mt-1">{{'please enter a valid email address'|translate}}</div> -->
                                <!-- </span>
                            </span>
                        </div>
                    </div>
                </div> -->
                
            <!-- <div *ngIf="selectedUserType=='external'"> -->
                <hr>
              
                <div class="row  "  >
                    <div class="col-6 pr-0">{{'Mobile number'|translate}}</div>
                    <div class="col-6 text-right pl-0">

                        <input 
                            type="text" name="usermobileNumber"  class="form-control"
                            formControlName="usermobileNumber" id="usermobileNumber" placeholder="enter mobile number" maxlength="15" (keypress)="restrictAlphabets($event)">
                        <span class="help-block">
                            <span *ngIf="submitted && addUserForm.controls.usermobileNumber.errors">
                                <!-- <div *ngIf="addUserForm.controls.usermobileNumber.errors.required"
                                    class="error-text text-danger mt-1">{{'email is required'|translate}}</div> -->
                                <div *ngIf="addUserForm.controls.usermobileNumber.errors.pattern"
                                    class="error-text text-danger mt-1">{{'please enter a valid mobile number'|translate}}</div>
                            </span>
                        </span>
                    </div>
                </div>
                    <hr>
                    <div class="row ">
                        <div class="col-6 pr-0">{{'Partner'|translate}}*</div>
                        <div class="col-6 text-right pl-0">
                            <ng-multiselect-dropdown formControlName="partner" id="partner" class="radio-check"
                            [settings]="partnerDropdownSettings" [placeholder]="'-All-'|translate" [data]="partnersList"
                                [(ngModel)]="partnerSelectedItems" 
                                (onSelect)="onItemSelect($event)"></ng-multiselect-dropdown>
                            <span class="help-block">
                                <span *ngIf="submitted && addUserForm.controls.partner.errors">
                                    <div *ngIf="addUserForm.controls.partner.errors.required"
                                        class="error-text text-danger mt-1">
                                        {{'partnerRequired'|translate}}
                                    </div>
                                </span>
                            </span>
                        </div>
                    </div>
                    <hr>
                    <div class="row ">
                        <div class="col-6 pr-0">{{'Groups'|translate}}*</div>
                        <div class="col-6 text-right pl-0">
                            <ng-multiselect-dropdown
                                formControlName="userGroups"
                                [placeholder]="'-All-'|translate"
                                [settings]="userGroupdropdownSettings" [data]="userGroupdropdownList"
                                [(ngModel)]="userGroupselectedItems" (onSelect)="onItemSelect($event)"
                                (onSelectAll)="onSelectAll($event)">
                            </ng-multiselect-dropdown>
                            <span class="help-block">
                                <span *ngIf="submitted && addUserForm.controls.userGroups.errors">
                                    <div *ngIf="addUserForm.controls.userGroups.errors.required"
                                        class="error-text text-danger mt-1">
                                        {{'Group is required'|translate}}
                                    </div>
                                </span>
                            </span>
                        </div>
                    </div>
                    <hr>

                    <div class="text-center">
                        <button class="btn btn-danger" type="submit"
                            (click)="onSubmit()">{{'Add'|translate}}</button>
                            <button class="btn btn-outline-danger ml-3" (click)="cancel()">{{'Cancel'|translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>