<app-header></app-header>
<img src="/assets/img/bg3.jpg" alt="" class="bg2">
<br>
<br>
<div class="col-12 text-secondary">
    <div class=" mat-card col-md-12 col-sm-12 col-xs-12 mx-auto">
        <h2 class="card-title text-center">{{header | translate}}</h2>
        <form [formGroup]="addUserForm" style="font-size: 13px;">
            <div class="form-group">
                <div class="row">
                    <label class="required col-sm-2 ml-4" for="Name">{{'Name' | translate}}</label>
                    <input type="text" formControlName="name" class="form-control col-sm-9" id="Name"
                        aria-describedby="nameHelp" [placeholder]="'Enter Name' |translate" (keyup.enter)="input2.focus()">
                </div>
                <small class="row">
                    <small class="col-sm-2 ml-4"></small>
                    <small *ngIf="submitted &&  formValidators.name.errors?.required"
                        class="form-text text-muted col-sm-9">{{'Please Fill Name Field' |translate}}
                        </small>
                </small>
            </div>
            <hr>
            <div class="form-group">
                <div class="row">
                    <label class="required col-sm-2 ml-4" for="Name">{{'Login ID'| translate}}</label>
                    <input type="text" formControlName="loginId" class="form-control col-sm-9 custplaceholder text-transform" id="Name"
                    #loginId (input)="loginId.value = loginId.value.toUpperCase()"   aria-describedby="nameHelp" [placeholder]="'Login ID' |translate" #input2 (keyup.enter)="input3.focus()">
                </div>
                <div class="row" >
                <small class="col-sm-2 ml-4"></small>
                    <small style="background-color: yellow;"
                        class="form-text col-sm-9">
                        {{'NoteKey'| translate}}
                        </small>
                    </div>
                <small class="row">
                    <small class="col-sm-2 ml-4"></small>
                    <small *ngIf="submitted &&  formValidators.loginId.errors?.required"
                        class="form-text text-muted col-sm-9">{{'Please fill Login field' | translate}}
                        </small>
                </small>
                <small class="row">
                    <small class="col-sm-2 ml-4"></small>
                    <small *ngIf="loginIdValidateError"
                        class="form-text text-muted col-sm-9">{{'Login id should end with SWEEP or CLEAN' | translate}}
                        </small>
                </small>
            </div>
            <hr>
            <div class="form-group ">
                <div class="row d-flex">
                    <label class="required col-sm-2 ml-4" for="Capabilities">{{'Capabilities'|translate}}</label>
                    <select id="capabilities" class="form-control col-sm-9" formControlName="capabilities" required #input3 (change)="input4.focus()">
                        <!-- <option value="">Select a language</option>
                        <option value="NL">NL</option>
                        <option value="FR">FR</option>
                        <option value="EN">EN</option> -->
                        <option value="" disabled selected>{{'Choose an option' | translate}}</option>
                        <option *ngFor="let list of list" [value]="list.name">
                         {{list.name}}
                        </option>
                    </select>
                    <!-- <app-multi-select-dropdown [list]="list" formControlName="name" class="col-sm-8" style="padding: 0px;"
                        (shareCheckedList)="shareCheckedList($event)"
                        (shareIndividualCheckedList)="shareIndividualCheckedList($event)">
                    </app-multi-select-dropdown> -->
                </div>
                <small class="row">
                    <small class="col-sm-2 ml-4"></small>
                    <small *ngIf="submitted &&  formValidators.capabilities.errors?.required"
                        class="form-text text-muted col-sm-9">{{'Please fill Capabilities field' | translate}}
                        </small>
                </small>
                <small class="row">
                    <small class="col-sm-2 ml-4"></small>
                    <small *ngIf="showMissmatchError"
                        class="form-text text-muted col-sm-9">{{'LoginID and Capabilities Mismatch' | translate}}
                        </small>
                </small>
            </div>
            <hr>
            <div class="form-group">
                <!-- <div class="row">
                    <label class="required col-sm-3 ml-4" for="Name">Password</label>
                    <input type="text" formControlName="password" class="form-control col-sm-8" id="Name"
                        aria-describedby="nameHelp" placeholder="Enter Password">
                </div> -->
                <div class="d-flex">
                <label class="required col-sm-2 ml-3" style="padding-left: 8px;" for="Name">{{'Password'|translate}}</label>
                <div class="inner-addon right-addon" style="width: 77%;">
                    <div class="">
                      <input name="password" formControlName="password"
                          [type]="showPasswordOnPress ? 'text' : 'password'"
                          class="form-control pwd-control"
                          #password (input)="password.value = password.value.toUpperCase()"
                          id="password-field" [placeholder]="'Password' |translate" #input4 (keyup.enter)="submit()">
                      <div
                      (mousedown)="showPasswordOnPress = !showPasswordOnPress"
                      (mouseup)="showPasswordOnPress = showPasswordOnPress">
                        <i alt="show" class="fas fa-eye eye-show"

                        [class.show]="showPasswordOnPress"></i>
                        <i alt="hide" class="fas fa-eye-slash eye-hide"

                        [class.hide]="!showPasswordOnPress"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <small class="row">
                    <small class="col-sm-2 ml-4"></small>
                    <small *ngIf="submitted && formValidators.password.errors"
                        class="form-text text-muted col-sm-8">
                       {{'Please check Password field (alphanumeric, min 8 chars)' | translate}}</small>
                </small>
            </div>
           <!-- <div class="row mt-3">
                    <div class="col-6 text-center">{{'Language'|translate}}</div>
                    <div class="col-6 ">
                    <select id="language" formControlName="language" required>
                        <option value="">Select a language</option>
                        <option value="NL">NL</option>
                        <option value="FR">FR</option>
                        <option value="EN">EN</option>
                    </select>
                    </div>
                </div> -->
        </form>
        <div class="text-center pb-4">
            <button class="btn btn-danger" type="submit" (click)="submit()">{{'Submit' | translate}}</button>
            <button class="btn btn-outline-danger ml-3" (click)="cancel()">{{'Cancel'|translate}}</button>
        </div>
    </div>
    </div>
