<div class="mx-5 mt-5"  [appViewController]="{partnerTool:'viewPartnerTool'}">
    <h5 style="color: red;">{{'createNewOrder'|translate}}</h5>

    <div class="mt-5 mx-auto card " style="width: 70%;">
        <div class="card-body" >
            <form [formGroup]="newOrderForm">
                <!-- <div class="form-group row">
                  <label for="staticEmail" class="col-sm-4 col-form-label">Mobile Number :</label>
                  <div class="col-sm-8">
                    <input type="text"  class="form-control" id="mobile">
                  </div>
                </div>
                <div class="form-group row">
                    <label for="staticEmail" class="col-sm-4 col-form-label"> Confirm Mobile Number :</label>
                    <div class="col-sm-8">
                      <input type="text"  class="form-control" id="Confirmmobile">
                    </div>
                  </div> -->

                  <div class="form-group row">
                    <label for="staticEmail" class="col-sm-4 col-form-label">{{'Email'|translate}}</label>
                    <div class="col-sm-8">
                      <input type="text"  class="form-control" id="E-mail" formControlName="email">
                      <small *ngIf="submitted &&  formValidators.email.errors?.required"
                        class="form-text text-muted">{{'Email is a required field'|translate}}</small>
                        <small *ngIf="submitted &&  formValidators.email.errors?.email"
                        class="form-text text-muted">{{'Email format incorrect'|translate}}</small>
                    </div>
                  </div>
                  <div class="form-group row">
                      <label for="staticEmail" class="col-sm-4 col-form-label">{{'Confirm email address'|translate}}</label>
                      <div class="col-sm-8">
                        <input type="text"  class="form-control" id="Confirm E-mail" formControlName="emailConfirm">
                        <small *ngIf="submitted &&  formValidators.emailConfirm.errors?.required"
                        class="form-text text-muted">{{'Confirm email is a required field'|translate}}</small>
                        <small *ngIf="submitted &&  formValidators.emailConfirm.errors?.email"
                        class="form-text text-muted">{{'Email format incorrect'|translate}}</small>
                      </div>
                    </div>


              <div class="row justify-content-center">
                <button type="submit" class="btn btn-danger" (click)="partnerOrder()">{{'saveData'|translate}}</button>
              </div>
            </form>
        </div>

    </div>
</div>
