<!-- <div class="locker-container">
    <div *ngFor="let terminal of terminals" class="locker-board">
        <div *ngFor="let column of terminal.columns" class="locker-block">
            <div *ngIf="column.centralConsole">!!</div>
            <div
                *ngFor="let compartment of column.compartments"
                class="compartment"
                id="compartment1"
                [style.height.mm]="compartment.height"
                [style.width.mm]="compartment.width">
                {{compartment.number}}
            </div>
        </div>
    </div>
</div> -->


<div class="box-container row" [appViewController]="{deliveryPoints:'displayLayout'}">
    <div *ngFor="let outerArrayItem of terminals; let i=index">
        <div class="array-index">{{i+1}}</div>
        <div *ngFor="let innerArray of outerArrayItem.compartments" class="locker-block">
            <div class="rectangular-box">
                <div class="compartment box-content" [style.height.px]="innerArray.height/3"
                    [style.width.px]="innerArray.width/2">
                    {{innerArray.number}}
                </div>
            </div>
        </div>
    </div>
</div>