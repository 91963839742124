import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ParcelService } from 'src/app/Service/parcel.service';
// import { BsModalRef } from 'ngx-bootstrap';
@Component({
  selector: 'app-postman-login-code',
  templateUrl: './postman-login-code.component.html',
  styleUrls: ['./postman-login-code.component.scss']
})
export class PostmanLoginCodeComponent implements OnInit {
  onClose: Subject<any>;
  data: any;
  someValue
  list: any[];
  postmanLoginForm: FormGroup;
  submitted: boolean
  dropdownValue: any[];
  capabilityCheck: boolean = false;
  dropSuccess: boolean = true;
  textareaArrObj: any;
  constructor(private _bsModalRef: BsModalRef,
    private _formBuilder: FormBuilder,
    private _parcelService: ParcelService) {
    this.list =
      [
        { name: 'CAN_COLLECT', checked: false },
        { name: 'CAN_DELIVER', checked: false },
        { name: 'CHECK', checked: false },
      ]
  }

  ngOnInit(): void {
    this.onClose = new Subject();
    this.postmanLoginForm = this._formBuilder.group({
      name: ['', Validators.required],
      matriculeID: ['', Validators.required],
      acCode: ['', Validators.required],
    })
  }

  close() {
    this._bsModalRef.hide()
  }
  shareCheckedList(item: any[]) {
    if (item.length > 0) {
      this.capabilityCheck = true
      this.dropSuccess = true
    } else {
      this.capabilityCheck = false
      this.dropSuccess = false
    }
    this.dropdownValue = item
  }
  shareIndividualCheckedList(item: {}) {
  }
  get formValidators() {
    return this.postmanLoginForm.controls;
  }
  textareaValues(value) {
    const values = value.split(',')
    this.textareaArrObj = values.map(value => ({ acCode: value.trim() }))
  }
  postcallData() {
    const obj = {
      postman: {
        matriculeId: this.postmanLoginForm.controls.matriculeID.value,
        name: this.postmanLoginForm.controls.name.value,
        capabilities: this.dropdownValue
      },
      deliveryPoints: this.textareaArrObj
    }
  }
  submitData() {
    // this.onClose.next(this.data.modalName)
    // this.onClose.next(this.someValue)
    this.submitted = true
    if (this.submitted && this.capabilityCheck) {
      this.dropSuccess = true
    } else {
      this.dropSuccess = false
    }
    if (this.postmanLoginForm.valid && this.dropdownValue) {
      this.postcallData()
    }
    //this._bsModalRef.hide()
  }
}
