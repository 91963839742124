import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public userInfo;
  public authorized: boolean;
  public firstname : string;
  public lastname : string;


  constructor() {

  }

  ngOnInit(): void {
    this.userInfo = JSON.parse(window.localStorage.getItem('userData'))
    if(this.userInfo){
      this.authorized = true
      this.firstname = this.userInfo.firstname
      this.lastname = this.userInfo.lastname
    }
    else{
      this.authorized = false
    }
  }

}
