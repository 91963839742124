import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { AuthorizationService } from '../../Service/authorization.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/Service/spinner.service';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PostmanLoginCodeComponent } from 'src/app/modals/postman-login-code/postman-login-code.component';
import { Subject, Subscription, fromEvent, merge, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from 'src/app/Service/user.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  onlineEvent: Subscription;
  offlineEvent: Subscription;
  subscriptions: Subscription[] = [];
  public userInfo;
  public authorized: boolean;
  public email: string;
  bsModalRef: BsModalRef;
  showDeliverypointHeader: boolean = true
  showparcelHeader: boolean = true
  showUserHeader: boolean = true
  showPostman: boolean = true
  showReportHeader: boolean = true
  showpartnerHeader: boolean = true
  loading: any
  showSpinner: boolean = false;
  partnerId: number = null;
  @ViewChild('delList', { static: false }) delList: ElementRef;
  @ViewChild('delonBoard', { static: false }) delonBoard: ElementRef;
  @ViewChild('parcelList', { static: false }) parcelList: ElementRef;
  @ViewChild('parcelLastmile', { static: false }) parcelLastmile: ElementRef;
  @ViewChild('parcelFirstmile', { static: false }) parcelFirstmile: ElementRef;
  @ViewChild('userList', { static: false }) userList: ElementRef;
  @ViewChild('userAdd', { static: false }) userAdd: ElementRef;
  @ViewChild('userGroup', { static: false }) userGroup: ElementRef;
  @ViewChild('postMan', { static: false }) postMan: ElementRef;
  @ViewChild('downTime', { static: false }) downTime: ElementRef;
  @ViewChild('firstFail', { static: false }) firstFail: ElementRef;
  @ViewChild('userReport', { static: false }) userReport: ElementRef;
  @ViewChild('partnerlist', { static: false }) partnerlist: ElementRef;
  @ViewChild('partneradd', { static: false }) partneradd: ElementRef;
  networkStatus: boolean = false;
  networkStatus$: Subscription = Subscription.EMPTY;
  constructor(
    public authorizationService: AuthorizationService,
    public user: UserService,
    public router: Router,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private spinner: SpinnerService,
    private translate: TranslateService
  ) {
    this.spinner.showSpinner.subscribe(value => {
      this.showSpinner = value;
    });
    this.user.subject.subscribe(value => {
      if (value) {
        window.localStorage.setItem("headerLoad", "false")
        this.hideShow()
      }
    })
    this.checkNetworkStatus();
  }

  ngOnInit() {
    this.authorizationService.currentDdsUser.subscribe(user => {
      if (user?.partner?.id) {
        this.partnerId = user?.partner?.id;
      }

      if (window.location.pathname === '/') {
        if (localStorage.getItem('redirectURL')) {
          let url = localStorage.getItem('redirectURL');
          url ? this.router.navigate([url]) : this.router.navigate(['parcels']);
        }
        else {
          this.router.navigate(['parcels'])
        }
      }
      localStorage.setItem("myData", user)
      this.authorizationService.currentUserData$.next(user)

      if (user === false) {
        this.router.navigate(['user/forbidden'])
        return;

      }

    });
    this.partnerId = JSON.parse(localStorage.getItem('partnerDetails'))?.id;
    this.userInfo = JSON.parse(window.localStorage.getItem('userData'))
    if (this.userInfo) {
      this.authorized = true
      if (this.userInfo.email == null || this.userInfo.email == undefined) {
        this.email = this.userInfo.Email
      }
      else {
        this.email = this.userInfo.email
      }
    }
    else {
      this.authorized = false
      this.router.navigate(['']);
    }
    setInterval(() => {
      this.authorizationService.isAccessTokenExpired();
    }, 2000);
  }

  checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        if(this.networkStatus !== status){
          if(status){
            this.toastr.success(this.translate.instant('You are back Online!'));
          }else{
            this.toastr.error(this.translate.instant("No internet connection!"));
          }
        }
        this.networkStatus = status;
      });
  }

  ngOnDestroy(): void {
    this.networkStatus$.unsubscribe();
  }

  postmanLogin() {
    let initialState = {}
    const modalRef = this.modalService.show(PostmanLoginCodeComponent, {
      initialState,
      backdrop: 'static',
      keyboard: true,
      class: "modal-md"
    });
    (modalRef.content?.onClose as Subject<any>).subscribe((res) => {
    })
  }

  ngAfterViewInit() {
    this.hideShow()
  }

  hideShow() {
    let dellist = this.delList.nativeElement;
    let delstyle = window.getComputedStyle(dellist)
    let deldplay = delstyle?.display;

    let delboard = this.delonBoard.nativeElement;
    let delboarstyle = window.getComputedStyle(delboard)
    let delboarddplay = delboarstyle?.display;

    if (deldplay == 'none' && delboarddplay == "none") {
      this.showDeliverypointHeader = false
    }

    let parcellist = this.parcelList.nativeElement;
    let parcelliststyle = window.getComputedStyle(parcellist)
    let parcellistdisplay = parcelliststyle?.display;

    let parcellatlist = this.parcelLastmile.nativeElement;
    let parcellastliststyle = window.getComputedStyle(parcellatlist)
    let parcellistlastdisplay = parcellastliststyle?.display;

    let parcelfirslatlist = this.parcelFirstmile.nativeElement;
    let parcelfirstliststyle = window.getComputedStyle(parcelfirslatlist)
    let parcellistfirstdisplay = parcelfirstliststyle?.display;

    if (parcellistdisplay == 'none' && parcellistlastdisplay == 'none' && parcellistfirstdisplay == 'none') {
      this.showparcelHeader = false
    }

    let userList = this.userList.nativeElement;
    let userListstyle = window.getComputedStyle(userList)
    let userListdisplay = userListstyle?.display;

    let userAdd = this.userAdd.nativeElement;
    let userAddliststyle = window.getComputedStyle(userAdd)
    let userAdddisplay = userAddliststyle?.display;

    let userGroup = this.userGroup.nativeElement;
    let userGroupstyle = window.getComputedStyle(userGroup)
    let userGroupdisplay = userGroupstyle?.display;

    if (userListdisplay == 'none' && userAdddisplay == 'none' && userGroupdisplay == 'none') {
      this.showUserHeader = false
    }

    let postMan = this.postMan.nativeElement;
    let postManstyle = window.getComputedStyle(postMan)
    let postMandisplay = postManstyle?.display;

    if (postMandisplay == 'none') {
      this.showPostman = false
    }

    let downTime = this.downTime.nativeElement;
    let downTimestyle = window.getComputedStyle(downTime)
    let downTimedisplay = downTimestyle?.display;

    let firstFail = this.firstFail.nativeElement;
    let firstFailstyle = window.getComputedStyle(firstFail)
    let firstFaildisplay = firstFailstyle?.display;

    let userReport = this.userReport.nativeElement;
    let userReportstyle = window.getComputedStyle(userReport)
    let userReportdisplay = userReportstyle?.display;

    if (downTimedisplay == 'none' && firstFaildisplay == 'none' && userReportdisplay == 'none') {
      this.showReportHeader = false
    }

    let partnerlist = this.partnerlist.nativeElement;
    let partnerliststyle = window.getComputedStyle(partnerlist)
    let partnerlistdisplay = partnerliststyle?.display;

    let partneradd = this.partneradd.nativeElement;
    let partneraddstyle = window.getComputedStyle(partneradd)
    let partneradddisplay = partneraddstyle?.display;

    if (partnerlistdisplay == 'none' && partneradddisplay == 'none') {
      this.showpartnerHeader = false
    }
  }
  clearLanguage() {
    localStorage.removeItem('currentLang')
  }
}
