import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { ToastrService } from 'ngx-toastr';
import { PartnerService } from '../../../Service/partner.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SpinnerService } from 'src/app/Service/spinner.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-partner-details',
  templateUrl: './partner-details.component.html',
  styleUrls: ['./partner-details.component.scss']
})
export class PartnerDetailsComponent implements OnInit {

  partnerDetails: any;
  partnerDeliverypointDetails: any;
  dpdsByAcCodeData: [];
  dpdsByPostalcodeData: [];
  partnerForm: FormGroup
  submitted: boolean;
  partnerId: any;
  constructor(public activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private partnerService: PartnerService,
    private router: Router,
    private formBuilder: FormBuilder,
    private spinner: SpinnerService,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    let partnerId = this.activatedRoute.snapshot.params.id;
    this.partnerId = partnerId
    this.getPartnerDetails(partnerId);
  }

  //Fech partner details
  getPartnerDetails(partnerId) {
    this.spinner.show();
    this.partnerService.fetchPartnerDetails(partnerId).subscribe(
      res => {
        this.partnerDetails = res
        this.spinner.hide();
        this.getPartnerDeliverypointDetails(partnerId);

        this.partnerForm.patchValue({
          partnerName: this.partnerDetails.name,
          country: this.partnerDetails.country,
          active: this.partnerDetails.active

        })
      },
      err => {
        this.spinner.hide();
        this.toastr.error(this.translate.instant('Failed to fetch partner details !!'), this.translate.instant('Failed'));
        console.error(err);
      }
    )
  }

  //Fech partner delivery point details
  getPartnerDeliverypointDetails(partnerId) {
    this.spinner.show();
    this.partnerService.fetchPartnerDeliverypointDetails(partnerId).subscribe(
      res => {
        this.partnerDeliverypointDetails = res
        this.dpdsByAcCodeData = this.partnerDeliverypointDetails.dpdsByAcCode.list;
        this.dpdsByPostalcodeData = this.partnerDeliverypointDetails.dpdsByPostalcode.list;
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
        this.toastr.error(this.translate.instant('Failed to fetch partner deliverypoint details !!'), this.translate.instant('Failed'));
        console.error(err);
      }
    )
  }
  goToPartnerList() {
    this.router.navigate(['/partners']);
  }

}
