import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LangtransService {
  translateValue: any;

  constructor(
    private http: HttpClient
    ) {

  }
  TranslateData(lang: any,key:any){
    return this.http.get (`/assets/i18n/${lang}.json`).pipe(
      map((data: any)=>data[key])
    )
  }
}

