import { Component, ElementRef, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';

@Component({
  selector: 'app-partner-navigation-menu',
  templateUrl: './partner-navigation-menu.component.html',
  styleUrls: ['./partner-navigation-menu.component.scss'],
})
export class PartnerNavigationMenu {
  @Input() partner: any;

  constructor(
  ) {
  }
}
