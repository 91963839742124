<app-header></app-header>
<app-lockers-menu [id]="id"></app-lockers-menu>
<div #myDiv [appViewController]="{deliveryPoints:'showLogs'}">
  <br>
  <div style="
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
     ">
  </div>
  <form [formGroup]="filterForm" class="pl-4 pr-4">
    <div class="form-row">

      <div class="form-group col-md-5">
        <label for="acCode">{{'Event'|translate}}</label>
        <select class="custom-select mr-sm-2" formControlName="logEvent" name="logEvent">
          <option selected value="">{{'Choose Event'|translate}}</option>
          <!-- <option value="CONNECTION">CONNECTION</option>
          <option value="COMMISIONING">COMMISIONING</option> -->
          <!-- <option value="ACCESS">ACCESS</option>
          <option value="COMMENT">COMMENT</option>
          <option value="UPDATE">UPDATE</option>
          <option value="RESERVATION">RESERVATION</option>
          <option value="DETAILS">DETAILS</option>
          <option value="TOKEN">TOKEN</option>
          <option value="DELIVERY">DELIVERY</option>  -->

          <!-- <option value="BARCODE_KNOWN">BARCODE KNOWN</option> -->
          <option value="BARCODE_UNKNOWN">{{'BARCODE UNKNOWN'|translate}}</option>
          <option value="SWEEP_LOGIN">{{'SWEEP LOGIN'|translate}}</option>
          <option value="CLEAN_LOGIN">{{'CLEAN LOGIN'|translate}}</option>
          <option value="POSTMAN_LOGIN">{{'POSTMAN LOGIN'|translate}}</option>
          <option value="POSTMAN_FLOW">{{'POSTMAN FLOW'|translate}}</option>
          <option value="SCANNED">{{'SCANNER/SCREEN'|translate}}</option>
          <option value="DOOROPENED">{{'DOOR OPENED'|translate}}</option>
        </select>
      </div>
      <div class="form-group col-md-6">
        <label for="orderDate">{{'Log date'|translate}}</label>
        <div class="card">
          <div class="form-row card-body p-0">
            <!-- <div class="form-group col-md-6 mb-0">
              <input class="form-control input-width" formControlName="logDateFrom" name="logDateFrom" type="date"
                [max]="getToday()">
            </div>
            <div class="form-group col-md-6 mb-0">
              <input class="form-control input-width" formControlName="logDateTo" name="logDateTo" type="date"
                [max]="getToday()">
            </div> -->
            <div class="input-group">
              <input style="height: 35px;" type="text" placeholder="dd-mm-yyyy" class="form-control"
              formControlName="logDateFrom" #orderDateFromInput bsDatepicker
                [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY',maxDate:maxDate}">
              <!-- bsDaterangepicker -->
              <div class="input-group-append">
                <span class="input-group-text">
                  <i class="fas fa-calendar-alt" (click)="openDatePicker('orderDateFromInput')"></i>
                </span>
              </div>
              <input style="height: 35px;margin-left: 15px;" type="text" placeholder="dd-mm-yyyy" class="form-control"
              formControlName="logDateTo" #orderDateToInput bsDatepicker
                [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY',maxDate:maxDate}">
              <!-- bsDaterangepicker -->
              <div class="input-group-append">
                <span class="input-group-text">
                  <i class="fas fa-calendar-alt" (click)="openDatePicker('orderDateToInput')"></i>
                </span>
              </div>
            <!-- <span *ngIf="selectBothdates"  style="color: red;">Please select both the dates</span> -->
          </div>
          </div>

        </div>
        <span *ngIf="selectBothdates"  style="color: red;">Please select both the dates</span>
        <span *ngIf="invalidDateRange" class="text-danger">{{'Please add a valid from and to date'|translate}}</span>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col text-center mb-3">
          <button class="btn btn-outline-danger mr-3 mt-2" type="submit" (click)="onSubmit()">{{'Filter'|translate}}</button>
          <button class="btn btn-outline-danger mt-2" type="submit" (click)="onReset()">{{'Reset'|translate}}</button>
        </div>
      </div>
    </div>
  </form>
  <div class="table-responsive pl-4 pr-4">
    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
      class="table table-sm table-bordered table-hover row-border hover" style="text-align:center; table-layout: fixed;">
      <caption></caption>
      <thead *ngIf="logdetails" class="thead-dark">
        <tr>
          <!-- <th scope="col">Type</th>
        <th scope="col">ID</th> -->
          <th scope="col" class="event">{{'Event'|translate}}</th>
          <th scope="col" class="action">{{'Action'|translate}}</th>
          <th scope="col" class="description">{{'Description'|translate}}</th>
          <!-- <th scope="col">Application</th> -->
          <th scope="col">{{'Agent'|translate}}</th>
          <th scope="col">{{'Date'|translate}}</th>
          <!-- <th scope="col">reservation attempted</th>
          <th scope="col">reservation status</th> -->
          <th scope="col" class="column">{{'Meta'|translate}}</th>
        </tr>
      </thead>
      <tbody>

        <tr *ngFor="let res of logdetails;">
          <!--
        <td *ngIf="res.type">{{res.type}}</td>
        <td *ngIf="!res.type">-</td>
        <td *ngIf="res.id">{{res.id}}</td>
        <td *ngIf="!res.id">-</td> -->


          <td class="event" *ngIf="!res.event">-</td>
          <td class="event" *ngIf="res.event">{{res.event}}</td>

          <td class="action" *ngIf="res.action">{{res.action}}</td>
          <td class="action" *ngIf="!res.action">-</td>

          <td class="description" *ngIf="res.description">{{res.description}}</td>
          <td class="description" *ngIf="!res.description">-</td>
          <!--
          <td *ngIf="res.application">{{res.application}}</td>
          <td *ngIf="!res.application">-</td> -->


          <td *ngIf="!res.agent">-</td>
          <td *ngIf="res.agent">{{res.agent}}</td>

          <!-- <td *ngIf="!res.meta">-</td>
          <td *ngIf="res.meta">{{getMeta(res.meta)}}</td> -->

          <td *ngIf="!res.eventDate">-</td>
          <td *ngIf="res.eventDate"><span style="display: none;">{{res.eventDate}}</span>{{res.eventDate | utctolocaldate:'DD/MM/YYYY HH:mm'}}</td>

          <!-- <td *ngIf="!res.meta.reservationattempted">-</td>
          <td *ngIf="res.meta.reservationattempted">{{res.meta.reservationattempted}}</td>

          <td *ngIf="!res.meta.reservationstatus">-</td>
          <td *ngIf="res.meta.reservationstatus">{{res.meta.reservationstatus}}</td> -->

          <td class="column">{{getMeta(res.meta)}}</td>

        </tr>
        <!-- <tr  *ngIf = "!logdetails?.length">
          <td style= "text-align:center" colspan="9">
          No Records Found
        </td></tr> -->

      </tbody>
    </table>
    <br>
  </div>
</div>
<app-un-authorizedpage *ngIf="showUnauthorized"></app-un-authorizedpage>
