import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { DeliverypointsService } from '../../../Service/deliverypoints.service';
import { LogsService } from '../../../Service/logs.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, FormArray, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import * as momenttimezone from 'moment-timezone'
import { DatePipe } from '@angular/common';
import { ConfirmationComponent } from "src/app/modals/confirmation/confirmation.component";
import { Subject } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { userEnvironment} from 'src/environments/environment';
import { SpinnerService } from 'src/app/Service/spinner.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-lockerdetails',
  templateUrl: './lockerdetails.component.html',
  styleUrls: ['./lockerdetails.component.scss']
})
export class LockerdetailsComponent implements OnInit {
  singleId: any
  lockerdetails: any
  ipAddress: any
  updatedAt: any
  isAlert: any
  online: any
  address: any
  connectivity: any
  id: any
  lockerProvider: any
  lockerName: any
  lockerId: any
  provider: any
  type: any
  street: any
  postalCode: any
  streetNumber: any
  acCode: any
  municipality: any
  active: any
  logdetails: any
  logsLocker: any
  lastUpdate: any
  today: any
  values: any
  selectedDate: any
  allowSoftReservation: any
  isClassicLocker = false
  lockerIP: any
  formattedTime: string
  timeDifference: number = 30;
  lockerRegularHours: any = [];
  regularHoursDisp: any = [];
  showUnauthorized: boolean = false;
  lockerType: any
  @ViewChild('myDiv', { static: true }) myDiv: ElementRef;

  public lockerDetailsForm
  timeDiffmin: number;

  constructor(public activatedRoute: ActivatedRoute,
    private deliverypointsService: DeliverypointsService,
    public router: Router,
    private logservice: LogsService,
    private FB: FormBuilder,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private modalService: BsModalService,
    private spinner: SpinnerService,
    private translate: TranslateService
  ) {
  }
  takeOver(device: string) {
    this.spinner.show();
    this.deliverypointsService.generateVncToken(this.singleId).subscribe(
      (res: any) => {
        this.spinner.hide()
        window.open(`/assets/noVNC/vnc_auto.html?#host=wss-parcellockers-${userEnvironment.basic_env}.bpost.cloud&path=websocket_vnc&password=R3mote&token=${res.token}`, "_blank");
      },
      err => {
        this.spinner.hide()
        console.error(err);
         this.toastr.error(err?.error?.error?.message || this.translate.instant('Error in take over'),this.translate.instant('Failed'))
      }
    )
  }

  getLogs(id: any) {
    this.router.navigate(['/deliverypoints/' + id + '/logs']);
  }

  ngOnInit() {
    this.lockerDetailsForm = new FormGroup({
      id: new FormControl('', []),
      lockerName: new FormControl('', []),
      ipAddress: new FormControl('', []),
      updatedAt: new FormControl('', []),
      lockerId: new FormControl('', []),
      postalCode: new FormControl('', []),
      street: new FormControl('', []),
      streetNumber: new FormControl('', []),
      municipality: new FormControl('', []),
      type: new FormControl('', []),
      acCode: new FormControl('', []),
      provider: new FormControl('', []),
      active: new FormControl('', []),
      isAlert: new FormControl('', []),
      acceptSoftReservations: new FormControl('', []),
      lockerOperationActive: new FormControl('', []),
      technology: new FormControl('', []),
      commissioned: new FormControl('', []),
      acceptWindBrackets: new FormControl('', []),
      acceptPosition: new FormControl('', []),
      acceptContractName: new FormControl('', [Validators.pattern("^[a-zA-Z \-\']+")]),
      acceptContractHostname: new FormControl('', [Validators.pattern("^[a-zA-Z \-\']+")]),
      acceptTotalDoors: new FormControl('', []),
      acceptNumberOfUnits: new FormControl('', []),
      acceptSalesKeyAccount: new FormControl('', []),
      acceptSalesSegment: new FormControl('', []),
      acceptOperationsFieldSupport: new FormControl('', []),
      acceptLocationDeliveryRoute: new FormControl('', []),
      acceptMailOffice: new FormControl('', []),
      acceptLogisticsPlatform: new FormControl('', []),
      acceptMailCentre: new FormControl('', []),
      acceptWindDirection: new FormControl('', []),
      hardwareType: new FormControl('', []),
      closingDate: this.FB.array([
        this.FB.group({
          from: new FormControl('', []),
          to: new FormControl('', []),
          check: new FormControl('', [])
        })
      ])
    })
    this.singleId = this.activatedRoute.snapshot.paramMap.get('id')
    this.fetchWithLockerId()
    var tdate: any = new Date();
    var date: any = tdate.getDate();
    if (date < 10) {
      date = "0" + date;
    }
    var month: any = tdate.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }

    var year: any = tdate.getFullYear();
    var hours: any = tdate.getHours();
    var minutes: any = tdate.getMinutes()
    this.today = year + "-" + month + "-" + date + "T" + hours + ":" + minutes;
    setTimeout(() => {
      if (Object.keys(this.lockerdetails.closeTimings).length !== 0) {
        for (let i = 0; i < this.lockerdetails.closeTimings.closing.length - 1; i++) {
          this.addNewRow()
        }
      }
      else {
        this.lockerdetails.closeTimings = { closing: [] }
      }
      this.lockerProvider = this.lockerdetails.provider;
      this.lockerType = this.lockerdetails.type
      this.lockerDetailsForm.patchValue({
        "id": this.lockerdetails.id,
        "lockerName": this.lockerdetails.lockerName,
        "ipAddress": this.lockerdetails.connectivity.ipAddress,
        "updatedAt": this.formattedTime,
        "lockerId": this.lockerdetails.lockerId,
        "postalCode": this.lockerdetails.address.postalCode,
        "street": this.lockerdetails.address.street,
        "streetNumber": this.lockerdetails.address.streetNumber,
        "municipality": this.lockerdetails.address.municipality,
        "type": this.lockerdetails.type == "Cubee Locker" ? 'Classic' : 'Lean',
        "acCode": this.lockerdetails.acCode,
        "provider": this.lockerdetails.provider,
        "active": this.lockerdetails.connectivity.online,
        "acceptSoftReservations": this.lockerdetails.operations.acceptSoftReservations,
        "lockerOperationalStatus": this.lockerdetails.lockerOperationalStatus,
        "closingDate": this.lockerdetails.closeTimings.closing,
        "commissioned": this.lockerdetails.connectivity.commisioned ? this.lockerdetails.connectivity.commisioned : '',
        "technology": this.lockerdetails.connectivity.technology,
        "acceptWindBrackets": this.lockerdetails.windBrackets ? this.lockerdetails.windBrackets : '',
        "acceptPosition": this.lockerdetails.position ? this.lockerdetails.position : '',
        "acceptContractName": this.lockerdetails.contractName,
        "acceptContractHostname": this.lockerdetails.contractHostname,
        "acceptTotalDoors": this.lockerdetails.totalDoors,
        "acceptNumberOfUnits": this.lockerdetails.numberOfUnits,
        "acceptSalesKeyAccount": this.lockerdetails.salesKeyAccount,
        "acceptSalesSegment": this.lockerdetails.salesSegment ? this.lockerdetails.salesSegment : '',
        "acceptOperationsFieldSupport": this.lockerdetails.operationsFieldSupport,
        "acceptLocationDeliveryRoute": this.lockerdetails.locationDeliveryRoute,
        "acceptMailOffice": this.lockerdetails.mailOffice,
        "acceptLogisticsPlatform": this.lockerdetails.logisticsPlatform,
        "acceptMailCentre": this.lockerdetails.mailCentre,
        "acceptWindDirection": this.lockerdetails.windDirection,
        "lockerOperationActive": this.lockerdetails.operations.lockerOperationActive,
        "hardwareType": this.lockerdetails.hardwareType ? this.lockerdetails.hardwareType : ''
      })
    }, 1500)
  }


  ngAfterViewInit() {
    let divElement = this.myDiv.nativeElement;
    let divstyle = window.getComputedStyle(divElement)
    let display = divstyle?.display;
    if (display == 'none') {
      this.showUnauthorized = true
    }
  }

  get closingDate() {
    return this.lockerDetailsForm.get('closingDate') as FormArray
  }
  addNewRow() {
    this.closingDate.push(
      this.FB.group({
        from: new FormControl('', []),
        to: new FormControl('', []),
        check: new FormControl('', [])
      })
    );
  }

  deleteRow() {
    let index = []
    let k = 0
    for (let i = 0; i < this.closingDate.length; i++) {
      if (this.lockerDetailsForm.value.closingDate[i].check === true) {
        index[k] = i;
        k++
      }
    }
    for (let j = index.length - 1; j >= 0; j--) {
      this.closingDate.removeAt(index[j]);
    }
  }

  async fetchWithLockerId() {
    this.spinner.show();
    this.deliverypointsService.fetchwithId(this.singleId)
      .then(res => {
        this.lockerdetails = res
        this.convertupdatedDate()
        if (Object.keys(this.lockerdetails.closeTimings).length !== 0) {
          for (let i = 0; i < this.lockerdetails.closeTimings.closing.length; i++) {
            let from = this.lockerdetails.closeTimings.closing[i].from.substring(0, this.lockerdetails.closeTimings.closing[i].from.length - 8)
            this.lockerdetails.closeTimings.closing[i].from = from
            let to = this.lockerdetails.closeTimings.closing[i].to.substring(0, this.lockerdetails.closeTimings.closing[i].to.length - 8)
            this.lockerdetails.closeTimings.closing[i].to = to
          }
        }
        if (this.lockerdetails.connectivity.online == null || this.lockerdetails.connectivity.online == false) {
          this.lockerdetails.connectivity.online = false
        }
        else {
          this.lockerdetails.connectivity.online = true
        }
        if (this.lockerdetails.type && this.lockerdetails.type == 'Classic') {
          this.isClassicLocker = true;
          this.lockerIP = this.lockerdetails.connectivity.ipAddress;

        }
        if (this.lockerdetails.lockerRegularHours) {
          this.lockerRegularHours = this.lockerdetails.lockerRegularHours;

        }
        this.spinner.hide();
      })
      .catch(ex => {
        console.error(ex);
        this.spinner.hide();
      })
  }
  convertupdatedDate() {
    if (this.lockerdetails && this.lockerdetails.connectivity && this.lockerdetails.connectivity.updatedAt) {
      const timestamp = (this.lockerdetails.connectivity.updatedAt).replace('T', ' ').replace('Z', '')

      this.formattedTime = this.datePipe.transform(timestamp, 'dd-MM-YYYY HH:mm:ss')
      const currentTime = new Date();

      let createdDate = momenttimezone(currentTime).tz("Europe/Berlin").format("YYYY-MM-DD HH:mm:ss");
      const startDown = moment(timestamp)
      const endDown = moment(createdDate)

      this.timeDiffmin = Math.abs(endDown.diff(startDown, 'minutes'))
      if (this.timeDiffmin < this.timeDifference) {
        this.isAlert = false
      }
      else {
        this.isAlert = true
      };
    }
  }

  updateLockerDetails() {
    this.spinner.show();
    this.deliverypointsService.updateLockerDetails(this.singleId, this.validate()).subscribe(
      res => {
        this.spinner.hide();
        this.toastr.success(this.translate.instant('Deliverypoint Details Updated Successfully !!'), this.translate.instant('Success'));
      },
      err => {
        this.spinner.hide();
        this.toastr.error(this.translate.instant('Failed to Update Deliverypoint Details !!'),this.translate.instant('Failed'));
        console.error(err);

      }
    )
  }

  validate() {
    let newLockerDetails = {}
    let closingTime = []
    let closingFrom
    let closingTo
    let setActive: Boolean

    if (this.lockerDetailsForm.value.lockerId != this.lockerdetails.lockerId) {
      Object.assign(newLockerDetails, { lockerId: this.lockerDetailsForm.value.lockerId })
      this.lockerdetails.lockerId = this.lockerDetailsForm.value.lockerId
    }
    if (this.lockerDetailsForm.value.provider != this.lockerdetails.provider) {
      Object.assign(newLockerDetails, { provider: this.lockerDetailsForm.value.provider })
    }
    // if (this.lockerDetailsForm.value.acceptTotalDoors != this.lockerdetails.acceptTotalDoors) {
    //   Object.assign(newLockerDetails, { acceptTotalDoors: this.lockerDetailsForm.value.acceptTotalDoors })
    //   this.lockerdetails.acceptTotalDoors = this.lockerDetailsForm.value.acceptTotalDoors
    // }
    if (this.lockerDetailsForm.value.acCode != this.lockerdetails.acCode) {
      Object.assign(newLockerDetails, { acCode: this.lockerDetailsForm.value.acCode })
      this.lockerdetails.acCode = this.lockerDetailsForm.value.acCode
    }
    // if (this.lockerDetailsForm.value.streetNumber != this.lockerdetails.streetNumber) {
    //   Object.assign(newLockerDetails, { streetNumber: this.lockerDetailsForm.value.streetNumber })
    //   this.lockerdetails.streetNumber = this.lockerDetailsForm.value.streetNumber
    // }
    // if (this.lockerDetailsForm.value.type != this.lockerdetails.type) {
    //   Object.assign(newLockerDetails, { type: this.lockerDetailsForm.value.type })
    //   this.lockerdetails.type = this.lockerDetailsForm.value.type
    // }
    // if (this.lockerDetailsForm.value.provider != this.lockerdetails.provider) {
    //   Object.assign(newLockerDetails, { provider: this.lockerDetailsForm.value.provider })
    // }
    if (this.lockerDetailsForm.value.active != this.lockerdetails.connectivity.online) {
      Object.assign(newLockerDetails, { active: this.lockerDetailsForm.value.active })
      setActive = (this.lockerDetailsForm.value.active == "true")
      this.lockerdetails.connectivity.online = setActive
    }
    if (this.lockerDetailsForm.value.acceptSoftReservations != this.lockerdetails.operations.acceptSoftReservations) {
      Object.assign(newLockerDetails, { acceptSoftReservations: this.lockerDetailsForm.value.acceptSoftReservations })
      this.lockerdetails.operations.acceptSoftReservations = this.lockerDetailsForm.value.acceptSoftReservations
    }
    if (this.lockerDetailsForm.value.hardwareType != this.lockerdetails.operations.hardwareType) {
      Object.assign(newLockerDetails, { hardwareType: this.lockerDetailsForm.value.hardwareType })
      this.lockerdetails.operations.hardwareType = this.lockerDetailsForm.value.hardwareType
    }
    if (this.lockerDetailsForm.value.acceptWindBrackets != this.lockerdetails.acceptWindBrackets) {
      Object.assign(newLockerDetails, { acceptWindBrackets: this.lockerDetailsForm.value.acceptWindBrackets })
      this.lockerdetails.acceptWindBrackets = this.lockerDetailsForm.value.acceptWindBrackets
    }
    if (this.lockerDetailsForm.value.acceptPosition != this.lockerdetails.acceptPosition) {
      Object.assign(newLockerDetails, { acceptPosition: this.lockerDetailsForm.value.acceptPosition })
      this.lockerdetails.acceptPosition = this.lockerDetailsForm.value.acceptPosition
    }
    if (this.lockerDetailsForm.value.acceptSalesSegment != this.lockerdetails.acceptSalesSegment) {
      Object.assign(newLockerDetails, { acceptSalesSegment: this.lockerDetailsForm.value.acceptSalesSegment })
      this.lockerdetails.acceptSalesSegment = this.lockerDetailsForm.value.acceptSalesSegment
    }
    if (this.lockerDetailsForm.value.acceptContractName != this.lockerdetails.acceptContractName) {
      Object.assign(newLockerDetails, { acceptContractName: this.lockerDetailsForm.value.acceptContractName })
      this.lockerdetails.acceptContractName = this.lockerDetailsForm.value.acceptContractName
    }
    if (this.lockerDetailsForm.value.acceptContractHostname != this.lockerdetails.acceptContractHostname) {
      Object.assign(newLockerDetails, { acceptContractHostname: this.lockerDetailsForm.value.acceptContractHostname })
      this.lockerdetails.acceptContractHostname = this.lockerDetailsForm.value.acceptContractHostname
    }
    if (this.lockerDetailsForm.value.acceptNumberOfUnits != this.lockerdetails.acceptNumberOfUnits) {
      Object.assign(newLockerDetails, { acceptNumberOfUnits: this.lockerDetailsForm.value.acceptNumberOfUnits })
      this.lockerdetails.acceptNumberOfUnits = this.lockerDetailsForm.value.acceptNumberOfUnits
    }
    if (this.lockerDetailsForm.value.acceptSalesKeyAccount != this.lockerdetails.acceptSalesKeyAccount) {
      Object.assign(newLockerDetails, { acceptSalesKeyAccount: this.lockerDetailsForm.value.acceptSalesKeyAccount })
      this.lockerdetails.acceptSalesKeyAccount = this.lockerDetailsForm.value.acceptSalesKeyAccount
    }
    if (this.lockerDetailsForm.value.acceptOperationsFieldSupport != this.lockerdetails.acceptOperationsFieldSupport) {
      Object.assign(newLockerDetails, { acceptOperationsFieldSupport: this.lockerDetailsForm.value.acceptOperationsFieldSupport })
      this.lockerdetails.acceptOperationsFieldSupport = this.lockerDetailsForm.value.acceptOperationsFieldSupport
    }
    if (this.lockerDetailsForm.value.acceptLocationDeliveryRoute != this.lockerdetails.acceptLocationDeliveryRoute) {
      Object.assign(newLockerDetails, { acceptLocationDeliveryRoute: this.lockerDetailsForm.value.acceptLocationDeliveryRoute })
      this.lockerdetails.acceptLocationDeliveryRoute = this.lockerDetailsForm.value.acceptLocationDeliveryRoute
    }
    if (this.lockerDetailsForm.value.acceptMailOffice != this.lockerdetails.acceptMailOffice) {
      Object.assign(newLockerDetails, { acceptMailOffice: this.lockerDetailsForm.value.acceptMailOffice })
      this.lockerdetails.acceptMailOffice = this.lockerDetailsForm.value.acceptMailOffice
    }
    if (this.lockerDetailsForm.value.acceptLogisticsPlatform != this.lockerdetails.acceptLogisticsPlatform) {
      Object.assign(newLockerDetails, { acceptLogisticsPlatform: this.lockerDetailsForm.value.acceptLogisticsPlatform })
      this.lockerdetails.acceptLogisticsPlatform = this.lockerDetailsForm.value.acceptLogisticsPlatform
    }
    if (this.lockerDetailsForm.value.acceptMailCentre != this.lockerdetails.acceptMailCentre) {
      Object.assign(newLockerDetails, { acceptMailCentre: this.lockerDetailsForm.value.acceptMailCentre })
      this.lockerdetails.acceptMailCentre = this.lockerDetailsForm.value.acceptMailCentre
    }
    if (this.lockerDetailsForm.value.acceptWindDirection != this.lockerdetails.acceptWindDirection) {
      Object.assign(newLockerDetails, { acceptWindDirection: this.lockerDetailsForm.value.acceptWindDirection })
      this.lockerdetails.acceptWindDirection = this.lockerDetailsForm.value.acceptWindDirection
    }
    if (this.lockerDetailsForm.value.lockerOperationActive != this.lockerdetails.operations.lockerOperationActive) {
      Object.assign(newLockerDetails, { lockerOperationActive: this.lockerDetailsForm.value.lockerOperationActive })
      this.lockerdetails.lockerOperationActive = this.lockerDetailsForm.value.lockerOperationActive
    }
    if (Object.keys(this.lockerDetailsForm.value.closingDate).length !== 0) {
      for (let i = 0; i < this.lockerDetailsForm.value.closingDate.length; i++) {
        this.lockerDetailsForm.value.closingDate[i].from = this.lockerDetailsForm.value.closingDate[i].from + ":00.000Z"
        this.lockerDetailsForm.value.closingDate[i].to = this.lockerDetailsForm.value.closingDate[i].to + ":00.000Z"
      }
      for (let j = 0; j < this.lockerdetails.closeTimings?.closing.length; j++) {
        this.lockerdetails.closeTimings.closing[j].from = this.lockerdetails.closeTimings.closing[j].from + ":00.000Z"
        this.lockerdetails.closeTimings.closing[j].to = this.lockerdetails.closeTimings.closing[j].to + ":00.000Z"
      }
      if (JSON.stringify(this.lockerdetails.closeTimings?.closing) !== JSON.stringify(this.lockerDetailsForm.value.closingDate)) {
        for (let k = 0; k < this.lockerDetailsForm.value.closingDate.length; k++) {
          closingFrom = new Date(this.lockerDetailsForm.value.closingDate[k].from);
          closingTo = new Date(this.lockerDetailsForm.value.closingDate[k].to)
          if (!isNaN(closingFrom) && !isNaN(closingTo)) {
            closingTime.push({ from: this.lockerDetailsForm.value.closingDate[k].from, to: this.lockerDetailsForm.value.closingDate[k].to })
          }
        }
        if (closingTime.length !== 0) {
          Object.assign(newLockerDetails, { closeTimings: { closing: closingTime } })
          this.lockerdetails.closeTimings.closing = this.lockerDetailsForm.value.closingDate
        }
      }
    }
    else {
      Object.assign(newLockerDetails, { closeTimings: {} })
      this.lockerdetails.closeTimings = {}
    }
    return newLockerDetails
  }
  onSubmit() {
    if (this.lockerDetailsForm.controls.acceptContractName.status && this.lockerDetailsForm.controls.acceptContractHostname.status != "INVALID") {
      if (this.lockerDetailsForm.valid) {
        this.updateLockerDetails()
      }   //this.router.navigate(['/deliverypoints']);
    }
    else {
      console.error("Failed  to update with invalid inputs")
    }
  }

  onChange(value: any) {
    var todate: any = new Date().getTime();
    this.selectedDate = new Date(value)
    var selectDate: any = new Date(value).getTime();
    var selectedDate: any = new Date(value)
    if (todate > selectDate) {
      this.values = "";
      alert("You can't choose previous Date and Time")
    }
  }
  performCommissioning() {
    this.deliverypointsService.startIoTCommissioning(this.singleId).subscribe(
      res => {
        this.toastr.success('Deliverypoint Commissioned Successfully !!', 'Success');
      },
      err => {
        this.toastr.error('Failed to Commission Deliverypoint !!', 'Failed');
        console.error(err);

      }
    )
  }
  openMap(latitude, longitude) {
    const mapUrl = `https://maps.google.com/maps?q=${latitude},${longitude}`;
    window.open(mapUrl, '_blank');
  }

  restart() {

    let initialState = {}
    initialState = { "currentCommand": "restart" }
    const modalRef = this.modalService.show(ConfirmationComponent, {
      initialState,
      backdrop: 'static',
      keyboard: true,
      class: "modal-md"
    });
    (modalRef.content?.onClose as Subject<any>).subscribe((res) => {
      if (res === "confirm") {

        let requestData = {
          deliverypoint: this.singleId,
          command: "restart",
          type: this.lockerdetails.type

        }
        this.restartLocker(requestData)
      }
    })
  }

  restartLocker(requestData) {
    this.spinner.show();
    this.deliverypointsService.updateCommand(this.singleId, requestData).subscribe(
      res => {
        this.fetchWithLockerId()
        this.spinner.hide();
        this.toastr.success(this.translate.instant('Restarted Successfully !!'), this.translate.instant('Success'));
      },
      err => {
        this.spinner.hide();
        console.error(err);
        this.toastr.error(this.translate.instant('Failed to Restart !!'), this.translate.instant('Failed'));
      }
    )
  }

  // populateDisplayObj = ()=>{
  //   let constructedDays  = [];
  //   for(let obj of this.lockerRegularHours){
  //     if(!constructedDays.includes(obj.openDay)){
  //       let arr = this.constructArr(obj);
  //       constructedDays.push(obj.openDay);
  //     }
  //   }

  // }
  // constructArr = (arrObj:any)=>{
  //   let newArr = [];
  //   newArr[0] = arrObj;
  //   const count = this.lockerRegularHours.filter((obj) => obj.openDay == arrObj.openDay).length;
  //   if(count<2){
  //     newArr[2]=null
  //   }
  //}
}
