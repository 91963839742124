import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DeliverypointsService } from '../../Service/deliverypoints.service'
import { LogsService } from '../../Service/logs.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { AppService } from '../../Service/app.service';
import { ReportsService } from '../../Service/reports.service';
import { SpinnerService } from 'src/app/Service/spinner.service';

@Component({
  selector: 'app-lockerdownreport',
  templateUrl: './lockerdownreport.component.html',
  styleUrls: ['./lockerdownreport.component.scss']
})
export class LockerdownreportComponent implements OnInit {
  lockerdownForm: any;
  filterCriteria: { id: number, location: string }[] = [];
  locationdropdownSettings: IDropdownSettings;
  logdetails: any;
  resultarray: any[];
  duration: any;
  locationdropdownList: any[];
  locationDropdownSetttings: IDropdownSettings;
  locationId: any;
  location: any;
  list: { id: number; location: string; }[];
  fromDate: any;
  toDate: any;
  Search: any;
  @ViewChild('logDateFromInput') logDateFromInput: ElementRef
  @ViewChild('logDateToInput') logDateToInput: ElementRef
  maxDate = new Date()

  //new objs
  locationSelectedItems = [];
  startDownData: any[];
  endDownData: any[];
  startDown: any[];
  endDown: any[];
  differenceArray: number[] = [];
  //end new objs
  constructor(
    private http: HttpClient,
    private appService: AppService,
    private reportsService: ReportsService,
    private deliverypointsService: DeliverypointsService,
    private logservice: LogsService,
    private datePipe: DatePipe,
    private renderer: Renderer2,
    private spinner: SpinnerService

  ) { }

  ngOnInit(): void {
    this.appService.languagetoggle$.subscribe((data: any) => {
      this.http.get(`/assets/i18n/${data}.json`).subscribe((data: any) => {
        this.locationDropdownSetttings = {
          singleSelection: false,
          itemsShowLimit: 3,
          allowSearchFilter: true,
          // enableCheckAll: false,
          searchPlaceholderText: data.Search,
          noDataAvailablePlaceholderText: data.nodataText,
          selectAllText: data.selectAll,  
        };
      })
    })
    this.lockerdownForm = new FormGroup({
      dropDown: new FormControl('', [Validators.required]),
      logDateFrom: new FormControl('', []),
      logDateTo: new FormControl('', []),

    })
    this.fetchresponse();
  }
  fetchresponse() {
    this.spinner.show();
    this.deliverypointsService.fetchMany({
      provider: 'bpost'
    })
      .then(res => {
        this.spinner.hide();
        this.list = res.map(locker => {
          return { id: locker.id, text: locker.lockerName, checked: false }
        })
        this.locationdropdownList = this.list
      })
      .catch(ex => {
        this.spinner.hide();
      })
  }
  onSelectAll(items: any) {
  }
  onItemSelect(item: any) {
  }
  onItemDeSelect(items: any) {
  }
  // validate(){
  //   let location = [];
  //   let locationForm = this.lockerdownForm.value? this.lockerdownForm.value : [];
  //   if (locationForm.length != 0) {
  //     for (let i = 0; i < locationForm.length; i++) {
  //       location.push(locationForm);
  //     }
  //   }
  //   return{
  //     location
  //   }
  // }
  runExport() {
    this.spinner.show();
    let lockerDown = this.lockerdownForm.value.dropDown
    console.log({lockerDown})

    const selectedIds = lockerDown.map(selection => selection.id)

    if (this.lockerdownForm.value.logDateFrom) {
      const Date1 = new Date(this.lockerdownForm.value.logDateFrom).setHours(0, 0, 0)
      this.fromDate = this.datePipe.transform(Date1, 'yyyy-MM-dd HH:mm:ss');
    }
    else { this.fromDate = null }
    if (this.lockerdownForm.value.logDateTo) {
      const Date2 = new Date(this.lockerdownForm.value.logDateTo).setHours(23, 59, 59)
      this.toDate = this.datePipe.transform(Date2, 'yyyy-MM-dd HH:mm:ss');
    }
    else { this.toDate = null }

    this.reportsService.lockerDown({
      ids: selectedIds,
      fromdate: this.fromDate,
      todate: this.toDate
    })
    .then((res: any) => {
      this.spinner.hide();
      const fields = ['id', 'terminalId', 'startdate', 'enddate', 'reason', 'notified', 'duration', 'title']

      res = res.map((item: any) => {
        // Object to array
        const arr = []
        for (const field of fields) {
          arr.push(item[field])
        }
        return arr
      })

      const Today = this.datePipe.transform(new Date(), 'dd-MM-yyyy')
      let downtimeReport = 'Downtime_Report_' + Today + '.xls'
      this.exportToExcel(fields, res, downtimeReport)
    }, err => {
      this.spinner.hide();
    })
  }

  exportToExcel(heading, data: any[], filename: string) {
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([heading, ...data]);
    // const headerStyle = {font:{color:{rgb: '0000FF'}}}
    // ws['A1'].s = headerStyle
    // ws['B1'].s = headerStyle
    // ws['C1'].s = headerStyle
    // ws['D1'].s = headerStyle
    // ws['E1'].s = headerStyle
    // ws['F1'].s = headerStyle
    // ws['G1'].s = headerStyle
    // ws['H1'].s = headerStyle
    // ws['!autofilter'] = {ref: 'A1:H1'}
    const colwidth = heading.map(() => ({ wch: 25 }));
    ws["!cols"] = colwidth; // Adjust column widths if needed
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // const headerRow = ws.addRow(['id','terminalId','startDate','endDate','reason','notified'])
    const textColor = { font: { color: { rgb: '0000FF' } } };
    Object.keys(ws).forEach((cell) => {
      if (cell.charAt(0) === 'A') {
        ws[cell].s = textColor;
      }
    });
    XLSX.utils.book_append_sheet(wb, ws, 'Data');
    XLSX.writeFile(wb, `${filename}.xlsx`);
  }
  getToday(): string {
    return new Date().toISOString().split('T')[0]
  }
  shareCheckedList(item) {
  }
  shareIndividualCheckedList(items) {
  }
  // convertSeconds(seconds: number): string {
  //   const date = new Date(null);
  //   date.setSeconds(seconds);
  //   date.toISOString().substr(11, 8);
  //   return date.toISOString().substr(11, 8)
  // }
  convertSeconds(seconds: number): string {
    let days = null
    if (seconds > 86400) {
      days = Math.floor(seconds / 86400);
      days = days + ' days,';
    }
    const date = new Date(null);
    date.setSeconds(seconds);
    date.toISOString().substr(11, 8);
    //return date.toISOString().substr(11, 8)
    if (days) {
      return days + ' ' + date.toISOString().substr(11, 8)
    } else {
      return date.toISOString().substr(11, 8)
    }
  }

  get formValidators() {
    return this.lockerdownForm.controls
  }

  onReset() {
    this.lockerdownForm.reset();
    Object.keys(this.lockerdownForm.controls).forEach(key => {
      this.lockerdownForm.get(key).setErrors(null);
    });
  }
  openDatePicker(v) {
    if (v == 'logDateFromInput')
      this.renderer.selectRootElement(this.logDateFromInput.nativeElement).click()
    if (v == 'logDateToInput')
      this.renderer.selectRootElement(this.logDateToInput.nativeElement).click()

  }
}
