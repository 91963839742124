import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/Service/app.service';

@Component({
  selector: 'app-langgtoggle',
  templateUrl: './langgtoggle.component.html',
  styleUrls: ['./langgtoggle.component.scss']
})
export class LanggtoggleComponent implements OnInit {
  selectedVal: any;
  //selectedVal='en'
  defaultLang: any;
  constructor(private translate: TranslateService,
    private appService: AppService) {
    translate.setDefaultLang('en');
    //this.changeLanguage('en')
    translate.getBrowserLang()
    const current = localStorage.getItem('currentLang');
    if (current != null)
      this.selectedVal = current
    if (current == null)
      this.selectedVal = 'en'
  }

  ngOnInit(): void {
    let language = localStorage.getItem('currentLang')
    if (language != null)
      this.changeLanguage(language)
    if (language == null)
      this.changeLanguage('en')

  }
  changeLanguage(lang: string) {
    localStorage.setItem('currentLang', lang)
    const current = localStorage.getItem('currentLang')
    if (current != null) {
      this.translate.use(current)
      this.appService.languagetoggle$.next(current)
    }
  }
}
