import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { ToastrService } from 'ngx-toastr';
import { PartnerService } from '../../../Service/partner.service';
import { DeliverypointsService } from '../../../Service/deliverypoints.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { LangtransService } from 'src/app/Service/langtrans.service';
import { AppService } from 'src/app/Service/app.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SpinnerService } from 'src/app/Service/spinner.service';
@Component({
  selector: 'app-partner-edit-deliverypoints',
  templateUrl: './partner-edit-deliverypoints.component.html',
  styleUrls: ['./partner-edit-deliverypoints.component.scss']
})
export class PartnerEditDeliverypointsComponent implements OnInit {
  showSpinner: boolean = false;
  partnerDetails: any;
  partnerDeliverypointDetails: any;
  dpdsByAcCodeData: any;
  dpdsByPostalcodeData: any;
  model: any = {};
  partnerId: number
  @ViewChild('closeAcCodeModal') private closeAcCodeModal: ElementRef;
  @ViewChild('closePostalCodeModal') private closePostalCodeModal: ElementRef;
  lockerList: any;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings;
  requiredField: boolean = false;
  lockerNamedropdownList = [];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  historyOfObj: any;
  langKey: any;
  addAcCodeForm: FormGroup
  submitted: boolean;
  translateData: any;
  deleteCode: boolean;
  constructor(public activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private partnerService: PartnerService,
    private router: Router,
    private deliverypointsService: DeliverypointsService,
    private formBuilder: FormBuilder,
    private langtransService: LangtransService,
    private appService: AppService,
    private spinner: SpinnerService
  ) {
    this.addAcCodeForm = this.formBuilder.group({
      lockerAcCode: ['', Validators.required]
    })
  }

  ngOnInit(): void {
    this.appService.languagetoggle$.subscribe((data: any) => {
      this.langKey = data;
    })
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      destroy: true,
    };
    this.lockerList = []
    this.partnerId = this.activatedRoute.snapshot.params.id;
    this.getPartnerDetails(this.partnerId);
    let parcelLockerNameList = []
    this.loading(true)
      .then(() => this.deliverypointsService.fetchMany({}))
      .then(res => {
        this.lockerList = res
        this.dropdownSettings = {
          singleSelection: false,
          idField: 'id',
          textField: 'type',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          allowSearchFilter: true,
          itemsShowLimit: 3,
          closeDropDownOnSelection: true
        };
        for (let i = 0; i < this.lockerList.length; i++) {
          if (this.lockerList[i].lockerName && this.lockerList[i].id) {
            parcelLockerNameList[i] = { id: `${this.lockerList[i].id}`, type: `${this.lockerList[i].id} - ${this.lockerList[i].lockerName}` };
          }
        }
        this.lockerNamedropdownList = parcelLockerNameList;
        // this.setStatus();
      })
      .catch(ex => {
        console.error(ex);
      })
      .finally(() => {
        this.loading(false)
      })

    this.appService.languagetoggle$.subscribe((data: any) => {
      this.translateData = data
    })
  }

  loading(state: boolean): Promise<any> {
    this.showSpinner = state;
    if (state === true) {
      this.spinner.show();
    } else {
      this.spinner.hide();
    }
    return Promise.resolve(state);
  }

  langaugeFetch(lang, key) {
    this.langtransService.TranslateData(lang, key).subscribe(
      res => {
        this.toastr.error(res);
      }
    )
  }

  /*
  * Functionality used to fetch partner details
  */
  getPartnerDetails(partnerId): void {
    this.showSpinner = true;
    this.partnerService.fetchPartnerDetails(partnerId).subscribe(
      res => {
        this.partnerDetails = res;
        this.showSpinner = false;
        this.getPartnerDeliverypointDetails(partnerId);
      },
      err => {
        this.showSpinner = false;
        // this.toastr.error('Failed to fetch partner details !!', 'Failed');
        this.translator(this.langKey, 'failedToFetch', false);
        console.error(err);
      }
    )
  }

  /*
  * Functionality used to fetch partner delivery point details
  */
  getPartnerDeliverypointDetails(partnerId) {
    this.loading(true);
    this.partnerService.fetchPartnerDeliverypointDetails(partnerId).subscribe(
      res => {
        this.partnerDeliverypointDetails = res;
        if(this.partnerDeliverypointDetails && 
          this.partnerDeliverypointDetails.dpdsByAcCode &&
          this.partnerDeliverypointDetails.dpdsByAcCode.list
         ){
          this.dpdsByAcCodeData = this.partnerDeliverypointDetails.dpdsByAcCode.list;
          this.dpdsByPostalcodeData = this.partnerDeliverypointDetails.dpdsByPostalcode.list;
         }else{
          this.dpdsByAcCodeData =[]
         }
       
        this.loading(false);
      },
      err => {
        this.loading(false);
        this.dpdsByAcCodeData =[]
        // this.toastr.error('Failed to fetch partner deliverypoint details !!', 'Failed');
        this.translator(this.langKey, 'failedToFetch', false)
        console.error(err);
      }
    )
  }

  /*
  * Functionality used to delete AC Code
  */

  get formValidators() {
    return this.addAcCodeForm.controls;
  }

  addAcCode() {
    this.submitted = true
    if (this.addAcCodeForm.valid === true) {
      let selectedIDs = this.selectedItems.map(item => item.id)
      let reqBody = {
        "purpose": "add",
        "id": selectedIDs
      }
      this.updateDeliverypointSchemas(reqBody);
      this.closeAcCodeModal.nativeElement.click();
      this.resetInputValues();
    }
  }

  setStatus() {
    (this.selectedItems.length > 0) ? this.requiredField = true : this.requiredField = false;
  }

  /*
  * Functionality used to add AC Code
  */
  addPostalCode() {
    let reqBody = {
      "purpose": "add",
      "postalCode": this.model.postalCode.trim()
    }
    this.updateDeliverypointSchemas(reqBody);
    this.closePostalCodeModal.nativeElement.click();
    this.resetInputValues();
  }

  /*
  * Functionality used to add accode
  */
  deleteAcCode(id) {
    let reqBody = {
      "purpose": "remove",
      "id": id
    }
    this.updateDeliverypointSchemas(reqBody);
  }

  /*
  * Functionality used to delete Postal Code
  */
  deletePostalCode(dpdsByPostalcode) {
    let reqBody = {
      "purpose": "remove",
      "postalCode": dpdsByPostalcode.trim()
    }
    this.updateDeliverypointSchemas(reqBody);
    // this.dpdsByPostalcodeData.splice(index, 1);
  }

  /*
  * Functionality used to update partner deliverypoint schemas
  */
  updateDeliverypointSchemas(reqBody) {
    this.partnerService.updatePartnerDeliveryPoints(this.partnerId, reqBody).subscribe(
      (res: any) => {
        if (res == 'nlCode updated') {
          this.translator(this.langKey, 'NlCodeUpdate', true);
          // this.toastr.success(res)
        }
        else if (res == 'Duplicate NL code not allowed') {
          // let beforeObj = JSON.parse(this.historyOfObj)
          // if (reqBody.nlCode && reqBody.nlCode != beforeObj.nlCode) {
          this.translator(this.langKey, 'NlCodeDuplicate', false);
          // }
          // else {
          //   this.translator(this.langKey, 'NlCodeSuccess', true)
          // }

        }
        else if (this.deleteCode) {
          this.translator(this.langKey, 'ACcoderemovedsuccessfully', true);
          this.deleteCode = false;

        }
        else {
          // this.toastr.success('Successfully updated the deliverypoint schemas ', 'Success');
          this.translator(this.langKey, 'NlCodeSuccess', true);
        }
        this.getPartnerDetails(this.partnerId);
        // this.router.navigate(['/partners/', this.partnerId]);
      },
      err => {
        if (err.error.error.message == "Record already present") {
          this.translator(this.langKey, 'ACCodeAlreadyExists', false);
        }
        else {
          // this.toastr.error('Failed to update deliverypoint schemas', 'Failed');
          this.translator(this.langKey, 'Pleasetryagain', false);
          console.error(err);
        }
      }
    )
  }

  goToPartnerDetails() {
    this.router.navigate(['/partners/', this.partnerId]);
  }

  resetInputValues() {
    this.model = {};
    this.selectedItems = [];
    this.addAcCodeForm.reset();
    this.submitted = false;
  }

  onItemSelect(item: any) {
  }

  getDeliverypointDetails(id: any) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/deliverypoints/' + id + '/details'])
    );

    window.open(url, '_blank');
  }
  editTableData(data) {
    this.historyOfObj = JSON.stringify(data);
    this.dpdsByAcCodeData.forEach(obj => {
      obj.isShowInput = false;
    });
    data.isShowInput = true;
  }
  saveTableData(data) {
    let beforeObj = JSON.parse(this.historyOfObj)
    var reqBody = {
      "purpose": 'mirroring',
      "id": data.id,
      "partnerId": data.partnerId,
      "lmCapacity": data.LMCapacity ? data.LMCapacity : null
    }
    if (data.nlCode != beforeObj.nlCode) {
      reqBody['nlCode'] = data.nlCode;
    }
    if(data.nlCode==''){
      reqBody['nlCode'] = null;
    }
    if (data.partnerId) {
      this.updateDeliverypointSchemas(reqBody);
    } else {
      this.translator(this.langKey, 'NlandLmMandatory', false);
      // this.toastr.error('Nl Code and Lm Capacity is Mandatory field', 'Failed');
    }
  }
  resetTableData(data) {
    data.isShowInput = false;
    let beforeObj = JSON.parse(this.historyOfObj);
    data.nlCode = beforeObj.nlCode;
    data.lmCapacity = beforeObj.lmCapacity;
  }
  removeTableData(data) {
    this.deleteCode = true;
    this.deleteAcCode(data.deliveryPointId);
  }
  translator(lang, key, status) {
    this.langtransService.TranslateData(lang, key).subscribe((res) => {
      if (status) {
        this.toastr.success(res);
      } else {
        this.toastr.error(res);
      }
    })
  }

}
