import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { ToastrService } from 'ngx-toastr';
import { PartnerService } from '../../../Service/partner.service';
import { DeliverypointsService } from '../../../Service/deliverypoints.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LangtransService } from 'src/app/Service/langtrans.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/Service/user.service';

@Component({
  selector: 'app-partner-edit-postman',
  templateUrl: './partner-edit-postman.component.html',
  styleUrls: ['./partner-edit-postman.component.scss']
})
export class PartnerEditPostmanComponent implements OnInit {
  
  showSpinner: boolean = false;
  partnerDetails: any;
  partnerId: any
  driverData: any;
  addPartnerPostmanForm: FormGroup
  submitted: boolean;
  model: any = {};
  langKey: any;
  btnAction:string;
  selectedDriverId:number;
  currentPartnerId : number
  languageList = [{language:'en',description:'english'},
  {language:'fr',description:'french'},
  {language:'nl',description:'dutch'}]
  @ViewChild('closePostmanModal') private closePostmanModal: ElementRef;
  list: any[];
  dropdownValue: any[];
  capabilityCheck: boolean = false;
  dropSuccess: boolean = true;
  dropdownList = [];
  selectedItems = [];
  selectAll: any;
  unselectAll: any;
  searchText: any;
  capabilitiesDropdownSetting: IDropdownSettings;
  constructor(public activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private partnerService: PartnerService,
    private router: Router,
    private formBuilder: FormBuilder,
    private langtransService: LangtransService,
    private translate: TranslateService,
    private userService: UserService
  ) {
    this.dropdownList = ['CAN_COLLECT', 'CAN_DELIVER']
  }

  ngOnInit(): void {
    let partnerId = +this.activatedRoute.snapshot.params.id;
    this.partnerId = partnerId
    let currentUserInfo = this.userService.currentUser.partner.id;
    this.currentPartnerId = currentUserInfo
    this.buildPartnerForm();
    this.getPartnerDetails(partnerId);
    this.getDetails(partnerId);
    this.capabilitiesDropdownSetting = {
      singleSelection: false,
      idField: '',
      textField: '',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: false
    };
    

   
  }

  buildPartnerForm(){
    this.addPartnerPostmanForm = this.formBuilder.group({
      name: ['', Validators.required],
      username: ['', [Validators.required , Validators.pattern("^[a-zA-Z0-9]+$")]],
      code: ['', [Validators.required , Validators.pattern("^[0-9]*$")]],
      active: [true, Validators.required],
      language: ['', Validators.required],
      capabilities: ['', Validators.required],
      generateSecret:[''],
      secretValidity:['' , Validators.required],
      expiresAt:['']
    })
  }
  get formValidators() {
    return this.addPartnerPostmanForm.controls;
  }
  //Fech partner details
  getPartnerDetails(partnerId) {
    this.showSpinner = true;
    this.partnerService.fetchPartnerDetails(partnerId).subscribe(
      res => {
        this.partnerDetails = res
        this.showSpinner = false;
      },
      err => {
        this.showSpinner = false;
        this.toastr.error(this.translate.instant('Failed to fetch partner details !!'), this.translate.instant('Failed'));
        console.error(err);
      }
    )
  }
  getDetails(partnerId) {
    this.showSpinner = true;
    this.partnerService.fetchDriverDetails(partnerId).subscribe(
      res => {

        this.driverData = res
        this.driverData.forEach(driver => {
          this.languageList.forEach(lang => {
            if (driver.language === lang.language){
              driver.langDesc = lang.description;
            }
          });
        });
        
        this.showSpinner = false;
      },
      err => {
        this.showSpinner = false;
        this.toastr.error(this.translate.instant('Failed to fetch driver details !!'), this.translate.instant('Failed'));
        console.error(err);
      }
    )
  }
  resetInputValues() {
    this.model = {};

    this.addPartnerPostmanForm.reset()
    this.submitted = false
  }
  addPartnerPostman() {
    this.submitted = true
    if (this.submitted && this.capabilityCheck) {
      this.dropSuccess = true
    } else {
      this.dropSuccess = false
    }
    if (this.addPartnerPostmanForm.valid) {

      this.addPartnerPostmanSchemas(this.addPartnerPostmanForm.value)
      this.closePostmanModal.nativeElement.click();
      this.resetInputValues();
    }
  }
  updatePartnerPostman() {
    this.addPartnerPostmanForm
    this.submitted = true
    if(Object.keys(this.addPartnerPostmanForm.value.capabilities).length === 0){
      this.addPartnerPostmanForm.controls.capabilities.setErrors({required: true})
    }else{
      this.addPartnerPostmanForm.controls.capabilities.setErrors(null)
    }

    
    if (this.addPartnerPostmanForm.valid) {
      this.updatePartnerPostmanSchemas()
    }
  }
  /*
   * Functionality used to update partner deliverypoint schemas
   */
  addPartnerPostmanSchemas(reqBody) {
    this.showSpinner = true;
    reqBody.capabilities = this.selectedItems
    if(reqBody.generateSecret == true){
      reqBody.generateSecret = reqBody.generateSecret
    }else{
      reqBody.generateSecret = false
    }

    if(reqBody.secretValidity.length == 0){
      delete reqBody.secretValidity
    }
    this.partnerService.addPartnerPostmanSchemas(this.partnerId, reqBody).subscribe(
      (res: any) => {
        this.getDetails(this.partnerId);
        this.showSpinner = false;
        this.addPartnerPostmanForm.controls['code'].enable();
        this.toastr.success(this.translate.instant('Postman Added Successfully!'), this.translate.instant('Success'))
        // this.router.navigate(['/partners/', this.partnerId]);
      },
      err => {
        this.showSpinner = false;
        if (err.error.code == "DUPLICATE_USERNAME") {
          this.toastr.error(this.translate.instant('PostmanAlreadyExists'), this.translate.instant('Failed'))
        }
        else {
          // this.toastr.error('Failed to update deliverypoint schemas', 'Failed');
          this.toastr.error(this.translate.instant('Pleasetryagain'),this.translate.instant('Failed'))
          console.error(err);
        }
      }
    )
  }
  updatePartnerPostmanSchemas() {
    this.showSpinner = true;
    if(this.addPartnerPostmanForm.value.generateSecret == true){
      this.addPartnerPostmanForm.value.generateSecret = this.addPartnerPostmanForm.value.generateSecret
    }else{
      this.addPartnerPostmanForm.value.generateSecret = false
    }
    
    this.partnerService.updatePartnerPostmanSchemas(this.partnerId, this.addPartnerPostmanForm.value,this.selectedDriverId).subscribe(
      (res: any) => {
        this.closePostmanModal.nativeElement.click();
        this.getDetails(this.partnerId);
         this.showSpinner = false;
         this.toastr.success(this.translate.instant('Postman Updated Successfully!'), this.translate.instant('Success'))
      },
      err => {
        this.showSpinner = false;
        if (err.error.code == "DUPLICATE_USERNAME") {
          this.toastr.error(this.translate.instant('PostmanAlreadyExists'), this.translate.instant('Failed'))
        }
        else {
          // this.toastr.error('Failed to update deliverypoint schemas', 'Failed');
          this.toastr.error(this.translate.instant('Failed to update postman schemas'), this.translate.instant('Failed'));
          this.toastr.error(this.translate.instant('Pleasetryagain'), this.translate.instant('Failed'))
          console.error(err);
        }
        
      }
    )
  }
  editTableData(data,action:string) {
    this.btnAction = action;
    this.selectedDriverId = data.id;
    this.addPartnerPostmanForm.controls['code'].clearValidators();
    this.addPartnerPostmanForm.controls['code'].setValue('');
    this.addPartnerPostmanForm.controls['code'].updateValueAndValidity();
    this.addPartnerPostmanForm.patchValue(data);
    this.selectedItems = data.capabilities
    this.addPartnerPostmanForm.controls['secretValidity'].clearValidators();
    this.addPartnerPostmanForm.controls['secretValidity'].setValue('');
    this.addPartnerPostmanForm.controls['secretValidity'].disable();
    this.addPartnerPostmanForm.controls['code'].enable();
  }
  resetTableData() {
    this.addPartnerPostmanForm.controls['code'].setValidators([Validators.required]);
    this.addPartnerPostmanForm.controls['code'].updateValueAndValidity()
  }
  removeTableData(data) {

    this.showSpinner = true;
    this.partnerService.deletePartnerPostmanSchemas(this.partnerId, data).subscribe(
      (res: any) => {
        this.getDetails(this.partnerId);
        this.showSpinner = false;
        this.toastr.success(this.translate.instant('Postman Deleted Successfully!'), this.translate.instant('Success'))
      },
      err => {
        this.showSpinner = false;
        this.toastr.error(this.translate.instant('Failed to delete postman schemas'), this.translate.instant('Failed'));
        this.toastr.error(this.translate.instant('Pleasetryagain'), this.translate.instant('Failed'))
        console.error(err);

      }
    )
  }


  translator(lang, key, status) {
    this.langtransService.TranslateData(lang, key).subscribe((res) => {
      if (status) {
        this.toastr.success(res)
      } else {
        this.toastr.error(res)
      }
    })
  }
  goToPartnerDetails() {
    this.router.navigate(['/partners/', this.partnerId]);
  }

  addPostman(action:string){
    this.addPartnerPostmanForm.controls['code'].enable();
    this.buildPartnerForm();
    this.btnAction = action;
  
    //this.addPartnerPostmanForm.controls['code'].setValidators([Validators.required]);
  }

  onItemSelect(item: any) {
  }
  onSelectAll(items: any) {
  }

  onCheckboxChange(){
    let checkedValue = this.addPartnerPostmanForm.get('generateSecret').value 
    if(checkedValue === true){
      this.addPartnerPostmanForm.controls['code'].setValue('');
      this.addPartnerPostmanForm.controls['code'].disable();
      this.addPartnerPostmanForm.controls['secretValidity'].setValidators([Validators.required]);
      this.addPartnerPostmanForm.controls['secretValidity'].enable();
      
     }else{
      this.addPartnerPostmanForm.controls['secretValidity'].disable();
      this.addPartnerPostmanForm.controls['code'].enable();
     }
  }

  onCheckboxChangeAddOption(){
    let checkedValue = this.addPartnerPostmanForm.get('generateSecret').value 
    if(checkedValue){
      this.addPartnerPostmanForm.controls['code'].setValue('');
      this.addPartnerPostmanForm.get('code').disable()
     }else{
      this.addPartnerPostmanForm.controls['code'].setValidators([Validators.required,Validators.pattern(/^\d+$/)]);
      this.addPartnerPostmanForm.get('code').enable()
     }
  }

  onInputChange(){
    let codeValue = this.addPartnerPostmanForm.get('code').value 
    if(typeof(codeValue) == "string"){
      this.addPartnerPostmanForm.controls['code'].setValidators([Validators.pattern("^[0-9]*$")]);
    }
    const slectcontrl =  this.addPartnerPostmanForm.get('secretValidity')
    if(codeValue){
      this.addPartnerPostmanForm.controls['secretValidity'].setValidators([Validators.required]);
      slectcontrl?.enable();
     }else{
      slectcontrl?.disable();
     }
  }
}
