<app-header></app-header>
<div [appViewController]="{partner:'showList'}">
  <br />
  <ng-template #tipContentType><b>DELIVERY</b>&nbsp;<i>Can make use of the network</i><br>
    <b>OWNER</b>&nbsp;<i>Owns a piece of the network</i></ng-template>
  <div style="display:inline">
    <!-- <input type="image" src="/assets/img/filter.png" style="margin-top: 1%;margin-left: 13px; margin-bottom: 1.5%; "
      name="submit" width="35" height="35" alt="submit" data-toggle="collapse" data-target="#collapseExpiryDate"
      aria-expanded="false" aria-controls="collapseExpiryDate"> -->
    <nav class="navbar navbar-expand-lg navbar-dark pr-1">
      <ul class="navbar-nav mr-0 ml-auto mt-0 mt-lg-0">
        <li class="nav-item">
          <input type="image" src="/assets/img/filter.png" name="submit" width="30" height="30" alt="submit"
            data-toggle="collapse" data-target="#collapseExpiryDate" aria-expanded="false"
            aria-controls="collapseExpiryDate">
        </li>
        <li class="nav-item">
          <input
            type="image"
            src="/assets/img/redIcon.png"
            name="submit"
            width="28"
            height="28"
            alt="submit"
            (click)="downloadAll()">
        </li>
      </ul>
    </nav>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="col-12 collapse" id="collapseExpiryDate">
          <form [formGroup]="filterForm" class="pl-4 pr-4">
            <div class="card shadow p-3 mx-auto bg-white rounded mt-0" style="width: 80%;">
              <div class="card-body p-3">
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="groups">{{'Partner'|translate}}</label>
                    <ng-multiselect-dropdown formControlName="partnerName" [placeholder]="'-All-'|translate"
                      [settings]="bussinessPartnerSettings" [data]="FilterPartnersList">
                    </ng-multiselect-dropdown>
                    <!-- <small class="row">
                      <small class="col-sm-3 ml-4"></small>
                      <small *ngIf="submitted &&  formValidators.partnerName.errors?.required"
                        class="form-text text-muted col-sm-8">{{'Please enter partner name' | translate}}
                      </small>
                    </small> -->
                  </div>

                  <div class="form-group col-md-6" style="margin-top: 2em;">
                    <div class="col">
                      <button class="btn btn-outline-danger  mb-3 mr-3" type="submit"
                        (click)="onSubmit()">{{'Filter'|translate}}</button>
                      <button class="btn btn-outline-danger  mb-3" (click)="onReset()">{{'Reset'|translate}}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="table-responsive pl-4 pr-4" style="margin-bottom: 25px; margin-top: 20px;">
          <!-- <nav class="navbar navbar-expand-lg navbar-dark pr-1">
      <ul class="navbar-nav mr-0 ml-auto mt-0 mt-lg-0">
        <li class="nav-item">
          <input type="image" src="/assets/img/redIcon.png" name="submit" width="28" height="28" alt="submit"
            (click)="downloadAll()">
        </li>
      </ul>
    </nav> -->
          <table
            datatable
            [dtOptions]="dtOptions"
            [dtTrigger]="dtTrigger"
            class="table table-sm table-bordered table-hover row-border hover"
            style="text-align:center;"
          >
            <caption></caption>
            <thead class="thead-dark">
              <tr>
                <th scope="col">{{'Id'|translate}}</th>
                <th scope="col">{{'Name'|translate}}</th>
                <th scope="col">{{'Country'|translate}}</th>
                <th scope="col">{{'Type'|translate}} <i class="fa fa-info-circle info-icon-background tooltip_head"
                    [ngbTooltip]="tipContentType" placement="right"></i></th>
                <th scope="col">{{'Active'|translate}}</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody *ngIf="partnersList?.length != 0">
              <tr *ngFor="let partner of partnersList;">
                <td> {{partner.id}}</td>
                <td>
                  {{partner.name}}
                </td>
                <td>{{partner.country}}</td>
                <td>{{partner.type}}</td>
                <td>{{partner.active}}</td>
                <td>
                  <button
                    type="button"
                    class="btn btn-sm mr-2 btn-success"
                    [routerLink]="['/partners/', partner.id]"
                    [appViewController]="{partner:'editPartner'}">{{'EDIT'| translate}}</button>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="partnersList?.length == 0">
              <tr>
                <td colspan="4" class="no-data-available">{{'No data!'|translate}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<div #partnerEdit [appViewController]="{partner:'editPartner'}"></div>
