import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/Service/user.service';

@Component({
  selector: 'app-partner-tools',
  templateUrl: './partner-tools.component.html',
  styleUrls: ['./partner-tools.component.scss']
})
export class PartnerToolsComponent implements OnInit {
  isJJCustomer: boolean;

  constructor(
    private userService: UserService
  ) { }

  ngOnInit(): void {
    let Ironing = ['J&J']
    let userPartner = this.userService?.currentUser?.partner?.name;
    this.isJJCustomer = Ironing.includes(userPartner)
  }

}
