import { Inject, Injectable } from '@angular/core';

interface Cookie {
    name: string;
    value: string;
}

@Injectable({
    providedIn: 'root',
})
export class AppCookieService {
    private cookieStore = {};

    constructor() {
        this.parseCookies(document.cookie);
    }

    
    public parseCookies(cookies = document.cookie) {
        this.cookieStore = {};
        if (!!cookies === false) { return; }
        const cookiesArr = cookies.split(';');
        for (const cookie of cookiesArr) {
            const cookieArr = cookie.split('=');
            this.cookieStore[cookieArr[0].trim()] = cookieArr[1];
        }
    }
    

    
    get(key: string) {
        this.parseCookies();
        return !!this.cookieStore[key] ? this.cookieStore[key] : null;
    }
   

    delete(key: string) {
        document.cookie = `${key} = ; expires=Thu, 1 jan 1990 12:00:00 UTC; path=/`;
    }

    set(key: string, value: string) {
        //document.cookie = key + '=' + (value || '');
        document.cookie = `${key}=${value}; path=/`;
    }

    setWithExpiry(key: string, value: string, expiresInMin: number) {
        if (expiresInMin) {
            var date = new Date();
            date.setTime(date.getTime() + (expiresInMin * 1000));
            var expires = "; expires=" + date.toUTCString();

        } else {
            var expires = "";
        }
        document.cookie = key + "=" + value + expires + "; path=/";
    }
    
    getAllCookies(): Cookie[] {
        const cookies: Cookie[] = [];
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
    
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1);
            const [name, ...valueParts] = c.split('=');
            const value = valueParts.join('=');
            if (name && value) {
                cookies.push({ name, value });
            }
        }
    
        return cookies;
    }
    
    dumpAllCookies(loc: string): void
    {
        const cookies = this.getAllCookies();
        console.log('dumping cookies from location:'+loc, JSON.stringify(cookies) ); // Affiche la liste des cookies
    }
    
}