<div class="mx-5 mt-5">
    <h2 style="color: red;">{{'Settings'|translate}}</h2>
    <div style="margin: 0 auto;width: 85%;">
        <form [formGroup]="partnerSettingForm">
        <div class="table-responsive">
            <div class="mt-8">
                <table class="table table-bordered" >
                    <caption></caption>
                    <thead>
                        <tr>
                            <th scope=""  colspan="2">{{'standardSettingsPackages'|translate}}</th>
                        </tr>
                        <tr>
                            <th scope="" rowspan="2">{{'Packages'|translate}}</th>
                            <td>
                                <div class="container">
                                <div class="row" style="align-items: center;">
                                    <div class="col-12 col-md-5">{{'minimumReservationPeriod'|translate}}</div>
                                    <div class="col-12 col-md-7">
                                    <input type="text" class="ml-md-5 mr-2 form-control phone_screen_width" style=" display: inline;"  id="minReservePeriod"
                                    formControlName="minReservePeriod" (input)="onInputChange($event)">
                                    <span>{{'Hours'|translate}}</span>
                                </div>
                                </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="container">
                                    <div class="row" style="align-items: center;">
                                        <div class="col-12 col-md-5">{{'Dimensions'|translate}}(LxWxH)</div>
                                        <div class="col-12 col-md-7">
                                        <input  class="ml-md-5  form-control phone_screen_width" style="display: inline;" id="length"
                                        formControlName="length" (input)="onInputChange($event)">
                                        <span>x</span>
                                        <input  class=" form-control phone_screen_width " style="display: inline;" id="width"
                                        formControlName="width" (input)="onInputChange($event)">
                                        <span>x</span>
                                        <input  class=" form-control phone_screen_width" style="display: inline;" id="height"
                                        formControlName="height" (input)="onInputChange($event)">
                                        <span>mm</span>
                                    </div>
                                    </div>
                                    </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <button class="btn btn-danger" style="float: right;" type="submit" (click)="saveSettingDetails()">{{'Save'|translate}}</button>
                            </td>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
    </form>
    </div>

</div>
