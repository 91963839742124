import { Injectable } from '@angular/core';
import { losEnvironment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  // public partnerId
  constructor(private http: HttpClient) {
    
  }

  lockerDown(body) {
    return this.http.post(`${losEnvironment.baseUrl}/reports/lockerdowntime`, body).toPromise();
  }
}