import { Component, OnInit, ViewChild, ElementRef, Inject, LOCALE_ID } from '@angular/core';
import { DeliverypointsService } from '../../../Service/deliverypoints.service';
import { ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationComponent } from "src/app/modals/confirmation/confirmation.component";
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { utcFormatter } from '../../timeFormatter';
import { SpinnerService } from 'src/app/Service/spinner.service';

@Component({
  selector: 'app-terminals',
  templateUrl: './terminals.component.html',
  styleUrls: ['./terminals.component.scss']
})
export class TerminalsComponent implements OnInit {
  id: any
  dpdetails: any

  layoutAvailable: boolean
  nestedArr: any;
  dtOptions: DataTables.Settings = {};
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  terminalArr: any;
  sortDir: number;
  compartments: any;
  classList: any;
  compart: any;
  number: any;
  valuesArr: any[];
  key: any;
  reverse: boolean = true
  parcels: any;
  parcelsCount: any;
  reservationsCount: any;
  deliveryCount:any;
  pickupCount:any;
  sortingAllowed: boolean = true
  disableComment: string
  disabledCompartment: any;
  showUnauthorized: boolean = false;
  showSpinner: boolean = false;
  error: any;
  @ViewChild('myDiv', { static: true }) myDiv: ElementRef;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    public activatedRoute: ActivatedRoute,
    private el: ElementRef, private deliverypointservice: DeliverypointsService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    private spinner: SpinnerService
  ) {

  }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id')
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      destroy: true,
      search: false
    };
    this.fetchTerminalDetails()

  }

  ngAfterViewInit() {
    let divElement = this.myDiv.nativeElement;
    let divstyle = window.getComputedStyle(divElement)
    let display = divstyle?.display;
    if (display == 'none') {
      this.showUnauthorized = true
    }
  }

  fetchTerminalDetails() {
    this.spinner.show();
    this.deliverypointservice.fetchwithId(this.id, {
      includeTerminals: true,
      includeCompartments: true
    })
      .then(res => {
        this.dpdetails = res
        this.terminalArr = this.dpdetails.terminals;
        this.key = "number"
        if (this.sortingAllowed)
          this.sort(this.key)
        this.parcels = this.terminalArr[0].compartments;
      //  this.parcelsCount = this.parcels.filter(obj => obj.loaded === true && obj.parcelid != null).length;
        // this.reservationsCount = this.parcels.filter(obj => obj.parcelid != null && !obj.loaded).length
        this.reservationsCount = this.parcels.filter(obj => obj.status === 'RESERVED').length
        this.deliveryCount = this.parcels.filter(obj => obj.status === 'DELIVERY_LOCKER_FILLED').length
        this.pickupCount = this.parcels.filter(obj => obj.status === 'PICKUP_LOCKER_FILLED').length

        if (this.dpdetails.length > 0) {
          this.dtTrigger.next();
          // this.rerender()
        }

        if (this.dpdetails.type == 'Lean Locker') {
          this.nestedArr = this.dpdetails.terminals
        }
        this.guessLayoutIsAvailable()
        this.spinner.hide();
      })
      .catch(ex => {
        this.spinner.hide();
        this.error = ex?.error?.error;
        console.error(ex);
      })
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  /**
   * Guesses if there is a layout to show based on stats & terminals information
   */
  guessLayoutIsAvailable() {
    this.layoutAvailable =
      this.dpdetails.stats.compartments > 0 &&
      this.dpdetails.terminals.length > 0 &&
      this.dpdetails.terminals.find(t => !!t.compartments.length)
  }



  updateStatus(id: any, x: any, ter: any, com: any, ext: any) {
    let result
    let initialState = {}
    if (x === 1) {
      result = {
        deliverypoint: id,
        command: "open",
        terminal: ter,
        compartment: com,
        ext_id: ext,
        type: this.dpdetails.type
      }
    } else if (x === 2) {
      result = {
        deliverypoint: id,
        command: "close",
        terminal: ter,
        compartment: com,
        ext_id: ext,
        type: this.dpdetails.type

      }
    } else if (x === 3) {
      result = {
        deliverypoint: id,
        command: "enable",
        terminal: ter,
        compartment: com,
        ext_id: ext,
        type: this.dpdetails.type

      }
    } else if (x === 4) {
      result = {
        deliverypoint: id,
        command: "disable",
        terminal: ter,
        compartment: com,
        ext_id: ext,
        type: this.dpdetails.type,
        remarks: this.disableComment
      }
    }

    initialState = { "currentCommand": result.command }
    if (result.command == 'open') {
      const modalRef = this.modalService.show(ConfirmationComponent, {
        initialState,
        backdrop: 'static',
        keyboard: true,
        class: "modal-md"
      });
      (modalRef.content?.onClose as Subject<any>).subscribe((res) => {
        if (res === "confirm") {

          this.disableComment = ''
          this.deliveryPointUpdateCommand(id, result)
        }
      })
    } else {
      this.deliveryPointUpdateCommand(id, result)
    }
  }

  deliveryPointUpdateCommand(id, result) {
    this.deliverypointservice.updateCommand(id, result).subscribe(
      res => {
        this.sortingAllowed = false
        this.fetchTerminalDetails()
      },
      err => {
        this.sortingAllowed = true
        console.error(err);
      }
    )
  }



  sort(key) {
    this.sortingAllowed = true
    this.key = key;
    this.reverse = !this.reverse
  }
  disabledValues(id: any, ter: any, com: any, ext: any) {
    this.disabledCompartment = {
      dp: id,
      term: ter,
      comp: com,
      ext_id: ext,
    }
  }
  timeFormatter(date) {
    const formattedDateTime = utcFormatter(date);
    if (!formattedDateTime) return null
    return moment(formattedDateTime).format('DD-MM-YYYY HH:mm:ss')
  }

  reloadCurrentPage() {
    window.location.reload();
 }
}
