import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PartnerService } from '../Service/partner.service';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '../Service/app.service';
import { LangtransService } from '../Service/langtrans.service';

import * as  XLSX from 'xlsx'
import { SpinnerService } from '../Service/spinner.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-partner-customer',
  templateUrl: './partner-customer.component.html',
  styleUrls: ['./partner-customer.component.scss']
})
export class PartnerCustomerComponent implements OnInit {
  errorTxt: boolean;
  selectedFile: any;
  fileSizeExceeded: boolean;
  invalid: boolean;
  xmlString: string;
  uploadForm: FormGroup
  selectedFilePath: any
  fileName: string;
  submitted: boolean;
  customerDetails: any;
  translateData: any;
  fileType: string;
  excelData: string;
  lineCount: number = 0;
  orderFound: boolean = true;
  fileError: any = null;
  constructor(private formBuilder: FormBuilder, private partnerService: PartnerService, private toastr: ToastrService, private appService: AppService, private langtransService: LangtransService,
    private spinner: SpinnerService,
    private translate: TranslateService
  ) {
    this.uploadForm = this.formBuilder.group({
      file: ['', Validators.required],
    })
  }

  ngOnInit(): void {
    this.partnerService.getCustomer().subscribe(
      res => {
        this.customerDetails = res;
        if (this.customerDetails.length == 0) {
          this.orderFound = false
        }
        else {
          this.orderFound = true
        }
      },
      err => {
        this.orderFound = false
      }
    )
    this.appService.languagetoggle$.subscribe((data: any) => {
      this.translateData = data
    })
  }

  onInputClick(event: any){
    this.uploadForm.get('file').reset()
    event.target.value = "";
  }

  onFileChange(event: any): void {
    this.spinner.show();
    this.errorTxt = false;
    this.fileError = null;
    this.selectedFile = event.target.files[0]
    const target = event.target as HTMLInputElement;
    const file: File = (target.files)[0];
    this.fileName = file ? file.name : null

    if (file) {
      const validationData = this.validateFile(file);
      if (validationData.type == 'csv') {
        this.convertFileToString(file);
      }
      else {
        this.convertFileToString(file);
      }

    } else {
      this.errorTxt = true;
    }
    this.spinner.hide();
  }
  convertFileToString(file: File) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const workbook = XLSX.read(new Uint8Array(e.target.result), { type: 'array' })
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName]
      const jsonString = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
      this.identifyErrors(jsonString);
      
      this.xmlString = this.sheetArraytoString(jsonString)
      this.excelData = null
      this.lineCount = 0

    }
    reader.readAsArrayBuffer(file);
  }
  
  convertCSVToString(file: File) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.xmlString = e.target.result as string;
      const data = this.xmlString.split(',')
      if (data.length == 7) {
        this.xmlString = null
      }
      let rowData = this.xmlString.split('\r\n');
      let arrFormatData = rowData.map(row => {
        let rowArray = row.split(',');
        return rowArray.map((value) => {
          return value.replace("\"", "").replace("\"","")
        })
      })
      this.identifyErrors(arrFormatData);
    }
    reader.readAsText(file);
  }

  identifyErrors(jsonStringArray){
    //convert json string to key-> object array
    const headerArr: any = jsonStringArray[0];
    const excelFileData = [];
      jsonStringArray.slice(1).forEach((exlRecord) => {
        let itemObj = {};
        headerArr.forEach((key, index) => {
          itemObj[key] = exlRecord[index];
        })
        excelFileData.push(itemObj);
      });
      const errList = this.validateFileData(excelFileData);
      if (errList.length > 0) {
        this.fileError = errList.map((errKey) => {
          return this.translate.instant(errKey);
        });
      }
  }

  validateFileData(fileData): any {
    const errObj = {
      invalidLanguage: "invalidLanguage",
      duplicateCustomerID: "duplicateCustomerID"
    }
    let err = [];
    for (var i = 0; i < fileData.length; i++) {
      if (fileData[i].TAAL) {
        if (['fr', 'nl', 'en'].includes(fileData[i].TAAL.toLowerCase()) === false) {
          err.push(errObj.invalidLanguage);
        }
      }
      if (err.length > 0) {
        break;
      }
    }
    return err;
  }

  findDuplicates(arr) {
    return arr.filter((currentValue, currentIndex) =>
      arr.indexOf(currentValue) !== currentIndex);
  }

  validateFile(file: File): any {
    this.fileSizeExceeded = false
    const allowedTypes = ['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    if (!allowedTypes.includes(file.type)) {
      this.invalid = true;
    } else if (file.size > 5242880) {
      this.invalid = true;
      this.fileSizeExceeded = true
    } else if (file.type == 'text/csv') {
      this.fileType = 'csv'
      this.invalid = false;
    } else {
      this.fileType = 'xlsx'
      this.invalid = false;
    }
    return { invalid: this.invalid, type: this.fileType }
  }
  onUpload() {
    this.submitted = true
    for (const control in this.uploadForm.controls) {
      if (this.uploadForm.controls.hasOwnProperty(control)) {
        this.uploadForm.get(control).markAsTouched();
      }
    }
    if (this.uploadForm.valid) {
      this.spinner.show();
      let reqObj = {
        fileReq: this.xmlString,
      }
      if (this.xmlString && this.selectedFile && this.invalid === false) {
        this.partnerService.addCustomer(reqObj).subscribe(
          res => {
            this.spinner.hide();
            //this.toastr.success('File uploaded','Success')
            let response = JSON.stringify(res)
            let parsedRes = JSON.parse(response)
            if (parsedRes.status == 'Duplicate Email') {
              this.langaugeFetch(this.translateData, 'DuplicateEmail', 'warning')
              this.uploadForm.reset()
              this.fileError = null;
              this.uploadForm.get('file').reset()
              this.ngOnInit()
              this.errorTxt = true
            }
            else {
              this.langaugeFetch(this.translateData, 'Fileuploaded', 'success')
              this.uploadForm.reset()
              this.fileError = null;
              this.uploadForm.get('file').reset()
              this.ngOnInit()
              this.errorTxt = true
            }
          },
          err => {
            this.spinner.hide();
            if (err.error.status) {
              this.fileError = err.error.status.map((errKey) => {
                return errKey
              });
              this.uploadForm.get('file').reset()
              this.errorTxt = true
            }
            else {
              this.langaugeFetch(this.translateData, 'Failedtoupload', 'error')
              this.uploadForm.get('file').reset()
              this.errorTxt = true
            }
          }
        )

      } else {
        if (!this.xmlString) {
          this.langaugeFetch(this.translateData, 'No customers were found.', 'error')
          this.uploadForm.get('file').reset()
          this.errorTxt = true
          this.spinner.hide();
        }
        this.errorTxt = !this.invalid
      }
    }
  }

  langaugeFetch(lang, key, toastrstatus) {
    this.langtransService.TranslateData(lang, key).subscribe(
      res => {
        if (toastrstatus == 'success') {
          this.toastr.success(res)
        }
        if (toastrstatus == 'error') {
          this.toastr.error(res)
        }
        if (toastrstatus == 'warning') {
          this.toastr.warning(res)
        }
      }
    )
  }
  sheetArraytoString(sheetArray: any[]): any {
    const header = sheetArray[0].map(value => `"${value}"`).join(',');
    sheetArray.forEach((item) => {
      if (item.length > 0) {
        this.lineCount += 1
        const rows = sheetArray.slice(1).map(row => row.map(value => (typeof value === 'number') ? value : `"${value}"`).join(','));
        this.excelData = `${header}\r\n${rows.join('\r\n')}\r\n`
      }
    })
    if (this.lineCount > 1) {
      return this.excelData
    }
  }
  displayValue(value: any): any {
    if (value !== undefined && value !== null && value !== '') {
      return value
    } else if (value === 0) {
      return 0
    }
    return '-'
  }
}
