<app-loader *ngIf="showSpinner"></app-loader>
<app-header></app-header>
<div class="container">
    <br />
    <div class="row">
        <div class="col-3">
            <app-partner-info-menu [partner]=partnerDetails></app-partner-info-menu>
        </div>
        
        <div class="col-9">
            <h3>Times</h3>
            <form [formGroup]="settingForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                  <label>DWELL time in seconds</label>
                  <input
                    type="number"
                    formControlName="dwell"
                    class="form-control" />
                </div>
                <button class="btn btn-secondary  mr-1" (click)="goToPartnerDetails()">{{'Back'|translate}}</button>
                &nbsp;
                <button type="submit" class="btn btn-primary">Submit</button>
              </form>
        </div>
    </div>
</div>