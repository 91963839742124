import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from '../../Service/authorization.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  public userInfo;
  public authorized: boolean;
  public email: string;
  constructor(
    public authorizationService: AuthorizationService,
    public router: Router
  ) {

  }

  ngOnInit() {
  }

  loginReuseService(): void
  {
    this.authorizationService.loginReuseService();
  }

  authorizeExternal(): void
  {
    this.authorizationService.authorizeExternal();
  }
}
