import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../Service/user.service';
import { group } from '@angular/animations';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { LangtransService } from 'src/app/Service/langtrans.service';
import { AppService } from '../../../Service/app.service';
import { HttpClient } from '@angular/common/http';
import { PartnerService } from 'src/app/Service/partner.service';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from 'src/app/Service/spinner.service';


@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserDetailsComponent implements OnInit {
  userEmail: any
  userGroups: any
  partner: any
  emailPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$';
  submitted: boolean
  groups: any
  partners: any
  parcelLockerNamedropdownList = [];
  userGroupdropdownList = [];
  userGroupselectedItems = [];
  partnersList = [];
  partnerSelectedItems = [];
  orderTypedropdownSettings: IDropdownSettings;
  userGroupdropdownSettings: IDropdownSettings;
  partnerDropdownSettings: IDropdownSettings;
  lockerFetchfail: any;
  translateData: any;
  public addUserForm
  selectAll: any;
  unselectAll: any;
  searchText: string;
  selectedUserType: any = "internal";
  courierType: any;
  courierList: any;
  courierSelectedItem = []
  showLoader: boolean = false;
  mobnumPattern = "^[- +()]*[0-9][- +()0-9]*$";
  constructor(private http: HttpClient, private langtransService: LangtransService,
    private appService: AppService, private userService: UserService, private partnerService: PartnerService,
    public router: Router, private toastr: ToastrService,
    private translate: TranslateService,
    private spinner: SpinnerService
  ) { }

  ngOnInit(): void {
    this.courierType = [{ id: 1, type: 'DELIVERY' }, { id: 2, type: 'OWNER' }]
    this.courierList = this.courierType.map(obj => obj.type);
    this.fetchPartnerList()
    this.fetchUserGroups()
    this.appService.languagetoggle$.subscribe((data: any) => {
      this.translateData = data
      this.http.get(`/assets/i18n/${data}.json`).subscribe((data: any) => {
        this.selectAll = data.selectAll
        this.unselectAll = data.UnSelectAll
        this.searchText = data.Search
        this.userGroupdropdownSettings = {
          singleSelection: false,
          idField: 'id',
          textField: 'type',
          selectAllText: this.selectAll,
          unSelectAllText: this.unselectAll,
          itemsShowLimit: 5,
          allowSearchFilter: true,
          searchPlaceholderText: this.searchText
        };
        this.partnerDropdownSettings = {
          singleSelection: true,
          idField: 'id',
          textField: 'type',
          // selectAllText: this.selectAll,
          // unSelectAllText: this.unselectAll,
          // allowSearchFilter: true,
          closeDropDownOnSelection: false,
          searchPlaceholderText: this.searchText
        }
      })
    })

    this.addUserForm = new FormGroup({
      userType: new FormControl('', []),
      userEmail: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
      // userConfirmEmail: new FormControl('',[]),
      // userName: new FormControl(''),
      userfirstName: new FormControl('', [Validators.required]),
      userlastName: new FormControl('', [Validators.required]),

      userGroups: new FormControl('', [Validators.required]),
      // name: new FormControl('', []),
      partner: new FormControl('', [Validators.required]),
      usermobileNumber: new FormControl('', Validators.pattern(this.mobnumPattern)),
      // partnerType: new FormControl('', [Validators.required])
    })
  }

  get F() {
    return this.addUserForm.controls
  }
  onItemSelect(item: any) {
  }
  onSelectAll(items: any) {
  }

  postUserDetails() {
      let body = {}
      let groups = []
      let partnerId = []
      if (this.selectedUserType == 'external') {

        // this.testUseradd()
        body["firstName"] = this.addUserForm.value.userfirstName;
        body["lastName"] = this.addUserForm.value.userlastName;
        body["email"] = this.addUserForm.value.userEmail;

        for (let i = 0; i < this.addUserForm.value.userGroups.length; i++) {
          groups.push(this.addUserForm.value.userGroups[i].id)
        }
        for (let i = 0; i < this.addUserForm.value.partner.length; i++) {
          partnerId.push(this.addUserForm.value.partner[i].id)
        }
        body["groups"] = groups
        body["partner"] = partnerId[0] // As selecting only one partner at a time
        body["userType"] = "External"
        body["mobilenumber"] = this.addUserForm.value.usermobileNumber;
        // body["courierType"] = this.addUserForm.value.partnerType[0]
        this.setUser(body)
      }
      else {
        // let name = this.addUserForm.value.userEmail
        // body["name"] = this.addUserForm.value.userName;
        body["email"] = this.addUserForm.value.userEmail;

        for (let i = 0; i < this.addUserForm.value.userGroups.length; i++) {
          groups.push(this.addUserForm.value.userGroups[i].id)
        }
        for (let i = 0; i < this.addUserForm.value.partner.length; i++) {
          partnerId.push(this.addUserForm.value.partner[i].id)
        }
        body["groups"] = groups
        body["partner"] = partnerId[0] // As selecting only one partner at a time
        body["userType"] = "Internal"
        body["name"] = this.addUserForm.value.userfirstName
        body["firstName"] = this.addUserForm.value.userfirstName;
        body["lastName"] = this.addUserForm.value.userlastName;
        body["mobilenumber"] = this.addUserForm.value.usermobileNumber;
        // body["courierType"] = this.addUserForm.value.partnerType[0]
        this.setUser(body)

      }
  }

  setUser(body: any) {
    this.showLoader = true;
    this.userService.addUser(body).subscribe(
      res => {
        this.showLoader = false;
        this.langaugeFetch(this.translateData, 'UserAddedSuccessfully', 'success')
        //this.toastr.success('User Added Successfully', 'Success');
        this.router.navigate(['/user/search']);
      },
      err => {
        this.showLoader = false;
        // this.langaugeFetch(this.translateData, 'AddingUserFailed', 'error')
        this.toastr.error(this.translate.instant(err?.error?.message), this.translate.instant('failed'));

        //this.toastr.error('Adding User Failed !!', 'Failed');
        console.error(err);
      })

  }

  onTypeChange() {
    if (this.selectedUserType == 'external') {

      this.addUserForm.controls['userfirstName'].setValidators([Validators.required]);
      this.addUserForm.controls['userfirstName'].updateValueAndValidity()
      this.addUserForm.controls['userlastName'].setValidators([Validators.required]);
      this.addUserForm.controls['userlastName'].updateValueAndValidity()
      // this.addUserForm.controls['password'].setValidators([Validators.required]);
      // this.addUserForm.controls['password'].updateValueAndValidity()
      // this.addUserForm.controls['userName'].clearValidators();
      // this.addUserForm.controls['userName'].reset();
      // this.addUserForm.controls['userName'].updateValueAndValidity()
    }
    else if (this.selectedUserType == 'internal') {
      // this.addUserForm.controls['userName'].setValidators([Validators.required]);
      // this.addUserForm.controls['userName'].updateValueAndValidity()
      // this.addUserForm.controls['userfirstName'].clearValidators();
      // this.addUserForm.controls['userfirstName'].reset();
      // this.addUserForm.controls['userfirstName'].updateValueAndValidity()
      // this.addUserForm.controls['userlastName'].clearValidators();
      // this.addUserForm.controls['userlastName'].reset();
      // this.addUserForm.controls['userlastName'].updateValueAndValidity()
      this.addUserForm.controls['userfirstName'].setValidators([Validators.required]);
      this.addUserForm.controls['userfirstName'].updateValueAndValidity()
      this.addUserForm.controls['userlastName'].setValidators([Validators.required]);
      this.addUserForm.controls['userlastName'].updateValueAndValidity()
      // this.addUserForm.controls['password'].clearValidators();
      // this.addUserForm.controls['password'].reset();
      // this.addUserForm.controls['password'].updateValueAndValidity()
    }
  }

  fetchUserGroups() {
    this.spinner.show();
    let userGroupList = []
    this.userService.fetchGroups().subscribe(
      res => {
        this.spinner.hide();
        this.groups = res;
        for (let i = 0; i < this.groups.length; i++) {
          userGroupList[i] = { id: this.groups[i].id, type: this.groups[i].name };
        }
        this.userGroupdropdownList = userGroupList
      }, err => {
        this.spinner.hide();
      }
    )
  }

  fetchPartnerList() {
    this.spinner.show();
    this.partnerService.fetchPartnersList().subscribe(
      res => {
        this.spinner.hide();
        this.partners = res;
        let test = []
        for (let i = 0; i < this.partners.length; i++) {
          test[i] = { id: this.partners[i].id, type: this.partners[i].name };
        }
        this.partnersList = test;
      }, err => {
        this.spinner.hide();
      }
    )
  }

  onSubmit() {

    if (this.addUserForm.valid) {

      this.postUserDetails()
    }
    else {

      this.submitted = true;
    }
  }
  langaugeFetch(lang, key, status) {
    this.langtransService.TranslateData(lang, key).subscribe(
      res => {
        if (status == 'success')
          this.toastr.success(res)
        if (status == 'error')
          this.toastr.error(res)
      }
    )
  }
  cancel = () => {
    this.router.navigate(['/user/search'])
  }
  restrictAlphabets(e) {
    var x = e.which || e.keycode;
    if ((x >= 48 && x <= 57) || x == 43 || x == 40 || x == 41 || x == 45 || x == 32 || x == 8 || x == 9 || x == 27 || x == 127)
      return true;
    else
      return false;
  }
}
