import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable} from 'rxjs';
import { UserService } from './Service/user.service';
import { AuthorizationService } from './Service/authorization.service';
import { map } from 'rxjs/operators';
import { AppService } from './Service/app.service';

@Injectable({
  providedIn: 'root'
})
export class PartnertoolauthGuard implements CanActivate {
  userDetails: any;
  status: boolean;

  constructor(
    private userService: UserService,
    private authorizationService: AuthorizationService,
    private router: Router,
    private appService: AppService
  ){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let userData =JSON.parse(window.localStorage.getItem('userData'))
    if(this.authorizationService.isLoggedIn() && userData){
      let userEmail = userData.email||userData.Email
      let filterVal= {
        email: userEmail.toLowerCase(),
      }

   return this.userService.fetchMany(filterVal).pipe(map((res:any[])=>{
    this.userDetails = res[0].disablePartner ? res[0].disablePartner : false
    this.appService.partner = res[0].partner ? res[0].partner: null
    let Ironing = ['J&J']
    const partnerToolRoute = state.url.startsWith('/partnertools')
    const partnerCustomerRoute = state.url.startsWith('/partnertools/customer')
    if(partnerToolRoute){
      if(this.userDetails){
        this.router.navigate(['user/forbidden'])
        return false
      }
      if(partnerCustomerRoute && !Ironing.includes(res[0].partner.name))
        {
          this.router.navigate(['user/forbidden'])
          return false
        }

    }
    return true;
   })
   )
  }
    //not logged in so redirect to login page with the return url
    this.router.navigate([''])
    return false;
  }
}
