import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppCookieService } from './Service/app.cookie.service';
import { UserService } from './Service/user.service';
import { AuthorizationService } from './Service/authorization.service';

@Injectable({
  providedIn: 'root'
})
export class RoutingGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private router: Router,
    private cookie: AppCookieService
  ) { }
  canActivate(  
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

      const urlParts = state.url.split('/');

      // Only related to /deliverypoints/123456
      const inDeliverypointMain = urlParts.length === 3 && urlParts[1] === 'deliverypoints';

      if (!inDeliverypointMain) return true;

      const canAccessMain = this.userService.checkPermission('deliveryPoints', 'showTerminals');
      const canAccessDetails = this.userService.checkPermission('deliveryPoints', 'showDetails');
      const canAccessLogs = this.userService.checkPermission('deliveryPoints', 'showLogs');
      const canAccessDowntime = this.userService.checkPermission('deliveryPoints', 'showDowntime');

      if (canAccessMain) return true;

      if (!canAccessMain && canAccessDetails) {
        this.router.navigate([state.url + '/details']);
        return false;
      }

      if (!canAccessMain && canAccessLogs) {
        this.router.navigate([state.url + '/logs']);
        return false;
      }

      if (!canAccessMain && canAccessDowntime) {
        this.router.navigate([state.url + '/connectivity']);
        return false;
      }

      return true;
  }
}
