import { Injectable, Inject, Output } from '@angular/core';
import {
  AuthorizationServiceConfigurationJson,
  AuthorizationServiceConfiguration,
  AuthorizationRequest,
  RedirectRequestHandler,
  FetchRequestor,
  LocalStorageBackend,
  DefaultCrypto,
  BaseTokenRequestHandler,
  AuthorizationNotifier,
  TokenRequest,
  GRANT_TYPE_AUTHORIZATION_CODE
} from '@openid/appauth';
import {NoHashQueryStringUtils} from './noHashQueryStringUtils';
import {ActivatedRoute, Router} from '@angular/router';
import { AuthorizationConfigExternal } from './authorization_config_external';
import { AuthorizationService } from '../Service/authorization.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  configuration: AuthorizationServiceConfigurationJson = null;
  error: any = null;
  authorizationHandler: any = null;
  tokenHandler: any = null;
  notifier: any = null;
  request: any = null;
  response: any = null;
  code: string;

  constructor(private route: ActivatedRoute, 
    private router: Router, 
    private userService: UserService,
    public authorizationService: AuthorizationService,
    @Inject('AuthorizationConfigExternal') private pingEnvironment: AuthorizationConfigExternal
    ) {
    this.authorizationHandler = new RedirectRequestHandler(
      new LocalStorageBackend(),
      new NoHashQueryStringUtils(),
      window.location,
      new DefaultCrypto()
    );

    this.tokenHandler = new BaseTokenRequestHandler(new FetchRequestor());
    this.notifier = new AuthorizationNotifier();
    this.authorizationHandler.setAuthorizationNotifier(this.notifier);
    this.notifier.setAuthorizationListener((request, response, error) => {
      if (response) {
        this.request = request;
        this.response = response;
        this.code = response.code;

        let extras = null;
        if (this.request && this.request.internal) {
          extras = {};
          extras.code_verifier = this.request.internal.code_verifier;
        }

        const tokenRequest = new TokenRequest({
          client_id: this.pingEnvironment.client_id,
          redirect_uri: this.pingEnvironment.redirect_uri,
          grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
          code: this.code,
          refresh_token: undefined,
          extras
        });

        AuthorizationServiceConfiguration.fetchFromIssuer(this.pingEnvironment.issuer_uri, new FetchRequestor())
          .then((oResponse: any) => {
            this.configuration = oResponse;
            return this.tokenHandler.performTokenRequest(this.configuration, tokenRequest);
          })
          .then((oResponse) => {
            window.localStorage.setItem('token', oResponse.accessToken);
            let res = this.authorizationService.completeAuthorizationRequest(oResponse.accessToken)
            window.localStorage.setItem('userData', JSON.stringify(res));
            window.localStorage.setItem('external','true')
            this.setPrivilegeValue(res);
            // this.router.navigate(['dashboard']);
            // this.router.navigate(['lockerlist']);
          })
          .catch(oError => {
            this.error = oError;
          });
      }
    });
  }

  async setPrivilegeValue(response: any) {
    // forkJoin([this.userService.fetchMany(filterVal),this.userService.getUsergroup()]).subscribe((res:any[])=>{
    //   let userRole:any= res[0]
    //new approach promise.all
    let reqArray = [this.userService.me().toPromise(), this.userService.getUsergroup().toPromise()];
    let [userRole, currentGroups] : any = await Promise.all(reqArray);
    let userDetails = (userRole as any);
    if (userDetails && userDetails.partner) {
      // this.partnerStatus = userDetails.partner
      window.localStorage.setItem('partnerDetails', JSON.stringify(userDetails.partner))
      if (userDetails.partner?.type?.toUpperCase() === 'OWNER') {
        window.localStorage.setItem('redirectURL','partnertools')
      }
      else  {
        window.localStorage.setItem('redirectURL','parcels')

      }
    }

    let currentUserRoles = userDetails.groups.map(element => element.name)
      ///  show partner tool tab as it is visible for partnertype DELIVERY and hide for type OWNER
      for (let i = 0; i < currentUserRoles.length; i++) {
        let filteredVal = currentGroups.filter((element) => {
          if (element.userGroups != null) {
            if (element.userGroups.title == currentUserRoles[i]) {
              return element
            }
          }
  
        })
  
        if (filteredVal.length == 0) {
          continue
        }
  
        let checklist = filteredVal[0].userGroups.checklistData;
        if (userDetails.partner?.type?.toUpperCase() === 'OWNER') {
          checklist.filter(x => x['partnerTool'])[0]['partnerTool'].viewPartnerTool = true
  
        }
        if (userDetails.partner?.type?.toUpperCase() === 'DELIVERY') {
          checklist.filter(x => x['partnerTool'])[0]['partnerTool'].viewPartnerTool = false
  
        }
      }
          ///End 
    window.localStorage.setItem('currentRoles', JSON.stringify(currentUserRoles))
    window.localStorage.setItem('currentGroups', JSON.stringify(currentGroups))
    // this.setRoute(currentUserRoles,currentGroups)
    if (currentUserRoles.includes("partnertoolgroup")) {
      if (currentUserRoles.length == 1) {
        this.router.navigate(['partnertools']);
      }
      else {
        this.setRoute(currentUserRoles,currentGroups)
      }

    }
    else {
     this.setRoute(currentUserRoles,currentGroups)
    }
    //})


  }
  setRoute(currentUserRoles:any,currentGroups:any)
  {
    let groupHeading = {}
    for(let i=0;i<currentUserRoles.length;i++)
    {
      let filteredVal = currentGroups.filter((element) => {
        if (element.userGroups != null) {
          if (element.userGroups.title == currentUserRoles[i]) {
            return element
          }
        }

      })
      if(filteredVal.length==0)
      {
        continue
      }
      
      let checklist = filteredVal[0].userGroups.checklistData;
      let titleKeys = checklist.map((obj) => {
        for (let i in obj) {
          return i
        }
  
  
      })
      if(i==0)
      {
      for (let i = 0; i < titleKeys.length; i++) {
        groupHeading[titleKeys[i]] = false
      }
    }
    
      checklist.forEach((object, index) => {
        let trueCount = 0
        for (let key in object[titleKeys[index]]) {
          if (object[titleKeys[index]][key] == true) {
            trueCount = trueCount + 1
          }
  
        }
        if (trueCount != 0) {
          if(!groupHeading[titleKeys[index]])
          {
          groupHeading[titleKeys[index]] = true
          }
        }
  
      })
      
    }
    if (localStorage.getItem('redirectURL')) {
      let url = localStorage.getItem('redirectURL');
      url ? this.router.navigate([url]):this.router.navigate(['parcels']);
      return  
    }
    
    if(groupHeading['parcels'])
      {
        this.router.navigate(['parcels']);
        return
      }
      else if(groupHeading['deliveryPoints'])
      {
        this.router.navigate(['lockerlist']);
        return
      }
      else if(groupHeading['users'])
      {
        this.router.navigate(['user/search']);
        return
      }
      else if(groupHeading['partner'])
      {
        this.router.navigate(['partners']);
        return
      }
      else if(groupHeading['staff'])
      {
        this.router.navigate(['postman']);
        return
      }
      else if(groupHeading['report'])
      {
        this.router.navigate(['lockerdownreport']);
        return
      }
      else if(groupHeading['partnerTool'])
      {
        this.router.navigate(['partnertools']);
        return
      }
    
  }


  handleCode() {
    this.code = this.route.snapshot.queryParams.code;

    if (!this.code) {
      this.error = 'Unable to get authorization code';
      return;
    }
    this.authorizationHandler.completeAuthorizationRequestIfPossible();
  }
}
