import { Component, OnInit } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { Router } from '@angular/router';
import { PartnerService } from 'src/app/Service/partner.service';
import { map } from 'rxjs/operators';
import { SpinnerService } from 'src/app/Service/spinner.service';

@Component({
  selector: 'app-partner-history',
  templateUrl: './partner-history.component.html',
  styleUrls: ['./partner-history.component.scss']
})
export class PartnerHistoryComponent implements OnInit {

  uploader: FileUploader = new FileUploader({ url: 'upload-url' });
  orderHistoryResponse: any
  orderFound: boolean = true;
  constructor(
    private partnerservice: PartnerService,
    private router: Router,
    private spinner:SpinnerService
  ) { }

  ngOnInit(): void {
    this.getPartnerOrderHistory();
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.uploader.addToQueue([file]);
    }
  }

  uploadFile(): void {
    this.uploader.uploadAll();
    this.uploader.onCompleteAll().subscribe(() => {
      console.log('File uploaded successfully');
    })
  }

  getPartnerOrderHistory() {
    this.spinner.show();
    this.partnerservice.getPartnerOrderHistory().pipe(map((orders: Array<any>) => {
      return orders.map((order: any) => {
        
        let retObj = { ...order }
        retObj.pickedAt = order.pickedAt ? order.pickedAt.split('Z').join('') : '';
        return retObj;
      })
    })).subscribe(res => {
      if (res.length == 0) {
        this.orderFound = false
      }
      else {
        this.orderFound = true
      }
      this.orderHistoryResponse = res;
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      this.orderFound = false
    })
  }

  viewPage(obj) {
    this.router.navigate(["/partnertools/partnerOrder/history", +obj.id])
  }
}
