import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router'
import { UserService } from '../../../Service/user.service';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {Location} from '@angular/common';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { LangtransService } from 'src/app/Service/langtrans.service';
import { AppService } from 'src/app/Service/app.service';
import { SpinnerService } from 'src/app/Service/spinner.service';
declare let $: any;
@Component({
  selector: 'app-user-details-update',
  templateUrl: './user-details-update.component.html',
  styleUrls: ['./user-details-update.component.scss']
})
export class UserDetailsUpdateComponent implements OnInit {
  userId:any
  userdetails:any
  deleteId:any
  groups:any
  id:any
  submitted: boolean
  parcelLockerNamedropdownList = [];
  userGroupdropdownList = [];
  userGroupselectedItems = [];
  orderTypedropdownSettings: IDropdownSettings;
  userGroupdropdownSettings: IDropdownSettings;
  public userDetailsForm
  langKey: any;
  mobnumPattern = "^[- +()]*[0-9][- +()0-9]*$";
  constructor(
    private _location: Location,
    public activatedRoute: ActivatedRoute,
    private userService: UserService,
    public router: Router,
    private FB: FormBuilder,
    private toastr: ToastrService,
    private langtransService: LangtransService,
    private appService: AppService,
    private spinner: SpinnerService
  ) { }

  ngOnInit(): void {
    this.appService.languagetoggle$.subscribe((data: any) => {
      this.langKey = data
    })
    this.fetchUserGroups();
    this.userDetailsForm = new FormGroup({
      id: new FormControl('', []),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      Email: new FormControl('', [Validators.required]),
      Groups: new FormControl('', [Validators.required]),
      partner: new FormControl('', [Validators.required]),
     // mobileNumber: new FormControl('', [])
      mobileNumber: new FormControl('', Validators.pattern(this.mobnumPattern)),
      active:new FormControl('',[])
    })

    this.userId = this.activatedRoute.snapshot.paramMap.get('id')
    this.fetchUserDetails(this.userId)

    this.userGroupdropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };

  }
  get F() {
    return this.userDetailsForm.controls
  }  updateUserDetails() {
    let body = {}
    let groups = []
    body["email"] = this.userDetailsForm.value.Email
    if (this.userDetailsForm.value.Groups.length != 0) {
    for (let i = 0; i < this.userDetailsForm.value.Groups.length; i++) {
      groups.push(this.userDetailsForm.value.Groups[i]?.id);
      }}
    body["groups"] = groups
    body["firstName"] = this.userDetailsForm.value.firstName
    body["lastName"] = this.userDetailsForm.value.lastName
    let mobileNum = this.userDetailsForm.value.mobileNumber;
    const isWhitespaceString = str => !/\S/.test(str)
    body["mobilenumber"] = mobileNum ? mobileNum : '';
    body["active"]=this.userDetailsForm.value.active
    
    this.userService.updateUser(body, this.userDetailsForm.value.id).subscribe(
      res => {
        // this.toastr.success('User Added Successfully', 'Success');
        this.translator(this.langKey,'User updated successfully',true)
        this.router.navigate(['/user/search']);
      },
      err => {
        console.error("THE ERROR IS", err)
        // this.toastr.error('Editing User Failed !!', 'Failed');
        this.translator(this.langKey,'Editing User Failed',false)
      })

  }

  onSave() {
   this.updateUserDetails()
  }
  fetchUserGroups() {
    this.spinner.show();
    this.userService.fetchGroups().subscribe(
      res => {
        this.spinner.hide();
        this.groups = res;
        this.userGroupdropdownList = this.groups
        // console.log('this.userGroupdropdownList', this.userGroupdropdownList);
      }
    )
  }
  onItemSelect(item: any) {
  }
  onSelectAll(items: any) {
  }
  fetchUserDetails(userId) {
    this.spinner.show();
    this.userService.fetchById(userId).subscribe(
      res => {
        
        this.spinner.hide();
        this.userdetails = res
        
        this.userDetailsForm.patchValue({
          "id": this.userdetails.id,
          "firstName": this.userdetails.firstName,
          "lastName": this.userdetails.lastName,
          "Email": this.userdetails.email,
          "Groups":  this.userdetails.groups ,
          "partner": this.userdetails.partner.name,
          "mobileNumber": this.userdetails.mobilenumber,
          "active":this.userdetails.active
        })
        this.userGroupselectedItems = this.userdetails.groups
        // console.log('this.userGroupselectedItems', this.userGroupselectedItems);
      },
      err => {
        this.spinner.hide();
        console.error(err);
      }
    )
  }
  translator(lang, key, status) {
    this.langtransService.TranslateData(lang, key).subscribe((res) => {
      if (status) {
        this.toastr.success(res)
      } else {
        this.toastr.error(res)
      }
    })
  }
  cancel = () => {

    this.router.navigate(['/user/search'])
  }

  formFieldsValidate() {
    
    if (this.userDetailsForm.valid) {

      
          $('#editUserModal').modal('show');
   
     
    }else{
      this.submitted=true
    }
  }
  restrictAlphabets(e) {
    var x = e.which || e.keycode;
    if ((x >= 48 && x <= 57) || x == 43 || x == 40 || x == 41 || x == 45 || x == 32 || x == 8 || x == 9 || x == 27 || x == 127)
      return true;
    else
      return false;
  }
}



