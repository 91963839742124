<app-header></app-header>
<br>
<br>
<div class="col-12 text-secondary" [appViewController]="{partner:'addPartner'}" >
    <div class=" mat-card col-md-12 col-sm-12 col-xs-12 mx-auto">
        <h2 class="card-title text-center">{{'Add Partner'|translate}}</h2>
        <form [formGroup]="partnerForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <div class="row">
                    <label class="required col-sm-3 ml-4" for="partnerName">{{'Name'|translate}}</label>
                    <input type="text" class="form-control col-sm-8" formControlName="partnerName" id="partner name"
                        [placeholder]="'partner name' | translate">
                </div>

                <small class="row">
                    <small class="col-sm-3 ml-4"></small>
                    <small *ngIf="submitted &&  formValidators.partnerName.errors?.required"
                        class="form-text text-muted col-sm-8">{{'Please enter partner name' | translate}}
                    </small>
                </small>
            </div>

            <div class="form-group">
                <div class="row">
                    <label class="required col-sm-3 ml-4" for="country">{{'Country'|translate}}</label>
                    <select class="form-control col-sm-8" formControlName="country" name="country">
                        <option selected value="">{{'Choose Option...'|translate}}</option>
                        <option value="belgium">Belgium</option>
                    </select>

                </div>
                <small class="row">
                    <small class="col-sm-3 ml-4"></small>
                    <small *ngIf="submitted &&  formValidators.country.errors?.required"
                        class="form-text text-muted col-sm-8">{{'Please select country' | translate}}
                    </small>
                </small>

            </div>
            <div class="form-group">
                <div class="row">
                    <label class="required col-sm-3 ml-4" for="active">{{'Active'|translate}}</label>
                    <select class="form-control col-sm-8" formControlName="active" name="active">
                        <option selected value="">{{'Choose Option...'|translate}}</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                </div>
                <small class="row">
                    <small class="col-sm-3 ml-4"></small>
                    <small *ngIf="submitted &&  formValidators.active.errors?.required"
                        class="form-text text-muted col-sm-8">{{'Please select active option' | translate}}
                    </small>
                </small>

            </div>
            <div class="form-group">
                <div class="row">
                    <label class="required col-sm-3 ml-4" for="active">Partner type</label>
                    <select class="form-control col-sm-8" formControlName="courier" name="courier">
                        <option selected value="">{{'Choose Option...'|translate}}</option>
                        <option value="DELIVERY">DELIVERY</option>
                        <option value="OWNER">OWNER</option>
                    </select>
                </div>
                <small class="row">
                    <small class="col-sm-3 ml-4"></small>
                    <small *ngIf="submitted &&  formValidators.active.errors?.required"
                        class="form-text text-muted col-sm-8">{{'Please select active option' | translate}}
                    </small>
                </small>

            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-sm-3 ml-4" for="prsId">{{'PRS ID'|translate}}</label>
                    <input type="text" class="form-control col-sm-8" formControlName="prsId" id="prsId"
                        [placeholder]="'PRS ID' | translate">
                </div>

                <small class="row">
                    <small class="col-sm-3 ml-4"></small>
                    <small *ngIf="submitted &&  formValidators.prsId.errors?.pattern"
                        class="form-text text-muted col-sm-8">{{'Please enter numbers only' | translate}}
                    </small>
                </small>
            </div>
            <div class="text-center pb-4">
                <button class="btn btn-outline-danger" type="Submit">{{'Submit'|translate}}</button>
            </div>
        </form>

    </div>
</div>
