<app-header></app-header>
<!-- <img src="/assets/img/bg2.jpg" class="bg2"> -->
<div #parcelLastmile [appViewController]="{parcels:'reserveLastmile'}"></div>
<div #parcelFirstmile [appViewController]="{parcels:'reserveFirstmile'}"></div>
<div *ngIf="test">
  <br>
  <form [formGroup]="filterForm" class="pl-4 pr-4">
    <div class="box">
      <div class="container">
        <div class="row" *ngIf="isFirstMile">
          <div class="col-4 offset-3">
            <h3 class="font">{{'First Mile Reservation'|translate}}</h3>
          </div>
        </div>
        <div class="row" *ngIf="!isFirstMile">
          <div class="col">
            <h3 class="font" style="text-align: center;">{{'Last Mile Reservation'|translate}}</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="mat-card shadow p-3 mb-5 bg-white rounded" style="margin: auto; padding-left: 25%;">
              <div class="card-body p-3">
                <div class="form-row">
                  <div class="form-group col">
                    <label for="Barcode" style="margin-left: 20%;">{{'Barcode'|translate}}</label>
                    <input type="text" class="form-control" style="width:80%; margin-left: 20%;"
                      formControlName="Barcode" id="Barcode" name="Barcode" placeholder="{{'Barcode'|translate}}"
                      [ngClass]="formControl.Barcode.errors?.required  &&  formControl.Barcode.touched ? 'error':'' ">
                    <span class="help-block">
                      <span *ngIf="submitted && filterForm.controls.Barcode.errors">
                        <div *ngIf="filterForm.controls.Barcode.errors.required" class="error-text text-danger mt-1">
                          <label for="Barcode is required" style="margin-left: 20%;">{{'Barcode is required'|translate}}</label></div>
                      </span>
                    </span>
                  </div>
                  <button class="btn btn-outline-danger" (click)="searchBarcode()"
                    style="margin-top: 28px; height: 37px;margin-right: 15%;">{{'Search'|translate}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<!--  -->
<div class="box" *ngIf="barcodeResult && barcodeResult.length">
  <div class="container">
    <div class="row">
      <div class="col-12">

        <div class="border border-success mat-card shadow p-3 mb-5 bg-white rounded">
          <div class="p-3">
            <div class=" border shadow p-3 mb-5 bg-white rounded">
              <div class=" p-3">
                <h6 class="card-title col text-center font-weight-bold" style="font-size: 15px;">{{'Barcode Details'|translate}}
                </h6>
                <div class="table-responsive">
                  <table class="table" style="text-align:center;">
                    <caption></caption>
                    <thead>
                      <tr>
                        <th class="spacing">{{'AC Code'|translate}}</th>
                        <th class="spacing">{{'Customer Name'|translate}}</th>
                        <th class="spacing">{{'Customer E-mail'|translate}}</th>
                        <th class="spacing">{{'Locker Name'|translate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="spacing">{{barcodeResult[0].deliveryPoint.acCode}}</td>
                        <td class="spacing">{{barcodeResult[0].customerFirstName}}</td>
                        <td class="spacing">{{barcodeResult[0].customerEmail}}</td>
                        <td class="spacing">{{barcodeResult[0].deliveryPoint.name}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="box" *ngIf="barcodeResult && !barcodeResult.length">
  <span>{{barcodeResult}}</span>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class=" border mat-card shadow p-3 mb-5 bg-white rounded" style="margin: auto; padding-left: 25%;">
          <div class="card-body p-3">
            <div>
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div>
                  <div class="col text-right">
                    <h6 class="card-title col text-center" style="font-size: 15px;"> {{'Details not found'|translate}}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--  -->
<div *ngIf="barcodeResult && barcodeResult.length">
  <br>
  <form [formGroup]="filterForm" class="pl-4 pr-4">
    <div class="box">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="mat-card shadow p-3 mb-5 bg-white rounded" style="margin: auto; padding-left: 25%;">
              <div class="card-body p-3">
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="Barcode">{{'Barcode'|translate}}</label>
                    <input type="text" class="form-control" formControlName="Barcode" name="Barcode"
                      placeholder="Barcode" value={{barcodeResult[0].barcode}} readonly>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="DeliveryDate">{{'Delivery Date'|translate}}</label>
                    <input class="form-control" [value]="displayDate" type="text" name="DeliveryDate"
                      placeholder="DeliveryDate" [readonly]="true">
                  </div>
                  <div class="form-group col-md-2">
                    <button class="btn btn-outline-danger" (click)="onSubmit()" style="margin-top: 30px;"
                      type="submit">{{'Submit'|translate}}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<!--  -->
<div class="box" *ngIf="barcodeResult && barcodeResult.length && result">
  <div class="container">
    <div class="row">

      <div class="col-12">
        <div class=" border border-success mat-card shadow p-3 mb-5 bg-white rounded"
          style="margin: auto; padding-left: 25%;">
          <div class="card-body p-3">
            <div>
              <!-- <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"> -->
                <div class=" border shadow p-3 mb-5 bg-white rounded" style="width: 100%; margin: 10px;">
                  <div class="card-body p-3">
                    <div>
                      <div class="col text-right">
                        <h6 class=" card-title col text-center font-weight-bold" style="font-size: 15px;">
                          Response
                        </h6>
                      </div>
                    </div>

                    <!-- SUCCESS CASE -->
                    <div class="col-12" *ngIf="result && result.reservationStatus == 'SUCCESS'">
                      <div class="table-responsive">
                        <table class="table" style="text-align:center;">
                          <caption></caption>
                        <tr>
                          <th style="white-space: nowrap;">Barcode</th>
                          <th style="white-space: nowrap;">AC Code</th>
                          <th style="white-space: nowrap;">Status</th>
                          <th style="white-space: nowrap;">Delivery Date</th>
                          <th style="white-space: nowrap;">Message</th>
                        </tr>
                        <tbody>
                          <tr>
                            <td>{{result.itemBarcode}}</td>
                            <td>{{result.acCode}}</td>
                            <td>{{result.reservationStatus}}</td>
                            <td *ngIf="result.deliveryDate">{{result.deliveryDate|date:'dd-MM-yyyy'}}</td>
                            <td *ngIf="result.reservation?.reservationattemptedat">
                              {{result.reservation?.reservationattemptedat}}</td>
                            <td>Successfully Reserved!</td>
                          </tr>
                        </tbody>
                      </table>
</div>
                      <!-- <div class="col-12 text-secondary text-center">
                        <hr>
                        <code>{{result.acCode}}</code>
                        <hr>
                      </div> -->
                    </div>
                  </div>

                  <!-- IN CASE OF BACKUP POINT CASE -->
                  <div class="col-12" *ngIf="result && result.reservationStatus == 'FAILED'">
                    <div class="table-responsive">
                      <table class="table" style="text-align:center;">
                        <caption></caption>
                      <tr>
                        <th class="spacing" style="white-space: nowrap;">Barcode</th>
                        <th class="spacing" style="white-space: nowrap;">AC Code</th>
                        <th class="spacing" style="white-space: nowrap;">Status</th>
                        <th class="spacing" style="white-space: nowrap;">Delivery Date</th>
                        <th class="spacing" style="white-space: nowrap;">Message</th>
                      </tr>
                      <tbody>
                        <tr>
                          <td class="spacing">{{result.itemBarcode}}</td>
                          <td class="spacing">{{result.acCode}}</td>
                          <td class="spacing">{{result.error.code}}</td>
                          <td class="spacing">{{result.deliveryDate|date:'dd-MM-yyyy'}}</td>
                          <td class="spacing">{{result.error.message}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  </div>
                  <!-- IN CASE OF SOMETHING BAD HAPPENED -->
                  <div class="col-12" *ngIf="result && !result.reservationStatus">
                    <div class="table-responsive">
                      <table class="table" style="text-align:center;">
                        <caption></caption>
                      <tr>
                        <th class="spacing">Barcode</th>
                        <th class="spacing">AC Code</th>
                        <th class="spacing">Status</th>
                        <th class="spacing">Delivery Date</th>
                        <th class="spacing">Message</th>
                      </tr>
                      <tbody>
                        <tr>
                          <td class="spacing">{{result.itemBarcode}}</td>
                          <td class="spacing">{{result.acCode}}</td>
                          <td class="spacing">{{result.error.code}}</td>
                          <td class="spacing">{{displayDate}}</td>
                          <td class="spacing">Error Occured! Failed Reservation</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                </div>
              </div>
            </div>
          <!-- </div> -->

          <!-- <div class="row" *ngIf="plainResult" >
          <div class="col-12">
              <pre>
                  {{plainResult}}
              </pre>
          </div>
      </div> -->
        </div>
      </div>
    </div>
  </div>
</div>