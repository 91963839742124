<app-header></app-header>
<div #myDiv [appViewController]="{users:'showGroup'}">
  <div class="">
    <div class="row" style="justify-content: end;">
      <button class="btn btn-outline-danger mt-4 mb-4" style="margin-right: 30px;" (click)="goTogroupadd()"
        [appViewController]="{users:'createGroup'}">{{'Add rights group'|translate}}</button>
    </div>
    <div class="container-fluid">
      <div>
        <div>
          <div class="table-responsive">
            <table class="table table-sm table-bordered table-hover row-border hover">
              <caption></caption>
              <thead class="thead-dark">
                <tr style="text-align: center;">
                  <th scope="col">{{'Group Name'|translate}}</th>
                  <th scope="col">{{'Functions'|translate}}</th>
                  <th scope="col">{{'Actions'|translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of userGrop">
                  <td style="vertical-align: middle;text-align: center;">
                    {{item.title}}
                  </td>
                  <td style="display: flex; flex-direction: row; white-space: nowrap;">
                <tr *ngFor="let element of item.checklistData; let i = index" style="width: 100%;border: none;">
                  <div
                    style="font-weight: 800;text-align: center;background-color: lightgrey;border: 1px solid #dee2e6;">
                    {{titleKeys[i] | titlecase}}</div>
                  <div *ngFor="let key of getObjectKeys(element,i)">
                    <td style="display: block;">
                      <span class="text-adjustment" matTooltip="{{key}}">{{key | titlecase}} </span> <img
                        src="../../../../assets/img/greencheck.png" alt="" *ngIf="element[titleKeys[i]][key]" width="20"
                        height="20" style="float: right;">
                      <img src="../../../../assets/img/cancel.png" alt="" *ngIf="!element[titleKeys[i]][key]" width="20"
                        height="20" style="float: right;">
                    </td>
                  </div>
                </tr>
                </td>
                <td style="vertical-align: middle;">
                  <div class="text-center mt-2">
                    <button type="button" class="btn btn-success edit1" style="width: 100px; background-color: #17a2b8;"
                      *ngIf="item.title != 'Administrators'" (click)="goTogroupedit(item.title)"
                      [appViewController]="{users:'modifyGroup'}">{{'EDIT'| translate}}</button>
                    <button type="button" class="btn btn-danger ml-2 delete1" style="width: 120px;"
                      *ngIf="item.title != 'Administrators'" (click)="savedelete(item.title)" data-toggle="modal"
                      data-target="#deleteUserModal" type="button" data-toggle="modal" data-target="#deleteUserModal"
                      [appViewController]="{users:'deleteGroup'}">{{'DELETE'| translate}}</button>
                  </div>
                </td>
                </tr>
              </tbody>
            </table>
            <br>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" tabindex="-1" id="deleteUserModal" role="dialog" aria-labelledby="deleteUserModal"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">{{'Delete'|translate}}</h3>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>{{'Are you sure you want to delete the user group'|translate}}?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'Cancel'|translate}}</button>
          <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="deleteUser()">{{'Confirm'|translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-un-authorizedpage *ngIf="showUnauthorized"></app-un-authorizedpage>
