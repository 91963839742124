import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppService } from '../../Service/app.service';
import { ReservationserviceService } from '../../Service/reservationservice.service';
import { ParcelService } from '../../Service/parcel.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common'
import * as moment from 'moment'
import { SpinnerService } from 'src/app/Service/spinner.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.scss']
})
export class ReservationComponent implements OnInit {

  Barcode: any
  DeliveryDate: any
  result: any
  plainResult: any
  attempted: boolean
  hasError: boolean
  error: any
  barcodeResult: any
  submitted: boolean
  isFirstMile = false;
  displayDate: any;
  public filterForm
  response: any;
  body: any;
  test: boolean = true;
  @ViewChild('parcelLastmile', { static: false }) parcelLastmile: ElementRef;
  @ViewChild('parcelFirstmile', { static: false }) parcelFirstmile: ElementRef;
  constructor(
    private reservationservice: ReservationserviceService,
    private ParcelService: ParcelService,
    private appService: AppService,
    public router: Router,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    private activatedRoute: ActivatedRoute,
    private spinner: SpinnerService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.filterForm = new FormGroup({
      Barcode: new FormControl('', [Validators.required]),
      DeliveryDate: new FormControl('', [])
    })

    //const currentDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    const currentDate = new Date();
    let formattedDate = moment().utc().format("YYYY-MM-DD HH:mm:ss")
    this.displayDate = this.datepipe.transform(currentDate, 'dd-MM-yyyy');
    this.filterForm.patchValue({
      DeliveryDate: formattedDate
    });
    
    const routeParams = this.activatedRoute.snapshot.paramMap;
    this.isFirstMile = false;
    const action = routeParams.get('action');
    if (action && action == 'firstmile') {
      this.isFirstMile = true;
    }
  }
  get formControl() {
    return this.filterForm.controls
  }
  performReservation() {
    this.hasError = false;
    this.error = undefined;
    this.attempted = false;
    this.spinner.show();
    this.reservationservice.performReservation(this.validate()).subscribe(
      res => {
        this.spinner.hide();
        this.attempted = true
        this.plainResult = JSON.stringify(res)
        this.result = res
      },
      err => {
        this.spinner.hide();
        this.hasError = true
        this.error = 'Something went wrong.'
      }
    )
  }

  ngAfterViewInit() {
    let parcellatlist = this.parcelLastmile.nativeElement;
    let parcellastliststyle = window.getComputedStyle(parcellatlist)
    let parcellistlastdisplay = parcellastliststyle?.display;

    let parcelfirslatlist = this.parcelFirstmile.nativeElement;
    let parcelfirstliststyle = window.getComputedStyle(parcelfirslatlist)
    let parcellistfirstdisplay = parcelfirstliststyle?.display;

    const routeParams = this.activatedRoute.snapshot.paramMap;
    let firstmile: any;
    const action = routeParams.get('action');
    if (action && action == 'firstmile') {
      firstmile = true;
    }
    else {
      firstmile = false
    }
    if (firstmile) {
      if (parcellistfirstdisplay == 'none') {
        this.test = false
      }
    }
    else {
      if (parcellistlastdisplay == 'none') {
        this.test = false
      }
    }
  }
  performFirstMileReservation = () => {
    //let acCode = '36995'
    let acCode = this.barcodeResult[0].deliveryPoint.acCode
    let provider = this.barcodeResult[0].deliveryPoint.provider;
    if (provider && provider == 'Lean') {
      this.toastr.info(this.translate.instant("Action only suppported for classic lockers"));
      return;
    }
    this.hasError = false
    this.error = undefined
    this.attempted = false
    let reqObj = this.constructFileMileREquest();
    if (this.barcodeResult && this.barcodeResult.length && this.barcodeResult[0].deliveryPoint) {
      acCode = this.barcodeResult[0].deliveryPoint.acCode;
    }
    this.spinner.show();
    this.reservationservice.performFirstMileReservation(reqObj, acCode).subscribe(
      res => {
        this.attempted = true
        this.plainResult = JSON.stringify(res)
        this.result = res
        if (this.result.reservation.reservationattemptedat != null) {
          let FMDate = this.result.reservation.reservationattemptedat
          let firstMileReserveDate = FMDate.split("T")
          this.result.reservation.reservationattemptedat = moment(firstMileReserveDate[0], "YYYY-M-D").format("DD-MM-YYYY")
        }
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
        this.hasError = true
        this.error = 'Something went wrong.'
        this.result = {
          itemBarcode:reqObj.barcode,
          acCode:acCode,
          error:{code:500}
        }
        //this.fmFailCall(this.barcodeResult[0].id)
      }
    )
  }

  validate() {
    let res = { Barcode: this.filterForm.value.Barcode }
    if (this.filterForm.value.DeliveryDate) Object.assign(res, { DeliveryDate: this.filterForm.value.DeliveryDate })
    //new change to set userid in the reservation request
    let userDataString = localStorage.getItem('userData');
    if (userDataString) {
      let jsonuserData = JSON.parse(userDataString);
      res['userId'] = jsonuserData.uid;
    }
    return res
  }

  onSubmit() {
    if (this.isFirstMile) {
      this.performFirstMileReservation();
    } else {
      this.performReservation()
    }

  }
  searchBarcode() {
    if (this.filterForm.valid) {
      this.spinner.show();
      let request = { itemBarcode: this.filterForm.value.Barcode }
      this.reservationservice.checkBarcode(request).then(
        res => {
          this.barcodeResult = res;
          this.result = null;
          this.spinner.hide();
        },
        err => {
          console.error("Error while calling API", err);
          this.spinner.hide();
        }
      )
    } else {
      this.submitted = true;
    }
  }

  constructFileMileREquest = () => {
    let userDataString = localStorage.getItem('userData');
    let uid = "";
    if (userDataString) {
      let jsonuserData = JSON.parse(userDataString);
      uid = jsonuserData.uid;
    }
    let reqObj = {
      "purpose": "PERFORM_RESERVATION",
      "flow": "FIRSTMILE",
      "barcode": this.filterForm.value.Barcode,
      "source": "iot-locker",
      "publish": true,
      "userId": uid,
      "size": {
        "width": 100,
        "height": 100,
        "depth": 100
      }
    }
    return reqObj;
  }

  fmFailCall(reservationId) {
    this.body = {
      text: 'FM-Reservation-Failed'
    }
    this.ParcelService.insertCommentsToDB(reservationId, this.body)
      .subscribe((res) => {
        this.response = res;
      })
  }
}
