import { Injectable } from '@angular/core';
import { lrsEnvironment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReservationserviceService {

  constructor(
    private http: HttpClient
  ) {  }

  performReservation(req: any) {
    return this.http.post(
      `${lrsEnvironment.baseUrl}/test-reservation`, req)
  }
  performFirstMileReservation (req:any,lockerAcCode:any){
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'source_device': lockerAcCode });
    let options = { headers: headers };
    return this.http.post(
      `${lrsEnvironment.baseUrl}/reserve-firstmile`,req,options)
    
  }
  checkBarcode(req: any) {
    return this.http.post(
      `${lrsEnvironment.baseUrl}/parcels?limit=500&offset=0&output=json`, req).toPromise()
  }
}
