import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-lockers-menu',
  templateUrl: './lockers-menu.component.html',
  styleUrls: ['./lockers-menu.component.scss']
})
export class LockersMenuComponent implements OnInit {
  @Input() id: any;

  constructor(public router: Router) { }

  ngOnInit(): void {
  }

  goToDetails() {
    this.router.navigate(['/deliverypoints/', this.id, 'details']);
  }

  goToLogs() {
    this.router.navigate(['/deliverypoints/', this.id, 'logs']);
  }

  goToTerminals() {
    this.router.navigate(['/deliverypoints/', this.id]);
  }

  filterConnectionLogs() {
    this.router.navigate(['/deliverypoints/', this.id, 'connectivity']);
  }

  goToSettings() {
    this.router.navigate(['/deliverypoints/', this.id, 'settings']);
  }
}

