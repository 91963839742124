<div class="mx-5 mt-5" [appViewController]="{partnerTool:'viewPartnerTool'}">
    <h2 style="color: red;"> {{'Overview'|translate}}</h2>
    <div *ngIf="lockerNotFree" class="alert alert-danger" role="alert"
        style="width: 60%; margin-left: auto; margin-right: auto;">
        {{'Capacity is full'|translate}}
    </div>
    <form [formGroup]="partenOrderForm">
        <div class="row">
            <div class="col-lg-6 col-12">
                <div class="table-responsive">
                    <div class="mt-8 ml-4 mr-5">
                        <table class="table table-bordered" style="width: 50;">
                            <caption></caption>
                            <thead>
                                <tr>
                                    <th scope="" colspan="2">{{'Facts'|translate}}</th>
                                </tr>
                                <tr>
                                    <th scope="">{{'Status'|translate}}</th>
                                    <td>
                                        <div class="container">
                                            <div class="row">
                                                <div *ngIf="!reservationCheck"
                                                    style="width: 1.5rem; height:1.5rem; background-color: green;"
                                                    class="mr-2"></div>
                                                <div *ngIf="reservationCheck"
                                                    style="width: 1.5rem; height:1.5rem; background-color: red;"
                                                    class="mr-2"></div>
                                                <div *ngIf="editForm && id.action == 'process'">{{'Safe Reserved'|translate}}</div>
                                                <div *ngIf="editForm && id.action == 'vault'">
                                                    {{'Parcel delivered to the safe'|translate}}</div>
                                                <div *ngIf="editForm && id.action == 'history'">
                                                    {{'Package collected from the safe'|translate}}</div>
                                                <div *ngIf="!editForm">{{'Empty order'|translate}}</div>

                                                <!-- <div><input type="checkbox" class="btn-sucess" *ngIf="this.reservationCheck?'green-class':'red-class'"[disabled]='true'> RESERVED</div> -->
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="">{{'Date'|translate}}</th>
                                    <td>{{overviewDate}}</td>
                                </tr>
                                <tr>
                                    <th scope="">{{'whenToCollect'|translate}}</th>
                                    <td>{{whentocollectvalue}}</td>
                                </tr>
                                <tr>
                                    <th scope="">{{'customerData'|translate}}</th>
                                    <td>{{customerData}}
                                    </td>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
                <div class="ml-4 mr-5">
                    <table class="table table-bordered">
                        <caption></caption>
                        <thead>
                            <tr>
                                <td *ngIf="!editForm"><button class="btn btn-danger"
                                        (click)="generatePdf()">{{'printBarcodes'|translate}}</button></td>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>

            <div class="col-lg-6 col-12">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <caption></caption>
                        <thead>
                            <tr>
                                <th scope="" style="width: 25%;" colspan="3">{{'placeAnOrder'|translate}}</th>
                            </tr>
                            <tr>
                                <th scope="" colspan="2">{{'numberOfPackages'|translate}}</th>
                                <td>
                                    <input type="text" class="form-control phone_screen_width" id="noPackage"
                                        formControlName="noPackage" [readonly]="true">
                                </td>
                            </tr>
                            <!-- <tr>
                <th colspan="2">Amount</th>

                <td>
                    <div class="row">
                        <div class="col-sm-4">
                            <input type="text" class="form-control" formControlName="amount"
                                id="amount">
                        </div>

                        <div class="col-sm-4">
                            <span>euros</span>
                        </div>
                        <div class="col-sm-4"></div>
                    </div>
                </td>
            </tr> -->
                            <tr>
                                <th colspan="2" scope="">{{'Reference'|translate}}</th>
                                <td>
                                    <input type="text" style="width: 45%;" class="form-control"
                                        formControlName="reference" id="reference" [readOnly]="editForm">
                                </td>
                            </tr>
                            <tr>
                                <th colspan="2" scope="">{{'Location'|translate}}</th>
                                <td *ngIf="!editForm">
                                    <select class="form-control" formControlName="location" name="Location"
                                        id="location" (change)="setLocationName($event.target.value)">
                                        <!-- {{item.deliveryPointId}} -->
                                        <option *ngFor="let item of locationData" [value]="item.id">{{item.name}}
                                        </option>
                                    </select>
                                    <small *ngIf="submitted &&  formValidators.location.errors?.required"
                                    class="text-danger d-flex" role="alert">{{'location is a required field'|translate}}</small>
                                </td>
                                <td *ngIf="editForm">
                                    <select class="form-control" formControlName="location" name="Location"
                                        id="location" (change)="setLocationName($event.target.value)" disabled>
                                        <option *ngFor="let item of locationData" [value]="item.id">{{item.name}}
                                        </option>
                                    </select>
                                    <small *ngIf="submitted &&  formValidators.location.errors?.required"
                                    class="text-danger d-flex" role="alert">{{'location is a required field'|translate}}</small>
                                </td>
                            </tr>
                            <!-- <tr>
                <th colspan="2">Queue</th>
                <td>
                    <select class="form-control" formControlName="queue" name="queue" id="queue">
                        <option value="">All</option>
                        <option value=""></option>
                        <option value="s"></option>
                    </select>
                </td>
            </tr> -->
                            <tr>
                                <th colspan="2" scope="">{{'reservePeriod'|translate}}</th>
                                <td>
                                    <div class="container">
                                        <div class="row">


                                            <input type="text" formControlName="reservePeriodFrom"
                                                class="form-control px-1 dateWidth" id="reservePeriod"
                                                #reserveDateFromInput bsDatepicker
                                                [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY hh:mm',withTimepicker: true}"
                                                disabled>
                                            <span class="input-group-text">
                                                <i class="fas fa-calendar-alt"></i>
                                            </span>
                                            <!-- <select class="form-control px-1" formControlName="reservePeriodFromTime"
                                name="reservePeriod" style="width: 20%;" id="reservePeriod">
                                <option value="2:00">2:00 PM</option>
                                <option value=""></option>
                                <option value="s"></option>
                            </select> -->
                                            <!-- <div class="col-sm-1"></div> -->
                                            <span class="mt-2 mx-1">-</span>
                                            <!-- <div class="col-sm-1"></div> -->
                                            <input formControlName="reservePeriodTo" type="text"
                                                class="form-control px-1 dateWidth" id="reservePeriod"
                                                #reserveDateToInput bsDatepicker [minDate]='todayDate' [maxDate]='maxDate' (bsValueChange)='todateChange($event)'
                                                [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY hh:mm',withTimepicker: true}">
                                            <span class="input-group-text"
                                                (click)="openDatePicker('reserveDateToInput')">
                                                <i class="fas fa-calendar-alt"></i>
                                            </span>
                                            <!-- <select class="form-control px-1" formControlName="reservePeriodToTime"
                                style="width: 20%;" name="reservePeriod" id="reservePeriod">
                                <option value="2:00">2:00 PM</option>
                                <option value=""></option>
                                <option value="s"></option>
                            </select> -->
                                        </div>

                                    </div>
                                    <small
                                        *ngIf="submitted &&  (formValidators.reservePeriodTo.errors?.required||formValidators.reservePeriodFrom.errors?.required)"
                                        class="text-danger d-flex" role="alert">{{'Please fill both the dates'|translate}}</small>
                                </td>
                            </tr>
                            <tr>
                                <th colspan="2" scope="">{{'Barcode'|translate}}</th>
                                <td>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <input type="text" class="form-control" formControlName="barcode" 
                                                id="barcode" >
                                        </div>
                                        <div class="col-sm-6">
                                            <span>{{'leaveBlankToGenerate'|translate}}</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th colspan="2" scope="">{{'pickUpCode'|translate}}</th>
                                <td>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <input type="text" class="form-control" formControlName="customerBarcode"
                                                id="customerBarcode" maxlength="6" [readOnly]="disablePickupCode ? true :''">
                                            <small *ngIf="submitted &&  formValidators.customerBarcode.errors?.pattern"
                                            class="text-danger d-flex" role="alert">{{'Enter the pickup code in correct
                                                format'|translate}}</small>
                                        </div>

                                        <div class="col-sm-6">
                                            <span>{{'leaveBlankToGenerate'|translate}}</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th colspan="2" scope="">{{'Package'|translate}}</th>
                                <td>
                                    <div>
                                        <div class="row" style="align-items: center;">
                                            <div class="col-12 col-md-3">{{'Dimensions'|translate}}(LxWxH)</div>
                                            <div class="col-12 col-md-9">
                                                <input class="ml-md-5  form-control phone_screen_width"
                                                    style="display: inline;" id="length" formControlName="length"
                                                    [readonly]="editBarcode" (input)="onInputChange($event)">
                                                <span>x</span>
                                                <input class=" form-control phone_screen_width" style="display: inline;"
                                                    id="width" formControlName="width" [readonly]="editBarcode"
                                                    (input)="onInputChange($event)">
                                                <span>x</span>
                                                <input class=" form-control phone_screen_width" style="display: inline;"
                                                    id="height" formControlName="height" [readonly]="editBarcode"
                                                    (input)="onInputChange($event)">
                                                <span>mm</span>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            <!-- <ng-container formArrayName="add">
                <ng-container *ngFor="let addForm of add.controls;let i = index">
                    <tr >
                        <th colspan="2" class="tb-6" formControlName="package"
                            style="padding-bottom: 140px;">Package {{i+1}}
                            <!-- <button class="btn-close ml-3"
                        (click)="deletePkg(i)">&times;</button> -->
                            <!-- <button type="button" class="btn-close ml-3" aria-label="Close"
                               (click)="deletePkg(i)">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </th>
                        <td>
                            <div class="row">
                                <div class="col-sm-4 " style="padding-top: 10px;">
                                    <span>Safe type</span>
                                </div>
                                <div class="col-sm-6" style="padding-bottom: 10px;">
                                    <select class="form-control" formControlName="queue" name="queue"
                                        id="queue">
                                        <option value="">Normal</option>
                                        <option value=""></option>
                                        <option value="s"></option>
                                    </select>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-4" style="padding-top: 10px;">
                                    <span>Standard package </span>
                                </div>
                                <div class="col-sm-6" style="padding-bottom: 10px;">
                                    <select class="form-control" formControlName="queue" name="queue"
                                        id="queue">
                                        <option value="">Choose package</option>
                                        <option value=""></option>
                                        <option value="s"></option>
                                    </select>
                                </div>
                                <button type="button" class="close ml-5" aria-label="Close"
                                    style="padding-bottom: 30px;">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div class="row">
                                <div class="col-sm-4" style="padding-top: 10px;">
                                    <span>Dimensions(LxWxH)</span>
                                </div>
                                <div class="col-sm-6">
                                    <div class="row">
                                        <input type="text" style="width: 23%;" class="form-control ml-3"
                                            id="dimension1"><span class="mt-2 mx-1 ml-2">x</span>
                                        <input type="text" style="width: 23%;" class="form-control ml-2"
                                            id="dimension1"> <span class="mt-2 mx-1 ml-2">x</span>
                                        <input type="text" style="width: 23%;" class="form-control ml-2"
                                            id="dimension1">
                                    </div>
                                </div>
                                <button type="button" class="close ml-5" aria-label="Close"
                                    style="padding-bottom: 30px;">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </td>
                    </tr>
                    <th colspan="2">Rotable
                    </th>
                    <td>
                        <div class="row">
                            <div class="col-sm-6 form-check">
                                <input type="checkbox" style="width: 7%;" class="form-control"
                                    id="rotable">
                            </div>
                        </div>
                    </td>
                </ng-container>
            </ng-container> -->
                            <tr *ngIf="!editForm">
                                <td colspan="3" style="text-align: center;">
                                    <!-- <button class="btn bt'n-danger" (click)="addPackage()" >Add Package</button> -->
                                    <button class="btn btn-danger" type="submit"
                                        (click)="saveDetails('saveorder')">{{'Save'|translate}}</button>
                                    <button class="btn btn-outline-danger ml-3" (click)="saveDetails('placeorder')"
                                        [disabled]="lockerNotFree">{{'placeAnOrder'|translate}}</button>
                                </td>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>
    </form>
</div>


<div id="contentToConvert" style="display: none;">

    <p>{{selectedLocationname}}</p>
    <ngx-barcode [bc-value]="partenOrderForm.value.barcode" [bc-display-value]="true"></ngx-barcode>
</div>
