import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { LogsService } from '../../../Service/logs.service';
import { ActivatedRoute, Router } from '@angular/router'
import { FormGroup, FormControl } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/Service/spinner.service';
import * as lodash from 'lodash';
import { map } from 'rxjs/operators';
import { LangtransService } from '../../../Service/langtrans.service';
import { AppService } from '../../../Service/app.service';
import * as moment from 'moment'
@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {
  id: any
  queryStringEvent: any
  logdetails: any
  details: any
  selectedValue: any
  public filterForm

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  @ViewChild('myDiv', { static: true }) myDiv: ElementRef;
  showUnauthorized: boolean = false;
  selectBothdates: boolean = false;
  lang: any;
  maxDate: Date;
  @ViewChild('orderDateFromInput') orderDateFromInput:ElementRef
  @ViewChild('orderDateToInput') orderDateToInput:ElementRef
  invalidDateRange: boolean;
  constructor(
    public activatedRoute: ActivatedRoute,
    private logservice: LogsService,
    public router: Router,
    private toastr: ToastrService,
    private appService: AppService,
    private langtransService: LangtransService,
    private spinner: SpinnerService,
    private renderer:Renderer2
   ) {
    this.maxDate=new Date()
   }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      // stateSave: true
      destroy: true,
      search: false,
      order: []
    };
    this.filterForm = new FormGroup({
      logEvent: new FormControl('', []),
      logDateFrom: new FormControl('', []),
      logDateTo: new FormControl('', []),
    });
    this.appService.languagetoggle$.subscribe((data: any) => {
      this.lang = data
    })
    this.id = this.activatedRoute.snapshot.paramMap.get('id')
    this.spinner.show();
    this.logservice.fetchLogs(this.id)
      .pipe(map((resArray: Array<any>) => {
        return resArray.map((resObj: any) => {
          let dateVal = resObj.date.replace('Z', '');
          let cloneObj = { ...resObj };
          cloneObj.date = dateVal;
          return cloneObj;
        })

      }))
      .subscribe(
        (res: any) => {
          if (res) {
            this.logdetails = res
            // this.details = lodash.sortBy(res, 'date');
            // this.logdetails = this.details.sort((a, b) => {
            //   const propA = a.date;
            //   const propB = b.date;
            //   return propB - propA;
            // })
            if (this.logdetails.length && this.logdetails.length > 0) {
              this.dtTrigger.next();
            } else {
              this.logdetails = [];
            }
            // this.toastr.success('Logs Found !!', 'Success');
          } else {
            this.langaugeFetch(this.lang,'No Matching Data Found !!','info')
            //this.toastr.info('No Matching Data Found !!', 'Not Found')
          }
          this.spinner.hide();
        },
        err => {
          console.error(err);
          this.spinner.hide();
        }
      )

  }

  ngAfterViewInit() {
    let divElement = this.myDiv.nativeElement;
    let divstyle = window.getComputedStyle(divElement)
    let display = divstyle?.display;
    if (display == 'none') {
      this.showUnauthorized = true
    }
  }

  onReset() {
    this.invalidDateRange = false
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }
  onSubmit() {
    // this.logdetails = null;
    // Checking if both dates selected or not
    if (this.filterForm.value.logDateFrom == '' && this.filterForm.value.logDateTo == '') {
      this.selectBothdates = true
    }
    if (this.filterForm.value.logDateFrom != '') {
      if (this.filterForm.value.logDateTo == '') {
        this.selectBothdates = true
      }
      else {
        this.selectBothdates = false
      }
    }

    if (this.filterForm.value.logDateTo != '') {
      if (this.filterForm.value.logDateFrom == '') {
        this.selectBothdates = true
      }
      else {
        this.selectBothdates = false
      }
    }
    this.invalidDateRange = (this.filterForm.value.logDateFrom > this.filterForm.value.logDateTo) && this.filterForm.value.logDateTo != ''
    if (!this.selectBothdates && !this.invalidDateRange) {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 50,
        destroy: true,
        search: false,
        order: []
      };
      this.spinner.show();
      this.rerender();
      this.id = this.activatedRoute.snapshot.paramMap.get('id')
      let queryParams = {
        event: this.filterForm.value.logEvent || undefined,
        fromDate: moment(this.filterForm.value.logDateFrom).format('YYYY-MM-DD') || undefined,
        toDate: moment(this.filterForm.value.logDateTo).format('YYYY-MM-DD') || undefined,
      }
      this.logservice.filterLogs(this.id, queryParams)
        .pipe(map((resArray: Array<any>) => {
          return resArray.map((resObj: any) => {
            let dateVal = resObj.date.replace('Z', '');
            let cloneObj = { ...resObj };
            cloneObj.date = dateVal;
            return cloneObj;
          })
        }))
        .subscribe(
          (res: any) => {
            this.spinner.hide();
            if (res) {
              this.logdetails = res;
              if (res.length && res.length > 0) {
                this.dtTrigger.next();
              } else {
                this.logdetails = [];
                this.dtTrigger.next();
              }
              // this.toastr.success('Logs Found !!', 'Success');
            } else {
              this.langaugeFetch(this.lang,'No Matching Data Found !!','info')
              //this.toastr.info('No Matching Data Found !!', 'Not Found')
            }
          },
          err => {
            console.error(err);
            this.spinner.hide();
          }
        )
    }
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
  }
  getToday(): string {
    return new Date().toISOString().split('T')[0]
  }
  getMeta = (meta: any) => {
    return JSON.stringify(meta);
  }

  langaugeFetch(lang, key, toastrstatus) {
    this.langtransService.TranslateData(lang, key).subscribe(
      res => {
        if (toastrstatus == 'info') {
          this.toastr.info(res)
        }
      }
    )
  }
  openDatePicker(v){
    if(v=='orderDateToInput'){
      this.renderer.selectRootElement(this.orderDateToInput.nativeElement).click()
    }
    if(v=='orderDateFromInput'){
      this.renderer.selectRootElement(this.orderDateFromInput.nativeElement).click()
    }
  }
}
