import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { DeliverypointsService } from 'src/app/Service/deliverypoints.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  id: string;
  public DpSettingsForm
  DpSettings: any;
  showSpinner: boolean = true


  constructor(
    public activatedRoute: ActivatedRoute,
    private deliverypointsService: DeliverypointsService,
    private toastr: ToastrService,
    private translate : TranslateService,
    private router : Router
  ) { }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id')
    this.DpSettingsForm = new FormGroup({
      lidl_image: new FormControl(false,[Validators.required])
    })
    this.deliverypointsService.fetchwithId(this.id).then(res=>{
      this.DpSettings = res
      if(this.DpSettings.settings && this.DpSettings.settings.lidlEndImage){
        this.DpSettingsForm.patchValue({
          "lidl_image":this.DpSettings.settings.lidlEndImage
        })
      }
      this.showSpinner = false
    }).catch(ex=>{
      this.showSpinner = false
      this.toastr.error(this.translate.instant('failedToFetch'),this.translate.instant('Failed'))
    })

  }
  onSubmit(){
    this.showSpinner= true
    let lidl_Check = this.DpSettingsForm.get('lidl_image').value
    let updateSettings = {"lidlEndImage":lidl_Check}
    this.deliverypointsService.updateLockerSettings(this.id, updateSettings).then((response)=>{
      this.toastr.success(this.translate.instant('Locker settings updated'))
      this.showSpinner = false
    }).catch((error)=>{
      this.toastr.error(this.translate.instant('Failedtoupload'))
      this.showSpinner = false
    }).finally(()=>{
      this.router.navigate(['/deliverypoints/' + this.id]);
    })

  }

}
