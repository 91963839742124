import { HttpClient } from '@angular/common/http';
import { ddsEnvironment, losEnvironment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DeliverypointsService {
  constructor(
    private http: HttpClient
  ) {

  }

  generateVncToken(id: string) {
    return this.http.post(
      `${ddsEnvironment.baseUrl}/deliverypoints/${id}/access`, {
      type: 'vnc_token'
    })
  }

  fetchMany(query: any): Promise<any> {
    Object.keys(query).forEach(key => query[key] === undefined ? delete query[key] : {});
  
    if (query.hasOwnProperty('acCode')){ // to filter data if multiple acCodes are selected
      return this.http.post(
        `${ddsEnvironment.baseUrl}/deliverypoints/search`,query).toPromise()
    } else {
      return this.http.get(
        `${ddsEnvironment.baseUrl}/deliverypoints`, {
        params: query
      }).toPromise()
    }
  }

  downloadLockerDetails(query: any): Promise<any> {
    // return this.http.get('assets/dummy.Json').toPromise()
    Object.keys(query).forEach(key => query[key] === undefined ? delete query[key] : {});

    return this.http.get(
      `${ddsEnvironment.baseUrl}/deliverypoints?output=csv-download`, {
      params: query,
      // responseType: 'blob'
    }).toPromise()
  }
  // downloadLockerDetails(body:any) {
  //   return this.http.get(
  //     `${ddsEnvironment.baseUrl}/deliverypoints?output=csv-download`, { responseType: 'blob' }).toPromise()
  // }

  fetchwithId(id: any, query: any = {}) {

    return this.http.get(
      `${ddsEnvironment.baseUrl}/deliverypoints/` + id, {
      params: query
    }).toPromise()
  }

  fetchAcCode() : Promise <any> {
    return this.http.get(`${ddsEnvironment.baseUrl}/deliverypoints/acCode`).toPromise()
 }

  updateLockerDetails(id: any, lockerDetails: any) {
    return this.http.post(
      `${ddsEnvironment.baseUrl}/deliverypoints/` + id, lockerDetails)
  }

  fetchLockerSettings() {
    return this.http.get(
      `${ddsEnvironment.baseUrl}/deliverypoints/settings`)
  }

  updatelockerSettings(lockerSettings: Object) {
    return this.http.post(
      `${ddsEnvironment.baseUrl}/deliverypoints/settings`, lockerSettings)
  }

  updateCommand(id: any, command: Object) {
    return this.http.post(
      `${losEnvironment.baseUrl}/deliverypoints/` + id + `/command`, command)
  }

  addLockerDetails(lockerDetails: any) {
    return this.http.post(
      `${ddsEnvironment.baseUrl}/deliverypoints`, lockerDetails)
  }

  startIoTCommissioning(id: any) {
    return this.http.post(`${ddsEnvironment.baseUrl}/deliverypoints/` + id + `/connectivity`,
      {
        technology: "aws-iot"
      })
  }

  fetchDashBoard() {
    return this.http.get(
      `${ddsEnvironment.baseUrl}/deliverypoints`, {
      params: {
        summary: "technology-iot-summary"
      }
    })
  }


  //uploadXml File

  uploadXml(xmlData: any) {
    return this.http.post(`${ddsEnvironment.baseUrl}/deliverypoints/addlocker`, xmlData)
  }

  updateLockerSettings(id: any, lockerSettings: any) {
    return this.http.post(
      `${ddsEnvironment.baseUrl}/deliverypoints/` + id + `/settings`, lockerSettings).toPromise()
  }



}
