import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { ToastrService } from 'ngx-toastr';
import { PartnerService } from '../../../Service/partner.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-partner-edit-info',
  templateUrl: './partner-edit-info.component.html',
  styleUrls: ['./partner-edit-info.component.scss']
})
export class PartnerEditInfoComponent implements OnInit {

  showSpinner: boolean = false;
  partnerDetails: any;
  partnerForm: FormGroup
  submitted: boolean;
  partnerId: any

  constructor(public activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private partnerService: PartnerService,
    private router: Router,
    private formBuilder: FormBuilder,
    private translate: TranslateService
  ) {
    this.partnerForm = this.formBuilder.group({
      partnerName: ['', Validators.required],
      country: ['', Validators.required],
      active: ['', Validators.required],
      prsId:['',Validators.pattern("^[0-9]*$")]
    })
  }

  ngOnInit(): void {
    let partnerId = this.activatedRoute.snapshot.params.id;
    this.partnerId = partnerId
    this.getPartnerDetails(partnerId);
  }

  get formValidators() {
    return this.partnerForm.controls;
  }

  //Fech partner details
  getPartnerDetails(partnerId) {
    this.showSpinner = true;
    this.partnerService.fetchPartnerDetails(partnerId).subscribe(
      res => {
        this.partnerDetails = res
        this.showSpinner = false;

        this.partnerForm.patchValue({
          partnerName: this.partnerDetails.name,
          country: this.partnerDetails.country,
          active: this.partnerDetails.active,
          prsId: this.partnerDetails.prsId
        })
      },
      err => {
        this.showSpinner = false;
        this.toastr.error(this.translate.instant('Failed to fetch partner details !!'), this.translate.instant('Failed'));
        console.error(err);
      }
    )
  }


  onSubmit() {
    this.submitted = true
    if (this.partnerForm.valid) {
      let editPartner = {
        "id": parseInt(this.partnerId),
        "active": typeof(this.partnerForm.controls.active.value) == 'boolean' ? (this.partnerForm.controls.active.value).toString() : this.partnerForm.controls.active.value,
        "name": this.partnerDetails.name,
        "prsId": parseInt(this.partnerForm.controls.prsId.value)
      }
      this.partnerService.editPartner(editPartner).subscribe((res) => {
        if (res) {
          this.toastr.success(this.translate.instant('Partner Updated Successfully!'), this.translate.instant('Success'))
        }
      }, (err: any) => {
        console.error(err);
        this.toastr.info(err.error.error.message, this.translate.instant('Failed'))
      })
    }
  }

  cancel() {
    this.router.navigate(['/partners/', this.partnerId]);
  }

}
