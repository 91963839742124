import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DeliverypointsService } from '../../../Service/deliverypoints.service';
import { ActivatedRoute,Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../../../Service/app.service';
import { LangtransService } from 'src/app/Service/langtrans.service';

@Component({
  selector: 'app-locker-upload',
  templateUrl: './locker-upload.component.html',
  styleUrls: ['./locker-upload.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LockerUploadComponent implements OnInit {

  acCode: any
  translateData: any;
  uploadForm: FormGroup;
  constructor(public activatedRoute: ActivatedRoute,
    private deliverypointsService: DeliverypointsService,
    private toastr: ToastrService,
    public router: Router,
    private formBuilder: FormBuilder, private appService: AppService, private langtransService: LangtransService) {

    this.uploadForm = this.formBuilder.group({
      acceptSoftReservations: ['', Validators.required],
      livedate: ['', Validators.required],
      file: ['', Validators.required],
      lockerName: [''],
      lockerPostalCode: [''],
      acCode: ['', Validators.required],
      language: ['', Validators.required]
    })
  }

  invalid = false;
  // uploader: FileUploader = new FileUploader({ url: 'upload-url' });
  xmlString: any;
  selectedFile: any;
  selectedFilePath: any;
  public errorTxt: boolean = false;
  acCodeList: any = []
  selectedAccode: any
  showUnauthorized: boolean = false;
  submitted: boolean;
  languagechar: any;
  deliveryPointList: any;
  lockerName: any;
  filteredList1: any;
  variables: any;
  fileSizeExceeded: boolean
  showSpinner: boolean = false
  fileType: boolean

  @ViewChild('myDiv', { static: true }) myDiv: ElementRef;

  // ngAfterViewInit() {
  //   let divElement = this.myDiv.nativeElement;
  //   let divstyle = window.getComputedStyle(divElement)
  //   let display = divstyle?.display;
  //   if (display == 'none') {
  //     this.showUnauthorized = true
  //   }
  // }

  onFileChange(event: any): void {
    this.errorTxt = false;
    this.selectedFile = event.target.files[0]
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];

    if (file) {
      const validationData = this.validateFile(file);
      if (!validationData) {
        this.convertXMLToString(file);
      }

    } else {
      this.errorTxt = true;
    }
  }

  convertXMLToString(file) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.xmlString = e.target.result as string;
    }
    reader.readAsText(file);
  }

  validateFile(file: File): boolean {
    this.fileSizeExceeded = false
    this.fileType = false
    const allowedTypes = ['text/xml'];
    if (!allowedTypes.includes(file.type)) {
      this.invalid = true;
      this.fileType = true

    } else if (file.size > 5242880) {
      this.invalid = true;
      this.fileSizeExceeded = true
    }
    else {
      this.invalid = false;
    }
    return this.invalid
  }

  langaugeFetch(lang, key, toastrstatus) {
    this.langtransService.TranslateData(lang, key).subscribe(
      res => {
        if (toastrstatus == 'success') {
          this.toastr.success(res)
        }
        if (toastrstatus == 'error') {
          this.toastr.error(res)
        }
      }
    )
  }

  onUpload() {
    this.submitted = true
    for (const control in this.uploadForm.controls) {
      if (this.uploadForm.controls.hasOwnProperty(control)) {
        this.uploadForm.get(control).markAsTouched();
      }
    }
    if (this.uploadForm.valid) {
      this.showSpinner = true
      let reqObj = {
        fileReq: this.xmlString,
        acceptSoftReservations: this.uploadForm.controls.acceptSoftReservations.value,
        acCode: this.uploadForm.controls.acCode.value,
        livedate: this.uploadForm.controls.livedate.value,
        language: this.uploadForm.controls.language.value
      }
      if (this.xmlString && this.selectedFile && this.invalid === false) {

        this.deliverypointsService.uploadXml(reqObj).subscribe(
          res => {
            if (res) {
              this.showSpinner = false
            }
            let response = JSON.stringify(res)
            let parsedRes = JSON.parse(response)
            if (parsedRes.success) {
              this.langaugeFetch(this.translateData, 'Fileuploaded', 'success')
              this.uploadForm.reset()
              this.uploadForm.get('acCode').setErrors(null)
              this.uploadForm.get('file').setErrors(null)
              this.uploadForm.patchValue({
                "acceptSoftReservations": "",
                "lockerName": "",
                "lockerPostalCode": ""
              })
            } else if (parsedRes?.failure?.reason === 'PARCEL_AVAILABLE') {
              this.showSpinner = false
              this.langaugeFetch(this.translateData, 'FailedtouploadDueToExistingParcel', 'error')
              console.error(parsedRes.failure);
            } else {
              this.showSpinner = false
              this.langaugeFetch(this.translateData, 'Failedtoupload', 'error')
              console.error(parsedRes);
            }
          },
          err => {
            this.showSpinner = false
            this.langaugeFetch(this.translateData, 'Failedtoupload', 'error')
            console.error(err);
          }
        )
      } else {
        this.errorTxt = this.invalid === true ? false : true
        this.showSpinner = false
        if (this.fileSizeExceeded) {
          this.langaugeFetch(this.translateData, 'XMLfileSizeExceeded', 'error')
        } else if (this.fileType) {
          this.langaugeFetch(this.translateData, 'InvalidFile', 'error')
        } else {
          this.langaugeFetch(this.translateData, 'Failedtoupload', 'error')
        }
      }
    }
  }

  // public resetForm(): void {
  //   this.selectedFile = undefined;
  //   this.selectedFilePath = undefined;
  //   this.errorTxt = false;
  //   this.invalid = false;
  //   this.xmlString = '';


  // };

  ngOnInit(): void {
    this.showSpinner=true;
    this.deliverypointsService.fetchMany({}).then(res => {
      this.showSpinner=false;
      this.deliveryPointList = res
      let code;
      this.deliveryPointList.map(val => {
        code = val.acCode
        this.acCodeList.push(code)
      })

      this.variables = this.acCodeList
      this.filteredList1 = this.variables.slice()
    },err => {
      this.showSpinner=false;
    })

    this.appService.languagetoggle$.subscribe((data: any) => {
      this.translateData = data
    })
  }

  get formValidators() {
    return this.uploadForm.controls;
  }

  onChangeCode(e, value) {
    this.acCode = value
    let filteredVal = this.deliveryPointList.filter((element) => {
      if (element.acCode == this.acCode) {
        return element
      }
    })

    this.uploadForm.patchValue({
      "lockerName": filteredVal[0].lockerName,
      "lockerPostalCode": filteredVal[0].address.postalCode
    })
  }

  getToday(): string {
    return new Date().toISOString().split('T')[0]
  }
}

