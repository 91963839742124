<nav class="navbar shadow navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand my-0 mr-md-auto" [routerLink]="['/']">
    <img class="logo" src="/assets/img/Bpost_Logo.svg" alt="" style="display: inline-block;">
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02"
    aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <!-- <div *ngIf="loading && userInfo " class="spinner-border" role="status"  style="margin-right:65%">
    <span class="sr-only">Loading...</span>
  </div> -->

  <div class="collapse navbar-collapse" id="navbarTogglerDemo02">

    <ul *ngIf="userInfo" class="navbar-nav mr-auto ">
      <li class="nav-item dropdown" routerLinkActive="active" [ngClass]="{'noneProperty':!showDeliverypointHeader}">
        <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{'Delivery Points'| translate}}</a>
        <div class="dropdown-menu">
          <a #delList class="dropdown-item" [routerLink]="['/deliverypoints']"
            [appViewController]="{deliveryPoints:'showList'}">{{'List'|translate}}</a>
          <a #delonBoard class="dropdown-item" [routerLink]="['/locker-upload']"
            [appViewController]="{deliveryPoints:'showOnboard'}">{{'Onboard'|translate}}</a>
          <div class="dropdown-divider" *ngIf="partnerId == 1"></div>
          <a #postMan class="dropdown-item" *ngIf="partnerId == 1" [routerLink]="['/postman']"
            [appViewController]="{staff:'postmanCode'}">{{'Generate Login Code'|translate}}</a>
        </div>
      </li>

      <li class="nav-item dropdown" routerLinkActive="active" [ngClass]="{'noneProperty':!showparcelHeader}">
        <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{'Parcels'| translate}}
        </a>
        <div class="dropdown-menu">
          <a #parcelList class="dropdown-item" [routerLink]="['/parcels']"
            [appViewController]="{parcels:'list'}">{{'List'|translate}}</a>
          <div class="dropdown-divider"></div>
          <a #parcelLastmile class="dropdown-item" [routerLink]="['/reservation']"
            [appViewController]="{parcels:'reserveLastmile'}">{{'Reserve Last Mile'|translate}}</a>
          <a #parcelFirstmile class="dropdown-item" [routerLink]="['/reservation/firstmile']"
            [appViewController]="{parcels:'reserveFirstmile'}">{{'Reserve First Mile'|translate}}</a>

        </div>
      </li>

      <li class="nav-item dropdown" routerLinkActive="active" [ngClass]="{'noneProperty':!showUserHeader}">
        <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{'Users'|translate}}
        </a>
        <div class="dropdown-menu">
          <a #userList class="dropdown-item" [routerLink]="['/user/search']"
            [appViewController]="{users:'showList'}">{{'List' | translate}}</a>
          <a #userAdd class="dropdown-item" [routerLink]="['/user/details']"
            [appViewController]="{users:'addUser'}">{{'Add' | translate}}</a>
          <div class="dropdown-divider"></div>
          <a #userGroup class="dropdown-item" [routerLink]="['/user/usergroup']"
            [appViewController]="{users:'showGroup'}">{{'Groups' | translate}}</a>
          <div class="dropdown-divider" *ngIf="partnerId == 1"></div>
          <a #postMan class="dropdown-item" *ngIf="partnerId == 1" [routerLink]="['/maintenanceUser']"
            [appViewController]="{staff:'postmanCode'}">{{'Maintenance Users' | translate}}</a>
        </div>
      </li>
      <li
        class="nav-item dropdown"
        *ngIf="partnerId == 1"
        routerLinkActive="active"
        [ngClass]="{'noneProperty':!showpartnerHeader}"
      >
        <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{'Partners'|translate}}</a>
        <div class="dropdown-menu">
          <a #partnerlist [appViewController]="{partner:'showList'}" class="dropdown-item"
            [routerLink]="['/partners']">{{'List'|translate}}</a>
          <a #partneradd [appViewController]="{partner:'addPartner'}" class="dropdown-item"
            [routerLink]="['/createPartners']">{{'Add'|translate}}</a>
        </div>
      </li>
      <!-- <li class="nav-item" routerLinkActive="active"> -->

        <a class="nav-link" [routerLink]="['/partnertools']"
          [appViewController]="{partnerTool:'viewPartnerTool'}">{{'Partner Tool'|translate}}</a>

        <!-- <li class="nav-item dropdown" routerLinkActive="active">
        <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          Postman Login</a>
        <div class="dropdown-menu">
          <a class="dropdown-item" (click)="postmanLogin()"></a>
        </div>
      </li> -->
        <!-- <div *ngIf="router.url !='/'" class="postmanClass" (click)="postmanLogin()">Postman</div> -->
        <!-- <li class="nav-item dropdown" routerLinkActive="active" [ngClass]="{'noneProperty':!showPostman}">
        <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{'Staff'|translate}}</a>
        <div class="dropdown-menu">


        </div>
      </li> -->
      <li class="nav-item dropdown" *ngIf="partnerId == 1" routerLinkActive="active"
        [ngClass]="{'noneProperty':!showReportHeader}">
        <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{'Reports'|translate}}</a>
        <div class="dropdown-menu">
          <a #downTime class="dropdown-item" [routerLink]="['/lockerdownreport']"
            [appViewController]="{report:'downTimeReport'}">{{'Downtimes'|translate}}</a>
          <a #firstFail class="dropdown-item" [routerLink]="['/fm-failed']"
            [appViewController]="{report:'firstmileFailed'}">{{'First Miles Failed'|translate}}</a>
          <a #userReport class="dropdown-item" [routerLink]="['/userOperation']"
            [appViewController]="{report:'userOpsReport'}">{{'User Operations'|translate}}</a>
        </div>
      </li>
      <!-- <li class="nav-item dropdown" routerLinkActive="active" [appViewController]="{deliveryPoints:'showLogs'}" >
        <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          Test custom</a>

      </li>
      <li class="nav-item dropdown" routerLinkActive="active" [appViewController]="{parcels:'showLogs'}" >
        <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          Test custom2</a>

      </li> -->
    </ul>
    <app-langgtoggle *ngIf="userInfo"></app-langgtoggle>
    <app-langgtoggle *ngIf="!userInfo"></app-langgtoggle>

    <div class="dropdown" *ngIf="userInfo">
      <input src="/assets/img/user2.svg" width="50" height="50" alt="User" class="btn btn-sm dropdown-toggle mr-2 "
        type="image" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
        <a class="dropdown-item " *ngIf='authorized'>{{email}}</a>
        <!-- <a class="dropdown-item" href="#">Another action</a>
          <a class="dropdown-item" href="#">Something else here</a> -->
      </div>
    </div>
    <!-- <button *ngIf="!userInfo" class="btn btn-danger navbar-btn ml-2" id="handle-sign-in"
      (click)='authorizationService.authorize()'>SIGN IN</button> -->
    <button *ngIf="!userInfo" class="btn btn-outline-danger navbar-btn ml-2" id="handle-sign-in"
      [routerLink]="['/signin']">{{'Sign in' | translate}}</button>
    <button *ngIf="userInfo" class="btn btn-outline-danger  navbar-btn ml-2" id="handle-sign-in"
      (click)='authorizationService.signOut(); clearLanguage()'>{{'Sign out'|translate}}</button>
  </div>
</nav>
<app-loader *ngIf="showSpinner"></app-loader>
<!-- <div style="display: none;">
<a  [appViewController]="{deliveryPoints:'showList'}">hiii</a>
</div> -->
<script>
  $(".ul li").on("click", function () {
    $(".ul li").removeClass("active");
    $(this).addClass("active");
  });
</script>
