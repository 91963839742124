import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ParcelService } from 'src/app/Service/parcel.service';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';
import { PartnerService } from 'src/app/Service/partner.service';
import { ToastrService } from 'ngx-toastr';
import { LangtransService } from 'src/app/Service/langtrans.service';
import { AppService } from '../../Service/app.service';
import { SpinnerService } from 'src/app/Service/spinner.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-fm-failed-report',
  templateUrl: './fm-failed-report.component.html',
  styleUrls: ['./fm-failed-report.component.scss']
})
export class FmFailedReportComponent implements OnInit {
  countries = [{
    id: 'BE', name: 'Belgium',
  },
  ];
  activities = [{
    id: 1, name: true
  },
  {
    id: 2, name: false
  }
  ];
  reserves = [{
    id: 1, name: true
  },
  {
    id: 2, name: false
  }
  ];
  businesses = [{
    id: 1, name: 'Bpost'
  },
  {
    id: 2, name: 'Dhm'
  },
  {
    id: 3, name: 'Dlm'
  }
  ];
  reportForm: FormGroup;
  submitted: boolean;
  partnerList: any;
  partnerNamedropdownList: any[];
  Partner: any[];
  lockerFetchfail: any;
  translateData: any;
  maxDate = new Date();
  @ViewChild('fromDateInput') fromDateInput: ElementRef
  @ViewChild('toDateInput') toDateInput: ElementRef
  constructor(
    private langtransService: LangtransService,
    private appService: AppService,
    private _formBuilder: FormBuilder,
    private _parcelService: ParcelService,
    public datepipe: DatePipe,
    private _partnerService: PartnerService,
    private toastr: ToastrService,
    private renderer: Renderer2,
    private spinner: SpinnerService
  ) {
    // const currentDate = new Date().toISOString().split('T')[0];
    // this.maxDate = currentDate;
  }

  ngOnInit(): void {
    this.appService.languagetoggle$.subscribe((data: any) => {
      this.translateData = data
    })
    this.reportForm = this._formBuilder.group({
      country: ['', Validators.required],
      // active: ['', Validators.required],
      softReservation: [''],
      businessesPartner: ['', Validators.required],
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
    })
    this.fetchPartners()
    this.reportForm.patchValue({
      country: this.countries[0].id,
      softReservation: false

    })
  }
  onChange(v) {
  }
  exportButton() {
    const newArray = []
    this.submitted = true
    var obj = {
      country: this.reportForm.controls.country.value,
      // active: this.reportForm.controls.active.value,
      softReserved: this.reportForm.controls.softReservation.value,
      businessPartner: this.reportForm.controls.businessesPartner.value,
      fromDate: this.datepipe.transform(this.reportForm.controls.fromDate.value, 'yyyy-MM-dd'),
      toDate: this.datepipe.transform(this.reportForm.controls.toDate.value, 'yyyy-MM-dd'),
    }

    if (this.reportForm.valid) {
      this.spinner.show();
      this._parcelService.firstMailFailedItems(obj).subscribe((res: any) => {
        this.spinner.hide();
        if (res.length > 0) {
          res.forEach(element => {
            const timeStamp = moment.utc(element.firstMileReservationAttemptedAt).local().format('YYYY-MM-DD HH:mm:ss');
            // const timeStamp = element.firstMileReservationAttemptedAt.replace("T", ' ').replace(/\.\d+Z$/, '')
            const newObj = {
              LocationTitle: element.lockerMunicipality + "|" + element.lockerStreetName + "|" + element.lockerName + "*",
              BarCode: element.barcode,
              Date: element.firstMileReservationAttemptedAt ? timeStamp : '-'
            }
            newArray.push(newObj)
          });
          // if (obj.businessPartner == 1)
          //   this.Partner = 'Bpost'
          // if (obj.businessPartner == 2)
          //   this.Partner = 'GLS Group'
          // if (obj.businessPartner == 3)
          //   this.Partner = 'DHL'
          // if (obj.businessPartner == 4)
          //   this.Partner = 'SLS Cargo'
          this.Partner = this.partnerNamedropdownList.filter(
            item=>
              item.id==
            parseInt(this.reportForm.controls.businessesPartner.value)
          )
          const Today = this.datepipe.transform(new Date(), 'dd-MM-yyyy')
          const filename = 'FirstMileFailed_' + this.Partner[0].name + '_' + Today
          this.exportToExcel(newArray, filename)
        } else {
          this.langaugeFetch(this.translateData, 'No Records Found!')
          //this.toastr.warning("You dont'have records on your specific search")
        }
      }, err => {
        this.spinner.hide();
      })
    }
  }
  exportToExcel(data: any[], filename: string) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    // ws['!cols'] = headers.map(header => ({ width: 15 })); // Adjust column widths if needed
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Data');
    XLSX.writeFile(wb, `${filename}.xlsx`);

  }
  get formValidators() {
    return this.reportForm.controls
  }
  fetchPartners() {
    this.spinner.show();
    this._partnerService.fetchPartnersList().subscribe(
      (result) => {
        this.spinner.hide();
        this.partnerList = result
        // for (let i = 0; i < this.partnerList.length; i++) {
        //   if (this.partnerList[i].active == true) {
        //     partnerNameList[i] = { id: this.partnerList[i].id, name: `${this.partnerList[i].name}` };
        //   }
        // }

        this.partnerNamedropdownList = this.partnerList.filter(item => item.active).map(item => ({ id: item.id, name: item.name }))
        // this.partnerNamedropdownSettings = {
        //   singleSelection: false,
        //   idField: 'id',
        //   textField: 'name',
        //   selectAllText: 'Select All',
        //   unSelectAllText: 'UnSelect All',
        //   itemsShowLimit: 3,
        //   allowSearchFilter: true
        // };

        if (this.partnerNamedropdownList.length > 0) {
          let defaultBusinessesPartner = this.partnerNamedropdownList.filter((element) => element.name == "Bpost")
          this.reportForm.patchValue({
            businessesPartner: defaultBusinessesPartner[0].id
          })
        }


      }, err => {
        this.spinner.hide();
      })
  }

  onReset() {
    this.submitted = false;
    this.reportForm.reset()
    Object.keys(this.reportForm.controls).forEach(key => {
      this.reportForm.get(key).setErrors(null);
    });
    this.reportForm.setValue({
      country: '',
      softReservation: '',
      businessesPartner: '',
      fromDate: '',
      toDate: ''
    })
  }

  langaugeFetch(lang, key) {
    this.langtransService.TranslateData(lang, key).subscribe(
      res => {
        this.toastr.warning(res)
      }
    )
  }
  openDatePicker(v) {
    if (v == 'fromDateInput')
      this.renderer.selectRootElement(this.fromDateInput.nativeElement).click()
    if (v == 'toDateInput')
      this.renderer.selectRootElement(this.toDateInput.nativeElement).click()
  }
}
