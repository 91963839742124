import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PartnerService } from '../Service/partner.service';
import { AppService } from '../Service/app.service';
import { data } from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from '../Service/spinner.service';
import { LangtransService } from '../Service/langtrans.service';
@Component({
  selector: 'app-maintenance-staff',
  templateUrl: './maintenance-staff.component.html',
  styleUrls: ['./maintenance-staff.component.scss']
})
export class MaintenanceStaffComponent implements OnInit {
  maintenance = true;
  addUserForm: FormGroup;
  userService: any;
  submitted: boolean;
  list: { name: string; checked: boolean; }[];
  id: any;
  languagechar: any;
  idValue: string;
  header: string;
  inEditView = false;
  editPartnerObj=null;
  showPasswordOnPress:boolean
  partnerList: any;
  loginIdValidateError = false;
  loginIdCopabilityMatched: any;
  showMissmatchError = false;
  constructor(private fb: FormBuilder,
    private router: Router,
    private partnerService: PartnerService,
    private route: ActivatedRoute,
    private appService: AppService,
    private toastr: ToastrService,
    private spinner: SpinnerService,
    private langtransService: LangtransService,
  ) {
    this.list = [
      { name: 'CAN_SWEEP', checked: false },
      { name: 'CAN_CLEAN', checked: false }
    ];
  }
  ngOnInit(): void {

    this.addUserForm = this.fb.group({
      name: ['', Validators.required],
      loginId: ['', Validators.required],
      capabilities: ['', Validators.required],
      password: ['', [Validators.pattern(/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/),Validators.required,Validators.minLength(8)]],
    });
    this.inEditView =false;
    this.route.params.subscribe((data) => {
      this.id = data
    });
    this.appService.languagetoggle$.subscribe((data: any) => {
      this.inEditView =true;
      this.languagechar = data
      if(Object.keys(this.id).length=== 0){
        this.header = 'Add Maintenance User'
        this.idValue=''
      }else{
        this.header = 'Edit Maintenance User'
        this.editDataFetch(this.id)
        this.idValue=this.id.id
      }
    })
  }
  get formValidators() {
    return this.addUserForm.controls;
  }
  editDataFetch(id){
    this.partnerService.fetchMaintenanceUserById(id.id).subscribe((res:any) => {
      this.addUserForm.setValue({
        name: res.name,
        loginId: res.loginId,
        capabilities: res.capabilities,
        password: res.password
      })
    })
  }
  submit() {
    this.submitted = true
    if (this.addUserForm.valid) {
      let userDataString = localStorage.getItem('userData');
      let uid = '';
      if (userDataString) {
        let jsonuserData = JSON.parse(userDataString);
        uid = jsonuserData.uid;
      }
      //checking loginId ends with SWEEP or CLEAN
      let loginID = this.addUserForm.controls.loginId.value.toUpperCase()
      if (loginID.endsWith('SWEEP') || loginID.endsWith('CLEAN')) {
        this.loginIdValidateError = false;
        // checking in loginId ends with SWEEP and capabilities=CAN_SWEEP or 
        //  loginId ends with CLEAN and capabilities=CAN_CLEAN
        if (loginID.endsWith('SWEEP') && this.addUserForm.controls.capabilities.value.includes('CAN_SWEEP')) {
          this.loginIdCopabilityMatched = true
        }
        else if (loginID.endsWith('CLEAN') && this.addUserForm.controls.capabilities.value.includes('CAN_CLEAN')) {
          this.loginIdCopabilityMatched = true
        }
        else {
          this.loginIdCopabilityMatched = false
          this.showMissmatchError = true
        }
      } else {
        this.loginIdValidateError = true;
      }
      //if loginId and capabilities are Matched then adding details.
      if (this.loginIdCopabilityMatched) {
      let obj = {
        name: this.addUserForm.controls.name.value,
        loginId: this.addUserForm.controls.loginId.value.toUpperCase(),
        capabilities: this.addUserForm.controls.capabilities.value,
        password: this.addUserForm.controls.password.value.toUpperCase(),
        language: this.languagechar.toUpperCase(),
        id: this.idValue,
        createdBy: uid
      }
      this.spinner.show();
      this.partnerService.createMaintenanceUser(obj).subscribe((res: any) => {
        this.spinner.hide();
        if (res.code) {
          this.langaugeFetch(this.languagechar, 'Userid already taken !!', 'info')
          return;
        }
        this.router.navigate(['/maintenanceUser'])
      }, (err: any) => {
        this.spinner.hide();
        this.langaugeFetch(this.languagechar, 'Something bad happened, please try again later.', 'info')
      })
      }

      // if (Object.keys(this.id).length === 0) {
      //   this.partnerService.createMaintenanceUser(obj).subscribe((res) => {
      //     console.log(res, 44)
      //     if(res=='inserted successfully'){
      //       this.router.navigate(['/maintenanceUser'])
      //     }
      //   })
      // }
      // else {
      //   this.partnerService.updateMaintenanceUser(obj).subscribe((res) => {
      //     console.log(res, 63)
      //     this.router.navigate(['/maintenanceUser'])
      //   })
      // }

    }

  }

  langaugeFetch(lang, key, toastrstatus) {
    this.langtransService.TranslateData(lang, key).subscribe(
      res => {
        if (toastrstatus == 'info') {
          this.toastr.info(res)
        }
      }
    )
  }
  cancel = () => {
    this.router.navigate(['maintenanceUser'])
  }

}
