import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation, Renderer2 } from '@angular/core';
import { FormGroup, FormControl, AbstractControl, ValidatorFn } from '@angular/forms';
import { ParcelService } from '../../../Service/parcel.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { DeliverypointsService } from '../../../Service/deliverypoints.service';
import { PartnerService } from '../../../Service/partner.service';
import * as FileSaver from 'file-saver';
import { DataTableDirective } from 'angular-datatables';
import { Subscription, Subject, Observable } from 'rxjs';
import *as Papa from 'papaparse';
import * as moment from 'moment'
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/Service/app.service';
import { DatePipe } from '@angular/common';
import { SpinnerService } from 'src/app/Service/spinner.service';
import { UserService } from 'src/app/Service/user.service';
@Component({
  selector: 'app-parcel-list',
  templateUrl: './parcel-list.component.html',
  styleUrls: ['./parcel-list.component.scss']
})
export class ParcelListComponent implements OnInit {
  orderTypedropdownList = [];
  parcelLockerNamedropdownList = [];
  partnerNamedropdownList = [];
  lastStatusdropdownList = [];
  orderTypeselectedItems = [];
  parcelLockerNameselectedItems = [];
  partnerNameselectedItems = [];
  lastStatusselectedItems = [];
  orderTypedropdownSettings: IDropdownSettings;
  parcelLockerNamedropdownSettings: IDropdownSettings;
  partnerNamedropdownSettings: IDropdownSettings;

  lastStatusdropdownSettings: IDropdownSettings;

  idField: any
  lockerList: any
  result: any = [];
  barcode: string
  showSpinner: boolean = true
  showDownload: boolean = true;
  parcelLockerName: string
  data: Object
  orderDateTo: any
  orderDateFrom: any
  orderType: string
  postalCode: string
  dateOfPickupCustomerFrom: any
  dateOfPickupCustomerTo: any
  dateOfDropFrom: any
  dateOfDropTo: any
  dateOfPickupCourierFrom: any
  dateOfPickupCourierTo: any
  customerName: string
  customerEMail: string
  senderEmail: string
  courier: string
  senderName: string
  backupAttributed: string
  reservationRequest: string
  lastStatus: string
  excludeCancelledOrders: string
  product: string
  partnerName: string
  partnerList: any
  public parcelForm

  dtOptions: DataTables.Settings = {};
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  cancelledState: boolean;
  showUnauthorized: boolean = false;
  @ViewChild('myDiv', { static: true }) myDiv: ElementRef;
  orderDateValidation: boolean;
  dateOfPickupCustomerValidation: boolean;
  dateOfPickupCourierValidation: boolean;
  dateOfDropValidation: boolean;
  translateValues: any[];
  errorOrderDate: any;
  errorDateofpickup: any;
  errorOrderCourierPickup: any;
  errorCourierdelivery: any;
  nodataText: any;
  translateData: any = 'en';
  hasErrorLoading: boolean = false;
  partnerDropdown:boolean;
  statusCodeList: any = [
    "LASTMILE_DELIVERED_BY_POSTMAN",
    "FIRSTMILE_COLLECTED_BY_POSTMAN",
    "LASTMILE_COLLECTED_BY_ENDUSER",
    "FIRSTMILE_DROPPED_BY_ENDUSER",
    "LASTMILE_EXPIRED_COLLECTED_BY_POSTMAN",
    "SOFT_RESERVED",
    "LM_RESERVED",
    "FM_RESERVED",
    "FM_RESERVATION_FAILED",
    "LM_RESERVATION_FAILED",
    "FRAUD",
    "CANCELLED",
    "ABORTED",
    "LOCKER_IS_EMPTY",
    "FIRSTMILE_NOT_DROPPED_EXPIRED",
    "LASTMILE_NOT_DROPPED_EXPIRED"
  ]
  @ViewChild('orderDateFromInput') orderDateFromInput:ElementRef
  @ViewChild('orderDateToInput') orderDateToInput:ElementRef
  @ViewChild('dateOfPickupCustomerFromInput') dateOfPickupCustomerFromInput:ElementRef
  @ViewChild('dateOfPickupCustomerToInput') dateOfPickupCustomerToInput:ElementRef
  @ViewChild('dateOfPickupCourierFromInput') dateOfPickupCourierFromInput:ElementRef
  @ViewChild('dateOfPickupCourierToInput') dateOfPickupCourierToInput:ElementRef
  @ViewChild('dateOfDropFromInput') dateOfDropFromInput:ElementRef
  @ViewChild('dateOfDropToInput') dateOfDropToInput:ElementRef
  get f() {
    return this.parcelForm.controls;
  }
  constructor(
    private appService: AppService,
    private translate: TranslateService,
    private http: HttpClient,
    private ParcelService: ParcelService,
    private deliverypointsService: DeliverypointsService,
    private PartnerService: PartnerService,
    public router: Router,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private renderer:Renderer2,
    private spinner:SpinnerService,
    private userService:UserService) { }

  ngOnInit(): void {
    this.appService.languagetoggle$.subscribe((data: any) => {
      this.translateData = data || 'en'
      this.http.get(`/assets/i18n/${this.translateData}.json`).subscribe((data: any) => {
        this.nodataText = data.nodataText
        this.errorOrderDate = data.pleasefillOrderDatefromandtovalues
        this.errorDateofpickup = data.pleasefillDateofpickupcustomerfromandtovalues
        this.errorOrderCourierPickup = data.pleasefillDateCourierPickupfromandtovalues
        this.errorCourierdelivery = data.pleasefillDateCourierdeliveryfromandtovalues
        let translateArray = [data.Deliveredbypostman, data.Pickedupbypostman, data.Pickedupbycustomer, data.Deliveredbycustomer, data.Postmanexpiredpickup, data.softReserved, data.lmReserved, data.fmReserved, data.fmFailed, data.lmFailed,
          data.markedAsFraud, data.cancelled, data.aborted, data.lockerEmpty, data.FirstMilenotdroppedExpired, data.LastMilenotdroppedExpired
        ]
        this.translateValues = translateArray

        this.lastStatusdropdownList = this.statusCodeList.map((value, index) => {
          return {id:index, text: this.translateValues[index], code: value}
        })
        // this.lastStatusdropdownList = this.lastStatusdropdownList.map((obj, index) => {
        //   return { ...obj, type: this.translateValues[index], textValue:this.translateValues[index] }
        // })
        console.log(this.lastStatusdropdownList);
        this.lastStatusdropdownSettings = {
          singleSelection: false,
          idField: 'code',
          textField: 'text',
          selectAllText: data.selectAll,
          unSelectAllText: data.UnSelectAll,
          itemsShowLimit: 3,
          allowSearchFilter: true
        };
      })
    })
  
    this.parcelForm = new FormGroup({
      barcode: new FormControl('', []),
      parcelLockerName: new FormControl('', []),
      orderDate: new FormGroup({
        orderDateFrom: new FormControl('', []),
        orderDateTo: new FormControl('', []),
       },[this.dateRangeValidator]),
      orderType: new FormControl('', []),
      dateOfPickup: new FormGroup({
        dateOfPickupCustomerFrom: new FormControl('', []),
        dateOfPickupCustomerTo: new FormControl('', []),
       },[this.dateOfPickupRangeValidator]),
       dateOfDrop: new FormGroup({
        dateOfDropFrom: new FormControl('', []),
        dateOfDropTo: new FormControl('', []),
       },[this.dateOfDropRangeValidator]),
       dateOfPickupCourier: new FormGroup({
        dateOfPickupCourierFrom: new FormControl('', []),
        dateOfPickupCourierTo: new FormControl('', []),
       },[this.dateOfPickupCourierRangeValidator]),
      customerName: new FormControl('', []),
      customerEMail: new FormControl('', []),
      senderEmail: new FormControl('', []),
      courier: new FormControl('', []),
      senderName: new FormControl('', []),
      backupAttributed: new FormControl('', []),
      reservationRequest: new FormControl('true', []),
      lastStatus: new FormControl('', []),
      excludeCancelledOrders: new FormControl('true', []),
      product: new FormControl('', []),
      partnerName: new FormControl('', []),
      lockerProvider: new FormControl('', [])
    })

    this.spinner.show();
    let promiseList = [this.fetchRecords()];
    this.fetchfilter();
    let currentUserInfo = this.userService.currentUser.partner.id
    if (currentUserInfo == 1) {
      promiseList.push(this.fetchPartners());
      this.partnerDropdown = true
    } else {
      this.partnerDropdown = false
    }
     
    Promise.all(promiseList).then((values) => {
      this.spinner.hide()
    });


    this.orderTypedropdownList = [
      { id: 1, type: 'firstmile' },
      { id: 2, type: 'lastmile' }
    ];

    this.orderTypedropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'type',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };


      this.parcelLockerNamedropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'type',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        allowSearchFilter: true,
        noDataAvailablePlaceholderText: this.nodataText

      };


  }

  onItemSelect(item: any) {
  }
  onSelectAll(items: any) {
  }

  fetchRecords() {
    return new Promise((resolve, reject) => {
      let body = {
        name: undefined,
        street: undefined,
        provider: undefined,
        type: undefined,
        active: undefined,
        acCode: undefined,
        postalCode: undefined,
        lockerId: undefined,

      }
      this.loading(true)
        .then(() => this.deliverypointsService.fetchMany(body))
        .then(res => {
          this.lockerList = res.map(i => {
            i.connectivity.online = !!i.connectivity.online;
            return i;
          })
          let parcelLockerNameList = []
          for (let i = 0; i < this.lockerList?.length; i++) {
            if (this.lockerList[i].lockerName === null) {
              parcelLockerNameList[i] = { id: this.lockerList[i].acCode, type: 'Unnamed' };
            } else {
              parcelLockerNameList[i] = { id: this.lockerList[i].acCode, type: `${this.lockerList[i].lockerName}(${this.lockerList[i].acCode})` };
            }
          }
          this.parcelLockerNamedropdownList = parcelLockerNameList
        })
        .catch(ex => {
          this.toastr.error(this.translate.instant('ListinglockersFailed'), this.translate.instant('Failed'));
          console.error(ex);
        })
        .finally(() => {
          // this.spinner.hide();
          resolve(null);
        })
    })
  }

  ngAfterViewInit() {
    let divElement = this.myDiv.nativeElement;
    let divstyle = window.getComputedStyle(divElement)
    let display = divstyle?.display;
    if (display == 'none') {
      this.showUnauthorized = true
    }
  }

  /*
  * Fetch partner list
  */
  fetchPartners() {
    return new Promise((resolve, reject) => {
      this.loading(true);
      this.PartnerService.fetchPartnersList().subscribe(
        result => {
          this.partnerList = result
          let partnerNameList = []
          for (let i = 0; i < this.partnerList.length; i++) {
            if (this.partnerList[i].active === true) {

              partnerNameList[i] = { id: this.partnerList[i].id, name: `${this.partnerList[i].name}` };
            }
          }
          this.partnerNamedropdownList = partnerNameList
          this.partnerNamedropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
          };
          resolve(null);
        },err => {
          this.toastr.error(this.translate.instant(this.translate.instant('Error while fetching Partners')));
          resolve(null);
        })
    });
  }

  loading(state: boolean): Promise<any> {
    this.showSpinner = state;
    if(state === true){
      this.spinner.show();
    }else{
      this.spinner.hide();
    }
    return Promise.resolve(state)
  }

  async fetchBarcodeList() {
    let start = 0;
    let limit = 1000;
    let apiReturnData = [];
    let mergedResult = [];
    do{
      await this.ParcelService.fetchBarcodeDetailsFilter(this.validate(), limit, start)
      .then((apiRes) => apiReturnData = apiRes.body)
      .catch((err) => {
        throw err;
      })
      mergedResult.push(...apiReturnData);
      start = start+limit;
    }while (apiReturnData.length !== 0)
      return mergedResult;
  }

  fetchBarcodeFilter() {
    return new Promise((resolve,reject) => {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 50,
        destroy: true,
        search: false,
        //Sorting orderdate by recent to oldest when list is loaded.
        order: [[1, 'desc']]
      };

      this.loading(true)
        .then(async () => await this.fetchBarcodeList())
        .then(parcels => {
          this.result = parcels.map(item => {
            if (item.reservation && item.reservation.date) {
              let dateval = item.reservation.date;
              let utcEventDate = dateval.split('Z').join('');
              // converting database utc format date into current local timezone.
              let localEventDate =  moment.utc(utcEventDate).toDate();
              item.reservation.date = moment(localEventDate).format('YYYY-MM-DD HH:mm:ss');
            }
            item.flow =
              item.isFirstmile && item.isLastmile ? 'FM - LM' :
                item.isFirstmile ? 'FM' :
                  item.isLastmile ? 'LM' : null
            return item;
          })

        })
        .catch(err => {
          this.toastr.error(this.translate.instant('Error while fething Barcodes!'), this.translate.instant('Failed'));
          console.error(err);
          this.result = null;
          this.hasErrorLoading = true;
        })
        .finally(() => {
          if (this.result != null && this.result?.length >= 0) {
            this.dtTrigger.next();
          }else{
            this.result = null;
            this.dtTrigger.next();
          }
          resolve(null);
        })
      })
  }

  downloadBarcodeFilter() {
    this.loading(true)
      .then(() => this.ParcelService.downloadBarcodeDetailsFilter(this.validate()))
      .then(res => FileSaver.saveAs(res, "export_" + Date.now() + "_parcels.csv"))
      .catch(err => {
        this.toastr.error(this.translate.instant('Downloading details failed!'), this.translate.instant('Failed'));
        console.error(err);
      })
      .finally(() => this.loading(false))
  }

  validate() {
    let orderType = []
    let parcelLockerName = []
    let lastStatus = []
    let partnerName = []

    let orderTypeForm = this.parcelForm.value.orderType ? this.parcelForm.value.orderType : [];
    let parcelLockerNameForm = this.parcelForm.value.parcelLockerName ? this.parcelForm.value.parcelLockerName : [];
    let lastStatusForm = this.parcelForm.value.lastStatus ? this.parcelForm.value.lastStatus : [];
    let partnerNameForm = this.parcelForm.value.partnerName ? this.parcelForm.value.partnerName : [];
    if (this.parcelForm.value.excludeCancelledOrders == "true") {
      this.cancelledState = true
    }
    if (this.parcelForm.value.excludeCancelledOrders == "false") {
      this.cancelledState = false
    }

    let a = {
      itemBarcode: this.parcelForm.value.barcode || undefined,
      reservationDate: (this.parcelForm.controls['orderDate'].value.orderDateFrom || this.parcelForm.controls['orderDate'].value.orderDateTo) ? {
        from:this.datePipe.transform(this.parcelForm.controls['orderDate'].value.orderDateFrom,'yyyy-MM-dd'),
        to:this.datePipe.transform(this.parcelForm.controls['orderDate'].value.orderDateTo,'yyyy-MM-dd')
      } : undefined,
      orderType: orderTypeForm.length != 0 ? orderTypeForm : undefined,
      dateOfPickupCustomer: this.parcelForm.controls['dateOfPickup'].value.dateOfPickupCustomerFrom && this.parcelForm.controls['dateOfPickup'].value.dateOfPickupCustomerTo ? {
        from:this.datePipe.transform(this.parcelForm.controls['dateOfPickup'].value.dateOfPickupCustomerFrom,'yyyy-MM-dd'),
        to:this.datePipe.transform(this.parcelForm.controls['dateOfPickup'].value.dateOfPickupCustomerTo,'yyyy-MM-dd')
      } : undefined,
      dateOfDrop: this.parcelForm.controls['dateOfDrop'].value.dateOfDropFrom && this.parcelForm.controls['dateOfDrop'].value.dateOfDropTo ? {
        from: this.datePipe.transform(this.parcelForm.controls['dateOfDrop'].value.dateOfDropFrom,'yyyy-MM-dd'),
        to:this.datePipe.transform(this.parcelForm.controls['dateOfDrop'].value.dateOfDropTo,'yyyy-MM-dd')
      } : undefined,
      dateOfPickupCourier: this.parcelForm.controls['dateOfPickupCourier'].value.dateOfPickupCourierFrom && this.parcelForm.controls['dateOfPickupCourier'].value.dateOfPickupCourierTo ? {
        from: this.datePipe.transform(this.parcelForm.controls['dateOfPickupCourier'].value.dateOfPickupCourierFrom,'yyyy-MM-dd'),
        to:this.datePipe.transform( this.parcelForm.controls['dateOfPickupCourier'].value.dateOfPickupCourierTo,'yyyy-MM-dd'),
      } : undefined,
      product: this.parcelForm.value.product || undefined,
      customerName: this.parcelForm.value.customerName || undefined,
      customerEmail: this.parcelForm.value.customerEMail || undefined,
      senderEmail: this.parcelForm.value.senderEmail || undefined,
      courier: this.parcelForm.value.courier || undefined,
      senderCompanyName: this.parcelForm.value.senderName || undefined,
      backupAttributed: this.parcelForm.value.backupAttributed || undefined,
      reservationRequest: this.parcelForm.value.reservationRequest || undefined,
      lastStatus: lastStatusForm.length != 0 ? lastStatusForm : undefined,
      excludeCancelledOrders: this.cancelledState || undefined,
      lockerProvider: this.parcelForm.value.lockerProvider || undefined

    }
    if (orderTypeForm.length != 0) {
      for (let i = 0; i < orderTypeForm.length; i++) {
        orderType.push(orderTypeForm[i].type)
      }
      Object.assign(a, { "orderType": orderType })
    }
    if (parcelLockerNameForm.length != 0) {
      for (let i = 0; i < parcelLockerNameForm.length; i++) {
        parcelLockerName.push(parcelLockerNameForm[i].id)
      }
      Object.assign(a, { "acCode": parcelLockerName })
    }

    if (lastStatusForm.length != 0) {
      Object.assign(a, { "lastStatus": lastStatusForm.map((status) => status.code) })
    }
    if (partnerNameForm.length != 0) {
      for (let i = 0; i < partnerNameForm.length; i++) {
        partnerName.push(partnerNameForm[i].id)
      }
      Object.assign(a, { "ddspartnerId": partnerName })
    }
    return a
  }

  async onSubmit() {
    if (!this.parcelForm.valid) {
      return;
    }
    if (this.datefieldValidating()) {
      this.ParcelService.setFilterState(this.parcelForm.value),
      this.result = [];
      this.hasErrorLoading = false; //reset flag

      this.rerender(); // re-render data table with new values
      this.fetchfilter();
      // await this.fetchBarcodeFilter().then(() => {
      //   this.spinner.hide();
      // });
    } 
  }
  datefieldValidating() {
    if ((!this.parcelForm.controls['orderDate'].value.orderDateFrom && !this.parcelForm.controls['orderDate'].value.orderDateTo)
      || (this.parcelForm.controls['orderDate'].value.orderDateFrom && this.parcelForm.controls['orderDate'].value.orderDateTo)) {
      this.orderDateValidation = true
    } else {
      this.orderDateValidation = false
      //  this.toastr.error('please fill "Order Date" from and to values')
      this.toastr.error(this.errorOrderDate)
    }
    if ((!this.parcelForm.controls['dateOfPickup'].value.dateOfPickupCustomerFrom && !this.parcelForm.controls['dateOfPickup'].value.dateOfPickupCustomerTo)
      || (this.parcelForm.controls['dateOfPickup'].value.dateOfPickupCustomerFrom && this.parcelForm.controls['dateOfPickup'].value.dateOfPickupCustomerTo)) {
      this.dateOfPickupCustomerValidation = true
    } else {
      this.dateOfPickupCustomerValidation = false
      // this.toastr.error('please fill "Date of pickup customer" from and to values')
      this.toastr.error(this.errorDateofpickup)
    }
    if ((!this.parcelForm.controls['dateOfPickupCourier'].value.dateOfPickupCourierFrom && !this.parcelForm.controls['dateOfPickupCourier'].value.dateOfPickupCourierTo)
      || (this.parcelForm.controls['dateOfPickupCourier'].value.dateOfPickupCourierFrom  && this.parcelForm.controls['dateOfPickupCourier'].value.dateOfPickupCourierTo)) {
      this.dateOfPickupCourierValidation = true
    } else {
      this.dateOfPickupCourierValidation = false
      //  this.toastr.error('please fill "Date Courier Pickup" from and to values')
      this.toastr.error(this.errorOrderCourierPickup)
    }
    if ((!this.parcelForm.controls['dateOfDrop'].value.dateOfDropFrom && !this.parcelForm.controls['dateOfDrop'].value.dateOfDropTo)
      || (this.parcelForm.controls['dateOfDrop'].value.dateOfDropFrom && this.parcelForm.controls['dateOfDrop'].value.dateOfDropTo)) {
      this.dateOfDropValidation = true
    } else {
      this.dateOfDropValidation = false
      // this.toastr.error('please fill "Date Courier delivery" from and to values')
      this.toastr.error(this.errorCourierdelivery)
    }
    if(this.orderDateValidation && this.dateOfPickupCustomerValidation
       &&  this.dateOfPickupCourierValidation &&  this.dateOfDropValidation){
     return true
    }
    else{
      return false
    }
  }
  fetchfilter(){

    let lastPage=0;  
    
       this.loading(true)
      
        this.dtOptions = {
          retrieve: true,
          destroy: true,
          pagingType: 'full_numbers',
          pageLength: 50,
          displayStart: lastPage,
          search: false,
          serverSide: true,
          order: [[1, 'desc']],
          ordering: false,
          ajax: (dataTablesParameters: any, callback) => {
            lastPage=dataTablesParameters.start
            this.ParcelService.fetchBarcodeDetailsFilter(this.validate(),dataTablesParameters.length,dataTablesParameters.start).then((resp)=>{
              const header = resp.headers.get('X-Total-Count');
     
               this.loading(false);
               if(resp.body.length > 0){
                this.result = resp.body.map(item => {
                  if (item.reservation && item.reservation.date) {
                    let dateval = item.reservation.date;
                    item.reservation.date = dateval.split('Z').join('');
                  }
                  item.flow =
                    item.isFirstmile && item.isLastmile ? 'FM - LM' :
                      item.isFirstmile ? 'FM' :
                        item.isLastmile ? 'LM' : null
                  return item;
                })
               }
            else{
              this.result=[]
            }
                callback({
                  recordsTotal: header,
                  recordsFiltered: header,
                  data:[]
              });
          });
          }
         
        }
      
       
      // })
    
      // .catch(err => {
      //   this.toastr.error(this.translate.instant('ErrordownloadingthefileFailed'), this.translate.instant('Failed'));
      //   console.error(err);
      // })
      // .finally(() => this.loading(false))
  }


  exportToCSV() {
    this.loading(true)

      .then(async () => await this.fetchBarcodeList())
      .then((res: any) => {
        res.map(item => {
          item.flow =
            item.isFirstmile && item.isLastmile ? 'FM - LM' :
              item.isFirstmile ? 'FM' :
                item.isLastmile ? 'LM' : null;
          item.product = item.product == null ? "-" : item.product;
          item.physicalStatus.date = item.physicalStatus.date == null ? "-" : item.physicalStatus.date;
          item.physicalStatus.eventCode = item.physicalStatus.eventCode == null ? "-" : item.physicalStatus.eventCode;
          item.physicalStatus.eventSubCode = item.physicalStatus.eventSubCode == null ? "-" : item.physicalStatus.eventSubCode;
          return item;
        })
        const csvData = this.formatDataForCSV(res)
        const Today = this.datePipe.transform(new Date(),'dd-MM-yyyy')
        let downtimeReport = 'Parcels_'+Today+'.csv'
        this.downloadCSV(csvData, downtimeReport)
      })
      .catch(err => {
        this.toastr.error(this.translate.instant('ErrordownloadingthefileFailed'), this.translate.instant('Failed'));
        console.error(err);
      })
      .finally(() => this.loading(false))
  }
  formatDataForCSV(data: any[]) {
    const flattenData = data.map((items) => {
      let backupAttributToParcel
      let reservationRequestSuccessful
      if (items.deliveryPoint.type === 'BackupPoint') {
        backupAttributToParcel = items.reservation.backupAcCode
      }

      if (items.reservation.isReserved == true || items.reservation.isReservedFirstMile == true) {
        reservationRequestSuccessful = "Successful"
      }

      if (items.reservation.isReserved == false && items.reservation.isReservedFirstMile == false && items.reservation.reservationAttempted == true) {
        reservationRequestSuccessful = "Unsuccessful"
      }

      let doorNumber = items.dds.compartmentNumber ? items.dds.compartmentNumber : null
      let partnerName;
      if (items.dds.partrner?.id) {

        switch (items.dds.partrner.id) {
          case 1:
            partnerName = 'Bpost';
            break;
          case 2:
            partnerName = 'GLS Group';
            break;
          case 3:
            partnerName = 'DHL';
            break;
          case 4:
            partnerName = 'SLS Cargo';
            break;
          default:
            partnerName = '-'
        }

      }
      return [
        items.barcode,
        (items.reservation.date != null) ? (moment(items.reservation.date).format('YYYY-MM-DD')) : (null),
        (items.reservation.date != null) ? (moment(items.reservation.date).format('HH:mm:ss')) : (null),
        items.deliveryPoint.name,
        (items.physicalStatus.date != null) ? (moment(items.physicalStatus.date).format('YYYY-MM-DD')) : (null),
        (items.physicalStatus.date != null) ? (moment(items.physicalStatus.date).format('HH:mm:ss')) : (null),
        items.physicalStatus.eventCode,
        items.customerEmail,
        items.customerFirstName,
        items.customerPhone,
        items.reservation.type,
        // items.deliveryPoint.provider,
        partnerName,
        items.sender.senderName,
        items.deliveryPoint.deliverypointId,
        backupAttributToParcel,
        reservationRequestSuccessful,
        (items.physicalStatus.pickedAt != null) ? (moment(items.physicalStatus.pickedAt).format('YYYY-MM-DD')) : (null),
        (items.physicalStatus.pickedAt != null) ? (moment(items.physicalStatus.pickedAt).format('HH:mm:ss')) : (null),
        (items.physicalStatus.loadedAt != null) ? (moment(items.physicalStatus.loadedAt).format('YYYY-MM-DD')):(null),
        (items.physicalStatus.loadedAt != null) ? (moment(items.physicalStatus.loadedAt).format('HH:mm:ss')):(null),
        doorNumber

      ];
    });
    const headers = ['Barcode', 'Order Date', 'Order Time', 'Parcel Locker Name', 'Date of last status', 'Time of last status', 'Last status', 'Customer E-mail', 'Customer Name', 'Customer Phone number', 'Order type', 'Courier', 'Sender Name', 'Location ID', 'Backup attributed to parcel', 'Reservation Request Successful', 'Date of pickup', 'Time of pickup', 'Date of drop', 'Time of drop', 'Door Number']
    const csvContent = Papa.unparse({ fields: headers, data: flattenData }, { quotes: true });
    return csvContent;
  }
  downloadCSV(data: string, filename: string) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  }
  goToParcelDetails(id: any) {
    this.router.navigate(['/parcels/' + id]);
  }

  onReset() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
    this.ParcelService.setFilterState(null)
  }

  rerender(): void {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        // dtInstance.state.loaded();
        dtInstance.ajax.reload();
        dtInstance.on( 'draw.dt', () => {
          if($('.dataTables_empty').length > 0)
            {
            if(this.hasErrorLoading){
              $('.dataTables_empty').text('Error in loading data');
              this.showDownload = false;
            }else if(this.result?.length == 0){
              $('.dataTables_empty').text('No records found!');
              this.showDownload = false;
            }
          } else {
            this.showDownload=true;
          }
        });
      });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  getetrackerUrl = (barcode: any) => {
    return `https://etracker-ac2.bpost.cloud/internal/shipment?barcode=${barcode}&lang=${this.translateData}&country=BE`
  }
  openDatePicker(v){
    if(v=='orderDateFromInput')
     this.renderer.selectRootElement(this.orderDateFromInput.nativeElement).click()
     if(v=='orderDateToInput')
     this.renderer.selectRootElement(this.orderDateToInput.nativeElement).click()
    if(v=='dateOfPickupCustomerFromInput')
    this.renderer.selectRootElement(this.dateOfPickupCustomerFromInput.nativeElement).click()
    if(v=='dateOfPickupCustomerToInput')
    this.renderer.selectRootElement(this.dateOfPickupCustomerToInput.nativeElement).click()
    if(v=='dateOfPickupCourierFromInput')
    this.renderer.selectRootElement(this.dateOfPickupCourierFromInput.nativeElement).click()
    if(v=='dateOfPickupCourierToInput')
    this.renderer.selectRootElement(this.dateOfPickupCourierToInput.nativeElement).click()
    if(v=='dateOfDropFromInput')
    this.renderer.selectRootElement(this.dateOfDropFromInput.nativeElement).click()
    if(v=='dateOfDropToInput')
    this.renderer.selectRootElement(this.dateOfDropToInput.nativeElement).click()
  }
  private dateRangeValidator: ValidatorFn = (control: AbstractControl): {
    [key: string]: any;
  } | null => {
      if(!this.parcelForm)
      {
        return null;
      }
      let invalid = false;
      const from =control.value.orderDateFrom;
      const to =control.value.orderDateTo;
      if (from && to) {
        invalid = new Date(from).valueOf() > new Date(to).valueOf();
      }
      return invalid ? { invalidRange: { from, to } } : null;
  };

  private dateOfPickupRangeValidator: ValidatorFn = (control: AbstractControl): {
    [key: string]: any;
  } | null => {
      if(!this.parcelForm)
      {
        return null;
      }
      let invalid = false;
      const from =control.value.dateOfPickupCustomerFrom;
      const to =control.value.dateOfPickupCustomerTo;
      if (from && to) {
        invalid = new Date(from).valueOf() > new Date(to).valueOf();
      }
      return invalid ? { invalidRange: { from, to } } : null;
  };

  private dateOfDropRangeValidator: ValidatorFn = (control: AbstractControl): {
    [key: string]: any;
  } | null => {
      if(!this.parcelForm)
      {
        return null;
      }
      let invalid = false;
      const from =control.value.dateOfDropFrom;
      const to =control.value.dateOfDropTo;
      if (from && to) {
        invalid = new Date(from).valueOf() > new Date(to).valueOf();
      }
      return invalid ? { invalidRange: { from, to } } : null;
  };

  private dateOfPickupCourierRangeValidator: ValidatorFn = (control: AbstractControl): {
    [key: string]: any;
  } | null => {
      if(!this.parcelForm)
      {
        return null;
      }
      let invalid = false;
      const from =control.value.dateOfPickupCourierFrom;
      const to =control.value.dateOfPickupCourierTo;
      if (from && to) {
        invalid = new Date(from).valueOf() > new Date(to).valueOf();
      }
      return invalid ? { invalidRange: { from, to } } : null;
  };

  checkParcelFilter(){
    return new Promise((resolve,reject)=>{
    let filterState = this.ParcelService.getFilterState()
    if(filterState){
      this.parcelForm.patchValue(filterState)
      this.orderTypeselectedItems = filterState.orderType
      this.partnerNameselectedItems = filterState.partnerName
      this.lastStatusselectedItems = filterState.lastStatus
      this.parcelLockerNameselectedItems = filterState.parcelLockerName
      resolve(null)
    }
    resolve(null)
  })
  }
}
