import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { ToastrService } from 'ngx-toastr';
import { PartnerService } from '../../../Service/partner.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-partner-edit-postman-codes',
  templateUrl: './partner-edit-postman-codes.component.html',
  styleUrls: ['./partner-edit-postman-codes.component.scss']
})
export class PartnerEditPostmanCodesComponent implements OnInit {

  showSpinner: boolean = false;
  partnerDetails: any;
  partnerId: any

  settingForm: FormGroup;

  settingName: string = 'postmans-codes-method'

  constructor(public activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private partnerService: PartnerService,
    private router: Router,
    private formBuilder: FormBuilder,
    private translate: TranslateService
  ) {
    this.settingForm = this.formBuilder.group({
      method: ['standard', Validators.required],
    })
  }

  ngOnInit(): void {
    let partnerId = this.activatedRoute.snapshot.params.id;
    this.partnerId = partnerId
    this.getPartnerDetails(partnerId);
  }

  //Fech partner details
  getPartnerDetails(partnerId) {
    this.showSpinner = true;
    this.partnerService.fetchPartnerDetails(partnerId).subscribe(
      res => {
        this.partnerDetails = res

        // Current setting value
        const currentSetting = this.partnerDetails.settings.find(s => s.key === this.settingName)
        if (currentSetting) {
          this.settingForm.patchValue({
            method: currentSetting.value
          })
        }

        this.showSpinner = false;
      },
      err => {
        this.showSpinner = false;
        this.toastr.error(this.translate.instant('Failed to fetch partner details !!'), this.translate.instant('Failed'));
        console.error(err);
      }
    )
  }

  onSubmit() {
    if (this.settingForm.valid) {
      this.partnerService.editPartnerSetting(this.partnerId, this.settingName, {
        method: this.settingForm.controls.method.value
      }).subscribe((res) => {
        if (res) {
          this.toastr.success(this.translate.instant('Partner Updated Successfully!'), this.translate.instant('Success'))
        }
      }, (err: any) => {
        console.error(err);
        this.toastr.info(err.error.error.message, this.translate.instant('Failed'))
      })
    }
  }

  goToPartnerDetails() {
    this.router.navigate(['/partners/', this.partnerId]);
  }
}
