<div class="mx-5 mt-5">
    <h2 style="color: red;" >{{'Orders to process'|translate}}</h2>

       <div class="mt-5">
        <div class="table-responsive">
        <table class="table table-bordered">
          <caption></caption>
            <thead>
              <tr>
                <th scope="col"  colspan="6">{{'Orders to process'|translate}}</th>

              </tr>
            </thead>
            <thead>
                <tr class="table-info">
                    <th scope="col" >{{'Name'|translate}}</th>
                    <th scope="col" >{{'Barcode'|translate}}</th>
                    <th scope="col" >{{'Status'|translate}}</th>
                  <th scope="col" >{{'When to collect'|translate}}</th>
                  <th scope="col" >{{'timeFilled'|translate}}</th>

                  <th scope="col" >{{'Options'|translate}}</th>
                </tr>
              </thead>
            <tbody>
              <tr *ngFor="let value of orderToProcess; let i = index">
                <td>Anonymous, anonymous customer</td>
                <td>{{value.barcode}}</td>
                <td>{{'Safe Reserved'|translate}}</td>
                <td>{{value.reservePeriodFrom | date:'dd-MM-yyyy'}} - {{value.reservePeriodTo | date:'dd-MM-yyyy'}}</td>
                <td>{{value.loadedAt|date:'dd-MM-yyyy HH:mm:ss'}}</td>

                <td><button class="btn btn-danger mr-2" (click)="viewPage(value)" >{{'View'|translate}}</button>
                  <button class="btn btn-danger" data-toggle="modal" data-target="#deleteOrderModal" >{{'Remove'|translate}}</button></td>

                <!-- api here -->
              <div class="modal fade" tabindex="-1" id="deleteOrderModal" role="dialog" aria-labelledby="deleteOrderModal"
                aria-hidden="true">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h3 class="modal-title">{{'Delete'| translate}}</h3>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <p>{{'Are you sure you want to delete order'| translate}}</p>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                      <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="removeOrder(value)">Confirm</button>
                    </div>
                  </div>
                </div>
            </div>
              </tr>
              <tr *ngIf="!orderFound">
                <td style="text-align: center;" colspan="6">
                  {{'No Records Found'|translate}}
                </td>
              </tr>

            </tbody>
          </table>
          </div>


    </div>

</div>

