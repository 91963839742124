<app-header></app-header>
<div
  class="col-12 d-flex justify-content-end">
    <button
      type="button"
      class="btn btn-danger mr-3 mt-2"
      [appViewController]="{staff:'createStaff'}"
      (click)="addMaintenanceUser()">{{'Add' |translate}}</button>
</div>
<br>
<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="table-responsive">
        <table class="table table-sm table-bordered table-hover row-border hover" style="text-align:center;">
          <caption></caption>
          <div class="text-center">
          </div>
          <thead class="thead-dark">
            <tr>
              <th scope="col">{{'Name' |translate}}</th>
              <th scope="col">{{'Login ID' |translate}}</th>
              <th scope="col">{{'Last logged-in time' |translate}}</th>
              <th scope="col">{{'Capabilities' |translate}}</th>
              <th scope="col">{{'Action' | translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let partner of partnerList">
              <td>{{partner.name}}</td>
              <td>{{partner.loginId}}</td>
              <td *ngIf="partner.lastLoginTime">{{partner.lastLoginTime | date:'YYYY-MM-dd HH:mm:ss'}}</td>
              <td *ngIf ="!partner.lastLoginTime" style="text-align: center">-</td>
              <td>{{partner.capabilities}}</td>
              <td>
                <button [appViewController]="{staff:'editStaff'}" type="button" class="btn btn-danger mr-3 mt-2 custedit" (click)="onEdit(partner)">{{'Edit' |translate}}</button>
                <button [appViewController]="{staff:'deleteStaff'}" data-toggle="modal" data-target="#deleteUserModal" type="button"
                  class="btn btn-danger mr-3 mt-2 text-align-right" data-toggle="modal"
                  (click)="getModalId(partner.id,partner.name)">{{'Delete' |translate}}</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="modal fade" tabindex="-1" id="deleteUserModal" role="dialog" aria-labelledby="deleteUserModal"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{'Delete' |translate}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>{{'Are you sure you want to delete the staff user name' |translate}} {{partnerName}} ?</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'Cancel' |translate}}</button>
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="onDelete()">{{'Confirm' |translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
 