<!-- <app-loader *ngIf="!dpdetails"></app-loader> -->
 <app-header></app-header>
<app-lockers-menu [id]="id"></app-lockers-menu>
<div #myDiv [appViewController]="{deliveryPoints:'showTerminals'}">
  <div class="box collapse" id="collapseLayout" [appViewController]="{deliveryPoints:'displayLayout'}">
    <div class="container-fluid">
      <div class="row d-flex justify-content-center">
        <div class="col-12">
          <app-lockers-layout
            *ngIf="dpdetails?.type == 'Lean Locker' &&  dpdetails?.terminals"
            [terminals]="nestedArr">
          </app-lockers-layout>
        </div>
      </div>
    </div>
  </div>

  <div [appViewController]="{deliveryPoints:'displayLayout'}" *ngIf="dpdetails && dpdetails.type !='Cubee Locker'" class="bg-info">
    <nav class="navbar navbar-expand-lg navbar-dark">
      <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
        <div class="details btn center mx-auto text-light" type="button" data-toggle="collapse"
          data-target="#collapseLayout" aria-expanded="false" aria-controls="collapseLayout">
          <span *ngIf="!layoutAvailable">
            {{'Layout not available'|translate}}</span>
          <span *ngIf="layoutAvailable">
            {{'Show layout'|translate}}</span>
          <div class="fa fa-chevron-down fa-lg text-white"></div>
        </div>
      </div>
    </nav>
  </div>
 
  <div *ngIf="dpdetails" class="container-fluid mt-3">
    <div class="row">
      <div class="col-12">
        <div class="row mb-3">
          <div class="col-md-4">
            <div class="h4">{{dpdetails?.lockerName}} </div>
          </div>
          <div class="col-md-8 col-sm-4 d-flex" style="padding-top:0%;justify-content:end;">
            <div class="h4">
              <button (click)="reloadCurrentPage()">{{'Reload'|translate}}</button>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-4">
            <div _ngcontent-wai-c50="" class="head-name" style="font-size: 14px;">{{dpdetails?.provider}} </div>
            <div _ngcontent-wai-c50="" class="head-name" style="font-size: 14px;">{{dpdetails?.type}}</div>
            <div _ngcontent-wai-c50="" class="head-name">
              <label class="mb-0" style="font-size: 14px;">Address:</label>
              {{dpdetails?.address.streetNumber}} {{dpdetails?.address.street}} {{dpdetails?.address.municipality}}
              {{dpdetails?.address.postalCode}}
            </div>
          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="text-center col">
                <div class="stats"> {{dpdetails?.stats.compartments}}
                  <span class="d-block small">{{'Compartments'|translate}}</span>
                </div>
              </div>
              <div class="text-center col">
                <div class="stats">{{reservationsCount}}
                  <span class="d-block small">{{'Reservation'|translate}}</span>
                </div>
              </div>
              <!-- <div class="text-center col">
                <div class="stats">{{parcelsCount}}
                  <span class="d-block small">{{'Parcels'|translate}}</span>
                </div>
              </div> -->
              <div class="text-center col">
                <div class="stats">{{pickupCount}}
                  <span class="d-block small">{{'pickup_locker_filled'|translate}}</span>
                </div>
              </div>
              <div class="text-center col">
                <div class="stats">{{deliveryCount}}
                  <span class="d-block small">{{'delivery_locker_filled'|translate}}</span>
                </div>
              </div>
              <div class="text-center col">
                <div class="stats">{{dpdetails?.stats.disabled}}
                  <span class="d-block small">{{'Disabled'|translate}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div *ngFor="let ter of terminalArr" class="table-responsive">
            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
              class="table table-sm table-bordered table-hover row-border hover" style="text-align:center;">
              <caption></caption>
              <thead class="thead-dark">
                <tr>
                  <!-- <th scope="col">{{'Terminal Id'|translate}}</th> -->
                  <!-- <th scope="col">External Id</th> -->
                  <th (click)="sort('number')" scope="col">{{'Compartment Id'|translate}}<i class="fa fa-sort"></i></th>
                  <th scope="col">{{'Dimension'|translate}}</th>
                  <th (click)="sort('size')" scope="col">{{'Size'|translate}}<i class="fa fa-sort"></i></th>
                  <th scope="col">{{'Status'|translate}}</th>
                  <th scope="col">{{'Barcode'|translate}}</th>
                  <th scope="col">{{'Status At'|translate}}</th>
                  <th scope="col">{{'Command'|translate}}</th>
                  <th scope="col">{{'Action'|translate}}</th>
                  <th scope="col">{{'Remarks'|translate}}</th>
                </tr>
              </thead>
              <tbody *ngFor="let com of ter?.compartments | orderBy:key:reverse">
                <!-- <tr [ngClass]="com.isOpen || !com.active ? 'table-danger' : 'white-class'"> -->
                <tr>
                  <!-- <td>{{ter.id}}</td> -->
                  <!-- <td>{{ter.externalId}}</td> -->
                  <td>{{com.number}}</td>
                  <td>{{com.height}} x {{com.width}} x {{com.depth}}</td>
                  <td>{{com.size}}</td>
                  <!-- <td *ngIf="com.loaded && com.parcelid">Loaded</td>
                <td *ngIf="!com.loaded && com.parcelid">Reserved</td>
                <td *ngIf="!com.loaded && !com.parcelid">-</td>
                <td *ngIf ="com.loaded && !com.parcelid">-</td>
                <td *ngIf="com.parcel != null"> <a [routerLink]="['/parcels/', com.parcel.id]">{{com.parcel.barcode}}</a>
                </td> -->
                  <td>{{com.status || '-'}}</td>
                  <!-- <td *ngIf="com.status == null">-</td> -->
                  <!-- <td *ngIf="com.itemBarcode && com?.parcelid"><a [routerLink]="['/parcels/', com?.parcel?.id]">{{com.itemBarcode}}</a>
                  </td> -->

                  <!--custom impl for lean lockers-->
                  <td><a [routerLink]="['/parcels/', com?.parcel?.id]">{{com.itemBarcode || '-'}}</a></td>
                  <!-- <td *ngIf="com?.parcelid == null || !com.itemBarcode">-</td> -->
                  <!-- {{(com.statusAt).replace("T",' ').replace(/\.\d+Z$/,'')}} -->
                  <!-- {{timeFormat(com.statusAt)}} -->
                  <td>{{com.statusAt | utctolocaldate:'DD-MM-YYYY HH:mm:ss' || '-'}}</td>
                  <!-- <td *ngIf="com.statusAt == null">-</td> -->
                  <td>
                    <div [appViewController]="{deliveryPoints:'allowCommand'}">
                      <div class="col" style="display: flex;" *ngIf="dpdetails.type == 'Lean Locker'">
                        <button type="button" style="margin-right:5px!important"
                          class="p-2 mx-auto text-dark btn btn-light " class="btn btn-success btn-sm"
                          [disabled]="dpdetails.type == 'Lean Locker'"
                          (click)="updateStatus(id,1,ter.id,com.number)">{{'OPEN'|translate}}</button>
                        <!-- <button type="button" style="margin-left:5px!important"
                          class="p-2 mx-auto text-dark btn btn-light" class="btn btn-danger btn-sm"
                          [disabled]="dpdetails.type == 'Lean Locker'"
                          (click)="updateStatus(id,2,ter.id,com.number)">{{'CLOSE'|translate}}</button> -->
                      </div>
                      <div class="col" style="display: flex;" *ngIf="dpdetails.type != 'Lean Locker'">
                        <button type="button" style="margin-right:5px!important; margin-top: 0.5px"
                          class="p-2 mx-auto text-dark btn btn-light" class="btn btn-success btn-sm"
                          (click)="updateStatus(id,1,ter.id,com.number)"
                          [disabled]="com.isOpen">{{'OPEN'|translate}}</button>
                        <!-- <button type="button" style="margin-left:5px!important; margin-top: 0.5px"
                          class="p-2 mx-auto text-dark btn btn-light" class="btn btn-danger btn-sm"
                          (click)="updateStatus(id,2,ter.id,com.number)"
                          [disabled]="!com.isOpen ">{{'CLOSE'|translate}}</button> -->
                      </div>
                    </div>
                  </td>
                  <!-- <td *ngIf="dpdetails.type == 'Lean Locker'">-</td> -->
                  <td>
                    <div [appViewController]="{deliveryPoints:'allowAction'}">
                      <div class="col" style="display: flex;">
                        <button type="button" style="margin-right:5px!important; margin-top: 0.5px;"
                          class="p-2 mx-auto text-dark btn btn-light" class="btn btn-success btn-sm"
                          (click)="updateStatus(id,3,ter.id,com.number,ter.externalId)"
                          [disabled]="com.active">{{'Enable'|translate}}</button>
                        <button type="button" style="margin-left:5px!important; margin-top: 0.5px"
                          class="p-2 mx-auto text-dark btn btn-light" class="btn btn-danger btn-sm"
                          (click)="disabledValues(id,ter.id,com.number,ter.externalId)" data-target="#disablepopup"
                          data-toggle="modal" [disabled]="!com.active">{{'Disable'|translate}}</button>
                      </div>
                    </div>
                  </td>
                  <!-- <td *ngIf="com.remarks == null">-</td> -->
                  <td *ngIf="com.remarks != null && com.remarks != '' ">{{com.remarks}}</td>
                  <td *ngIf="com.remarks == null && (com.isOpen == true && com.active == true)">Open</td>
                  <td *ngIf="(com.remarks == '' || com.remarks == null) && (com.isOpen == false && com.active == false)">Non-Active</td>
                  <td *ngIf="com.remarks == null && (com.isOpen == false && com.active == true)">-</td>
                  <!-- <td *ngIf="dpdetails.type == 'Lean Locker'">-</td> -->

                  <!--Disable popup-->

                  <div class="modal" id="disablepopup">
                    <div class="modal-dialog">
                      <div class="modal-content">

                        <div class="modal-header">
                          <h4></h4>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span></button>
                        </div>

                        <div class="modal-body">
                          <p style="text-align: left;">{{'Change the status of vault below and enter a reason when you
                            Deactivate them' | translate}}</p>
                          <textarea class="textreason" autofocus id="disable-reason" rows="2" cols="50"
                            [(ngModel)]="disableComment" style="margin-right: 40px;" maxlength="50"></textarea>
                        </div>

                        <div class="modal-footer">
                          <button type="button" class="activebtn" data-dismiss="modal"
                            (click)="updateStatus(disabledCompartment.dp,4,disabledCompartment.term,disabledCompartment.comp,disabledCompartment.ext_id)">{{'Deactivate'|translate}}</button>
                          <button type="button" class="activebtn" data-dismiss="modal">{{'Cancel'| translate}}</button>
                        </div>
                      </div>
                    </div>
                  </div>


                </tr>
              </tbody>
            </table>
            <br>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-un-authorizedpage *ngIf="showUnauthorized"></app-un-authorizedpage>
