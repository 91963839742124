<app-header></app-header>
<div class="container">
    <br />
    <div class="row">
        <div class="col-3">
            <app-partner-info-menu [partner]=partnerDetails></app-partner-info-menu>
        </div>
        <div class="col-9">
            <app-partner-navigation-menu [partner]=partnerDetails></app-partner-navigation-menu>
            <button class="btn btn-secondary  mr-1" (click)="goToPartnerList()">{{'Back'|translate}}</button>&nbsp;
        </div>

    </div>
</div>
