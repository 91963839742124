import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PartnerService } from 'src/app/Service/partner.service';
import { LangtransService } from 'src/app/Service/langtrans.service';
import { AppService } from 'src/app/Service/app.service';

@Component({
  selector: 'app-ironing-barcode',
  templateUrl: './ironing-barcode.component.html',
  styleUrls: ['./ironing-barcode.component.scss']
})
export class IroningBarcodeComponent implements OnInit {
  barcodeForm:FormGroup
  submitted: boolean;
  translateData: any;
  constructor(private _formBuilder: FormBuilder,
    public router: Router,
    private partnerService: PartnerService,
    private toastr: ToastrService,
    private langtransService: LangtransService,
    private appService: AppService,
  ) {

  this.barcodeForm =this._formBuilder.group({
    barcode:['',[Validators.required,Validators.pattern(/^JJ(MD|LG|HG)U\d+U\d{1}$/)]]
  })
  }

  ngOnInit(): void {
    this.appService.languagetoggle$.subscribe((data: any) => {
      this.translateData = data
    })
  }
  get formValidators() {
    return this.barcodeForm.controls
  }
  submit(){
    this.submitted=true
    if(this.barcodeForm.valid){
      // this.router.navigate(['partnertools/partnerOrder/','add',this.barcodeForm.controls.barcode.value])
      this.extractValue(this.barcodeForm.controls.barcode.value)
    }
  }
  extractValue(barcode) {
    const regex = /U(.*?)U/
    let codeFetch = barcode.match(regex)
    this.partnerService.getPartnerCustomer(codeFetch[1]).subscribe((res: any) => {
      if (res.length > 0) {
        this.partnerService.setEmail(res[0]?.Email)
        this.router.navigate(['partnertools/partnerOrder/', 'add', this.barcodeForm.controls.barcode.value])
      }
    },
      (err) => {
        if (err.error.code) {
          this.langaugeFetch(this.translateData, err.error.message)
          this.router.navigate(['partnertools/createBarcode'])
        }
      })
  }

  langaugeFetch(lang, key) {
    this.langtransService.TranslateData(lang, key).subscribe(
      res => {
        this.toastr.error(res)
      }
    )
  }
}
