<app-header></app-header>
<nav class="navbar shadow navbar-expand-lg navbar-light bg-light">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mx-auto" style="cursor:pointer">
            <li class="nav-item mx-2" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <a class="nav-link" [routerLink]="['/partnertools']">
                    <i class="fa fa-home fa-2x" ></i>
                </a>
              </li>
              <li class="nav-item mx-2" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/partnertools/newOrder']">{{'New Orders'|translate}}</a>
              </li>
              <li class="nav-item mx-2" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/partnertools/ordersToProcess']" >{{'Orders to be processed'|translate}}</a>
              </li>
              <li class="nav-item mx-2" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/partnertools/ordersPlacedInVault']" >{{'Orders placed in Locker'|translate}}</a>
              </li>
              <li class="nav-item mx-2" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/partnertools/history']" >{{'History'|translate}}</a>
              </li>
              <li class="nav-item mx-2" routerLinkActive="active" *ngIf = "isJJCustomer">
                <a class="nav-link" [routerLink]="['/partnertools/customer']" >{{'Customers'| translate}}</a>
              </li>
              <li class="nav-item mx-2" routerLinkActive="active" >
                <a class="nav-link" [routerLink]="['/partnertools/settings']">
                    <i class="fa fa-cog fa-2x" ></i>
                </a>
              </li>

            </ul>
      </div>
</nav>
<!-- <app-partner-home *ngIf="showHome"></app-partner-home> -->
<router-outlet></router-outlet>
