import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-un-authorizedpage',
  templateUrl: './un-authorizedpage.component.html',
  styleUrls: ['./un-authorizedpage.component.scss']
})
export class UnAuthorizedpageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
