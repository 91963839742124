import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AppService } from '../../../Service/app.service';
import { FormGroup, FormControl } from '@angular/forms';
import { DeliverypointsService } from '../../../Service/deliverypoints.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import * as FileSaver from 'file-saver';
import { PartnerService } from '../../../Service/partner.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import *as Papa from 'papaparse'
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import * as momenttimezone from 'moment-timezone'
import { LangtransService } from 'src/app/Service/langtrans.service';
import { HttpClient } from '@angular/common/http';
import { utcFormatter } from '../../timeFormatter';
import { SpinnerService } from 'src/app/Service/spinner.service';
import { UserService } from 'src/app/Service/user.service';
@Component({
  selector: 'app-lockerlist',
  templateUrl: './lockerlist.component.html',
  styleUrls: ['./lockerlist.component.scss']
})
export class LockerlistComponent implements OnDestroy, OnInit {
  resultsingle: any
  showSpinner: boolean = false
  showDownload: boolean = true;
  result: any = []
  commissioned: any
  lockerdetails: any
  notCommissioned: any
  online: any
  offline: any
  dashBoard: any
  dashBoardError: boolean
  lockername: string
  street: string
  lockerprovider: string
  lockertype: string
  postalCode: string
  acCode: string
  active: string
  lockerid: string
  public filterForm
  public lockerDetailsForm
  partnerNamedropdownList = [];
  partnerList: any
  partnerNamedropdownSettings: IDropdownSettings;
  acCodedropdownSettings: IDropdownSettings;
  acCodedropdownList = [];
  acCodeselectedItems= [];
  partnerNameselectedItems = [];
  dtOptions: DataTables.Settings = {};
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  results: any = [];
  formattedTime: string;
  timeDifference: number = 30;
  isAlert: boolean;
  timeDiffmin: number;
  showUnauthorized: boolean = false;
  @ViewChild('myDiv', { static: true }) myDiv: ElementRef;
  lockerFetchfail: any;
  translateData: any;
  @ViewChild('holidayDateInput') holidayDateInput: ElementRef;
  headerVariables: any[];
  hasErrorLoading: boolean = false;
  partnerDropdown: boolean
  nodataText: any;
  constructor(
    private http: HttpClient,
    private langtransService: LangtransService,
    private appService: AppService,
    private deliverypointsService: DeliverypointsService,
    public router: Router,
    private toastr: ToastrService,
    private PartnerService: PartnerService,
    private datePipe: DatePipe,
    private renderer: Renderer2,
    private spinner: SpinnerService,
    private userService: UserService
  ) {
  }

  ngOnInit() {
    $('.details').click(function () {
      $(this).find('i').toggleClass('fa fa-chevron-down fa fa-chevron-up');
    });
    this.appService.languagetoggle$.subscribe((data: any) => {
      this.translateData = data
      this.headerVariables = []
      this.http.get(`/assets/i18n/${data}.json`).subscribe((data: any) => {
        this.nodataText = data.nodataText
        this.headerVariables.push(data.AcCode, data.LockerID, data.Name,
          data.Location, data.LockerType, data.Online, data.OperationalStatus,
          data.DeliveryPointId, data.IpAddress, data.Provider, data.Techology, data.LastPingTime,
          data.totalDoors, data.acceptSoftReservations, data.windBrackets, data.position, data.salesSegment,
          data.contractName, data.contractHostName, data.numberOfUnits, data.salesKeyAccount, data.operationsFieldSupport,
          data.locationDeliveryRoute, data.mailOffice, data.logisticsPlatform, data.mailCentre, data.windDirection, data.HardwareType
        )
      })
    })
    this.filterForm = new FormGroup({
      lockername: new FormControl('', []),
      street: new FormControl('', []),
      postalCode: new FormControl('', []),
      acCode: new FormControl('', []),
      lockerid: new FormControl('', []),
      lockerprovider: new FormControl('', []),
      lockertype: new FormControl('', []),
      active: new FormControl('', []),
      online: new FormControl('', []),
      commisioned: new FormControl('', []),
      technology: new FormControl('', []),
      partnerId: new FormControl('', []),
      holidayDate: new FormControl('', []),
      acceptSoftReservations: new FormControl('', []),
    })
    this.acCodedropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'type',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: this.nodataText

    };
    this.fetchLockerAcCode();
    this.fetchRecords()
    let currentUserInfo = this.userService.currentUser.partner.id
    if (currentUserInfo == 1) {
      this.fetchPartners();
      this.partnerDropdown = true
    } else {
      this.partnerDropdown = false
    }
    this.fetchDashBoard();
  }
  ngAfterViewInit() {
    let divElement = this.myDiv.nativeElement;
    let divstyle = window.getComputedStyle(divElement)
    let display = divstyle?.display;
    if (display == 'none') {
      this.showUnauthorized = true
    }
  }

  langaugeFetch(lang, key) {
    this.langtransService.TranslateData(lang, key).subscribe(
      res => {
        this.toastr.error(res)
      }
    )
  }


  fetchDashBoard() {
    this.spinner.show();
    this.deliverypointsService.fetchDashBoard().subscribe(
      res => {
        this.dashBoard = res
        this.dashBoardError = false
        this.commissioned = this.dashBoard?.commisioned?.completed
        this.notCommissioned = this.dashBoard?.commisioned?.total
        this.online = this.dashBoard?.online
        this.offline = this.dashBoard?.offline
        this.spinner.hide();
      },
      err => {
        this.dashBoardError = true
        console.error(err);
        this.spinner.hide();
      }
    )
  }

  loading(on: boolean) {
    const state = on == false ? false : true
    if (state === true) {
      this.spinner.show();
    } else {
      this.spinner.hide();
    }
    return Promise.resolve(state)
  }

  fetchLockerAcCode(){
    this.spinner.show()
    this.deliverypointsService.fetchAcCode().then(res=>{
      let acCodes= res.map(obj=>obj.acCode);
      this.acCodedropdownList = acCodes.filter((obj1, i, arr) => 
        arr.findIndex(obj2 => (obj2 === obj1)) === i
      )
      this.spinner.hide();
    })
  }
  fetchRecords() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      destroy: true,
      search: false
    };
    this.loading(true)
      .then(() => this.deliverypointsService.fetchMany(this.validate()))
      .then(res => {
        // this.acCodedropdownList = res.map(obj=>obj.acCode);
        this.result = res.map(i => {
          i.connectivity.online = !!i.connectivity.online;
          return i;
        })
        this.convertupdatedDate(this.result)
        this.result = res.map(i => {
          if (i.connectivity && i.connectivity.updatedAt) {
            let dateval = i.connectivity.updatedAt;
            i.connectivity.updatedAt = dateval.split('Z').join('');
          }
          return i;
        })
        // this.dtTrigger.next();
      })
      .catch(ex => {
        //this.toastr.error(this.lockerFetchfail);
        this.langaugeFetch(this.translateData, 'ListinglockersFailed')
        console.error(ex);
        this.hasErrorLoading = true;
        this.result = null;
        this.loading(false)
      })
      .finally(() => {
        if (this.result != null && this.result?.length >= 0) {
          this.dtTrigger.next();
        } else {
          this.result = null;
          this.dtTrigger.next();
        }
        this.loading(false)
      })
  }

  validate() {

    let isActive =
      this.filterForm.value.active === 'true' ? true :
        this.filterForm.value.active === 'false' ? false : undefined
    let partnerName = [];
    let acCode=this.filterForm.value.acCode
    let partnerNameForm = this.filterForm.value.partnerId ? this.filterForm.value.partnerId : [];
    let processedRequest = {};
    if (partnerNameForm.length != 0) {
      for (let i = 0; i < partnerNameForm.length; i++) {
        partnerName.push(partnerNameForm[i].id);
      }
    }
    return {
      name: this.filterForm.value.lockername || undefined,
      street: this.filterForm.value.street || undefined,
      provider: this.filterForm.value.lockerprovider || undefined,
      type: this.filterForm.value.lockertype || undefined,
      active: isActive,
      acCode: (acCode.join(','))  || undefined,
      postalCode: this.filterForm.value.postalCode || undefined,
      lockerId: this.filterForm.value.lockerid || undefined,
      online: this.filterForm.value.online || undefined,
      commisioned: this.filterForm.value.commisioned || undefined,
      technology: this.filterForm.value.technology || undefined,
      partnerId: (partnerName.join(',')) || undefined,
      holidayDate: this.filterForm.value.holidayDate ? this.datePipe.transform(this.filterForm.value.holidayDate, 'yyyy-MM-dd') : undefined,
      acceptSoftReservations: this.filterForm.value.acceptSoftReservations || undefined
    };


  }
  onSubmit() {
    this.result = [];
    this.hasErrorLoading = false;
    this.fetchRecords();
    this.rerender();
  }
  // downloadCSV() {
  //   this.loading(true)
  //     .then(() => this.deliverypointsService.downloadLockerDetails(this.validate()))
  //     .then(res => FileSaver.saveAs(res, "export_" + Date.now() + "_lockers.csv"))
  //     .catch(err => {
  //       this.toastr.error('Error downloading the file', 'Failed');
  //       console.error(err);
  //     })
  //     .finally(() => this.loading(false))
  // }
  exportToCSV() {
    this.loading(true)
      .then(() => this.deliverypointsService.downloadLockerDetails(this.validate()))
      //.then(res => FileSaver.saveAs(res, "export_" + Date.now() + "_lockers.csv"))
      .then((res: any) => {
        const csvData = this.formatDataForCSV(res)
        const Today = this.datePipe.transform(new Date(), 'dd-MM-yyyy')
        let downtimeReport = 'Delivery_Points_' + Today + '.csv'
        this.downloadCSV(csvData, downtimeReport)
      })
      .catch(err => {
        //this.toastr.error('Error downloading the file');
        this.langaugeFetch(this.translateData, 'ErrordownloadingthefileFailed')
        console.error(err);
      })
      .finally(() => this.loading(false))
  }
  formatDataForCSV(data: any[]): string {
    let onlineStatus
    const flattenData = data.map((item) => {
      if (!item.connectivity.online || this.isAlert) {
        onlineStatus = "False"
      }
      if (item.connectivity.online && !this.isAlert) {
        onlineStatus = "True"
      }
      return [
        item.acCode,
        item.lockerId,
        item.lockerName,
        item.address.street + "," + item.address.streetNumber + "," + item.address.postalCode + "," + item.address.municipality + "," + item.address.countryCode,
        item.type,
        onlineStatus,
        // item.operations.acceptSoftReservations,
        item.operations.lockerOperationActive,
        item.id,
        item.connectivity.ipAddress,
        item.provider,
        item.connectivity.technology,
        this.timeFormatter(item.connectivity.updatedAt) || '-',
        item.totalDoors || '-',
        item.acceptSoftReservations || '-',
        item.windBrackets || '-',
        item.position || '-',
        item.salesSegment || '-',
        item.contractName || '-',
        item.contractHostName || '-',
        item.numberOfUnits || '-',
        item.salesKeyAccount || '-',
        item.operationsFieldSupport || '-',
        item.locationDeliveryRoute || '-',
        item.mailOffice || '-',
        item.logisticsPlatform || '-',
        item.mailCentre || '-',
        item.windDirection || '-',
        item.hardwareType || '-'
      ];
    });
    // const csvContent = Papa.unparse(flattenData, {
    //   delimiter: ',',
    // });
    const headers = [
      this.headerVariables[0], this.headerVariables[1], this.headerVariables[2], this.headerVariables[3], this.headerVariables[4],
      this.headerVariables[5], this.headerVariables[6], this.headerVariables[7], this.headerVariables[8], this.headerVariables[9],
      this.headerVariables[10], this.headerVariables[11], this.headerVariables[12], this.headerVariables[13], this.headerVariables[14],
      this.headerVariables[15], this.headerVariables[16], this.headerVariables[17], this.headerVariables[18], this.headerVariables[19],
      this.headerVariables[20], this.headerVariables[21], this.headerVariables[22], this.headerVariables[23], this.headerVariables[24],
      this.headerVariables[25], this.headerVariables[26], this.headerVariables[27]
    ]
    const csvContent = Papa.unparse({ fields: headers, data: flattenData }, { quotes: true });
    return csvContent;
  }

  downloadCSV(data: string, filename: string) {
    let encodedCsvData = encodeURIComponent(data)
    let csvUrl = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodedCsvData
    // const blob = new Blob([data], { type: 'text/csv;charset=utf-8;'});
    // const url = window.URL.createObjectURL(blob);
    // const a = document.createElement('a');
    // a.href = url;
    // a.download = filename;
    // a.click();
    // window.URL.revokeObjectURL(url);
    let link = document.createElement("a")
    link.href = csvUrl
    link.setAttribute("download", filename)
    link.style.visibility = 'none'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  getId(id: any) {
    this.router.navigate(['/deliverypoints/' + id + '/details']);
  }

  onReset() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      dtInstance.on('draw.dt', () => {
        if ($('.dataTables_empty').length > 0) {
          if (this.hasErrorLoading) {
            $('.dataTables_empty').text('Error in loading data');
            this.showDownload = false;
          } else if (this.result?.length == 0) {
            $('.dataTables_empty').text('No records found!');
            this.showDownload = false;
          }
        } else {
            this.showDownload=true;
        }
      });
    });
  }

  reload() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload()
    });
  }

  rerender_datatable() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  // ngAfterViewInit(): void {
  //   this.dtTrigger.next();
  // }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  fetchPartners() {
    this.spinner.show();
    this.PartnerService.fetchPartnersList().subscribe(
      result => {
        this.spinner.hide();
        this.partnerList = result
        let partnerNameList = []
        for (let i = 0; i < this.partnerList.length; i++) {
          if (this.partnerList[i].active === true) {

            partnerNameList[i] = { id: this.partnerList[i].id, name: `${this.partnerList[i].name}` };
          }
        }
        this.partnerNamedropdownList = partnerNameList
        this.partnerNamedropdownSettings = {
          singleSelection: false,
          idField: 'id',
          textField: 'name',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 3,
          allowSearchFilter: true
        };
      }, err => {
        this.spinner.hide();
      })
  }

  onItemSelect(item: any) {
  }
  onSelectAll(items: any) {
  }
  convertupdatedDate(res: any) {
    for (let i = 0; i < res.length; i++) {
      if (res[i].connectivity.updated != null) {
        const timestamp = (res[i].connectivity.updatedAt).replace('T', ' ').replace('Z', '')

        const currentTime = new Date();

        let createdDate = momenttimezone(currentTime).tz("Europe/Berlin").format("YYYY-MM-DD HH:mm:ss");

        const startDown = moment(timestamp)
        const endDown = moment(createdDate)

        this.timeDiffmin = Math.abs(endDown.diff(startDown, 'minutes'))

        if (this.timeDiffmin < this.timeDifference) { this.isAlert = false } else { this.isAlert = true };
      }
    }
  }
  openDatePicker(v) {
    if (v == 'holidayDateInput')
      this.renderer.selectRootElement(this.holidayDateInput.nativeElement).click()
  }
  timeFormatter(date) {
    const formattedDateTime = utcFormatter(date);
    if (!formattedDateTime) return null
    // return moment(formattedDateTime).format('DD-MM-YYYY HH:mm:ss')
    return this.datePipe.transform(formattedDateTime, 'dd-MM-YYYY HH:mm:ss')
  }
}
