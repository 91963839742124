import { Injectable } from '@angular/core';

@Injectable()

export class DataService {
    public data: any ; 
     
  constructor() {}

    
}