import { Component, ElementRef, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';

@Component({
  selector: 'app-partner-info-menu',
  templateUrl: './partner-info-menu.component.html',
  styleUrls: ['./partner-info-menu.component.scss'],
})
export class PartnerInfoMenu implements OnInit {
  @Input() partner: any;

  constructor(
  ) {
    
  }

  ngOnInit(): void {
    
  }
}
