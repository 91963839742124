import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../../../Service/app.service';
import { LangtransService } from 'src/app/Service/langtrans.service';
import { PartnerService } from '../../../Service/partner.service';
import { SpinnerService } from 'src/app/Service/spinner.service';

@Component({
  selector: 'app-partner-add',
  templateUrl: './partner-add.component.html',
  styleUrls: ['./partner-add.component.scss']
})
export class PartnerAddComponent implements OnInit {
  partnerForm: FormGroup
  submitted: boolean;
  translateData: any;

  constructor(
    public activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private appService: AppService,
    public router: Router,
    private partnerService: PartnerService,
    private langtransService: LangtransService,
    private spinner: SpinnerService
  ) {
    this.partnerForm = this.formBuilder.group({
      partnerName: ['', Validators.required],
      country: ['', Validators.required],
      active: ['', Validators.required],
      courier: ['', Validators.required],
      prsId: ['', Validators.pattern("^[0-9]*$")]
    })
  }

  ngOnInit(): void {
    this.appService.languagetoggle$.subscribe((data: any) => {
      this.translateData = data
    })
  }

  get formValidators() {
    return this.partnerForm.controls;
  }

  langaugeFetch(lang, key, toastrstatus) {
    this.langtransService.TranslateData(lang, key).subscribe(
      res => {
        if (toastrstatus == 'success') {
          this.toastr.success(res)
        }
        if (toastrstatus == 'error') {
          this.toastr.error(res, 'Error')
        }
      }
    )
  }

  onSubmit() {

    this.submitted = true

    if (this.partnerForm.valid) {
      this.spinner.show();
      let addPartner = {
        "name": this.partnerForm.controls.partnerName.value,
        "country": this.partnerForm.controls.country.value,
        "active": this.partnerForm.controls.active.value,
        "courier": this.partnerForm.controls.courier.value,
        "prsId": parseInt(this.partnerForm.controls.prsId.value)
      }
      this.partnerService.addNewPartner(addPartner).subscribe((res) => {
        if (res) {
          this.spinner.hide();
          this.router.navigate(['/partners']);
          // this.toastr.success('Partner Added Successfully !!', 'Success')
          this.langaugeFetch(this.translateData, 'PartnerAdded', "success")
        }
      }, (err: any) => {
        this.spinner.hide();
        if (err.error && err.error.error) {
          console.error(err.error.error, "add partner error")
          //this.toastr.error(err.error.error.message, 'Error')
          this.langaugeFetch(this.translateData, 'AlreadyExistPartner', "error")
        } else {
          this.langaugeFetch(this.translateData, 'AddPartnerFailed', "error")
        }

      })
    }
  }

}
