import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { ToastrService } from 'ngx-toastr';
import { PartnerService } from '../../Service/partner.service';
import { SpinnerService } from 'src/app/Service/spinner.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-partner-settings',
  templateUrl: './partner-settings.component.html',
  styleUrls: ['./partner-settings.component.scss']
})
export class PartnerSettingsComponent implements OnInit {
  public partnerSettingForm
  settingResult: any
  constructor(
    private FB: FormBuilder,
    public activatedRoute: ActivatedRoute,
    private partnerService: PartnerService,
    public router: Router,
    private toastr: ToastrService,
    private spinner: SpinnerService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.partnerSettingForm = new FormGroup({
      minReservePeriod: new FormControl('', []),
      length: new FormControl(100, [Validators.pattern("^[0-9]*$")]),
      width: new FormControl(100, [Validators.pattern("^[0-9]*$")]),
      height: new FormControl(100, [Validators.pattern("^[0-9]*$")])
    })
    this.getSettingDetails()
  }

  onInputChange(event: any): void {
    const inputValue = event.target.value;
    event.target.value = inputValue.replace(/[^0-9]/g, '');
  }

  getSettingDetails() {
    this.spinner.show();
    this.partnerService.getsettingInfo().subscribe((res: any) => {
      this.spinner.hide();
      if (res.length != 0) {
        this.patchDefaultValues(res[0]);
      }
    }, err => {
      console.error(err);
      this.spinner.hide();
    })
  }
  patchDefaultValues(result: any) {
    this.partnerSettingForm.patchValue({
      minReservePeriod: result.minReservePeriod ? result.minReservePeriod : '',
      length: result.length ? result.length : '',
      width: result.width ? result.width : '',
      height: result.height ? result.height : '',
    })
  }
  saveSettingDetails() {
    console.log('valuee',this.partnerSettingForm.value)
    let body = {
      minReservePeriod: this.partnerSettingForm.value.minReservePeriod ? this.partnerSettingForm.value.minReservePeriod : null,
      length: this.partnerSettingForm.value.length ? this.partnerSettingForm.value.length : null,
      width: this.partnerSettingForm.value.width ? this.partnerSettingForm.value.width : null,
      height: this.partnerSettingForm.value.height ? this.partnerSettingForm.value.height : null
    }
    this.spinner.show();
    this.partnerService.saveSettingInfo(body).subscribe((res) => {
      this.spinner.hide();
      this.toastr.success(this.translate.instant('Partner setting saved !!'), this.translate.instant('Success'));
      this.router.navigate(['/partnertools']);
    }, err => {
      console.error(err);
      this.spinner.hide();
    })
  }

}
