import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ddsEnvironment, lrsEnvironment,losEnvironment} from 'src/environments/environment';
import { Injectable, Inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ParcelService {

  constructor(private http: HttpClient) { }
  private filterState: any
  sendEmail(data : Object,barcode) {
    return this.http.post(
      `${losEnvironment.baseUrl}/items/` +barcode+ `/emails`,data)
  }

  getOne(id : any){
     return this.http.get(
       `${lrsEnvironment.baseUrl}/parcels/`+ id )
  }

  getHistoricalData(barcode:any){
      return this.http.get(
      `${lrsEnvironment.baseUrl}/parcels/${barcode}/historicalData`)
  }

  postRetrievalCode(id : any,body: any){
    return this.http.post(
      `${lrsEnvironment.baseUrl}/parcels/`+ id,body)
  }
  fetchBarcodeDetailsFilter(body:any, limit, offset) : Promise <any> {
    
     return this.http.post(
      `${lrsEnvironment.baseUrl}/parcels?limit=${limit}&offset=${offset}&output=json`,body,{
     
  
        observe: 'response'
    }).toPromise()
  }

  downloadBarcodeDetailsFilter(body:any) {
    return this.http.post(
      `${lrsEnvironment.baseUrl}/parcels?output=csv-download`,body, { responseType: 'blob' }).toPromise()
  }

  changeCompartment(id: any, newCompartmentDetails: any) {
    return this.http.post(
      `${lrsEnvironment.baseUrl}/parcels/`+ id,
      Object.assign({}, {
        action: 'FORCE_CHANGE_COMPARTMENT'
      }, newCompartmentDetails)
    ).toPromise()
  }

  changeStatus(id: any, status: any) {
    return this.http.post(
      `${lrsEnvironment.baseUrl}/parcels/`+ id, {
        action: 'EVENT',
        eventCode: status.eventCode,
        eventSubCode: status.eventSubCode,
        flow: status.flow
      }).toPromise()
  }

  getActivityDetails(id:any) {
    return this.http.get(
      `${lrsEnvironment.baseUrl}/parcels/`+ id +`/activity`)
  }
  insertCommentsToDB(id:any,body: string){
    return this.http.post(`${lrsEnvironment.baseUrl}/parcels/`+ id +`/activity`,body)
  }
postmanLoginCode(body){
  return this.http.post(`${ddsEnvironment.baseUrl}/code`,body)
}
  getParcelInfo(barcode: any , parcelId: any , fetchColumn: any) {
    parcelId = parcelId.toString()
    const headers = new HttpHeaders()
    .set('barcode', barcode)
    .set('parcelId', parcelId)
    .set('read_mask' , fetchColumn);
    return this.http.get(`${lrsEnvironment.baseUrl}/parcels/parcel_info`, { headers }).toPromise()
  }
  firstMailFailedItems(body){
    // return this.http.get('assets/dummy.json')
    return this.http.get(`${losEnvironment.baseUrl}/reports/faileddrops`,{params:body})
    // return this.http.get(url,{params:queryParams});
  }

  fetchUserOperation(query) {
    return this.http.get(
      `${losEnvironment.baseUrl}/reports/usercreation`,{
        params: query
      })
  }

  setFilterState(state:any){
    this.filterState = state
  }

  getFilterState(){
    return this.filterState
  }
}


