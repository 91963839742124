import {
  Component,
  OnInit,
  Input
} from '@angular/core';

@Component({
  selector: 'app-lockers-layout',
  templateUrl: './lockers-layout.component.html',
  styleUrls: ['./lockers-layout.component.scss']
})
export class LockersLayoutComponent implements OnInit {

  @Input() terminals: any;
  columns: any;

  hasAnyCompartment: boolean;
  consoleValue: number;

  constructor() {}
  ngOnInit(): void {
    
  }


}
