import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { DeliverypointsService } from 'src/app/Service/deliverypoints.service';
import { PartnerService } from 'src/app/Service/partner.service';
import { SpinnerService } from 'src/app/Service/spinner.service';

@Component({
  selector: 'app-partner-home',
  templateUrl: './partner-home.component.html',
  styleUrls: ['./partner-home.component.scss']
})
export class PartnerHomeComponent implements OnInit {
  storedData: any
  orderResult: any
  vaultResponse: any
  orderToProcess: any
  locationData: any = [];
  lockerNotFree: boolean = false;
  loadingData: boolean = true
  constructor(
    public router: Router,
    private partnerservice: PartnerService,
    private deliverypointservice: DeliverypointsService,
    private spinner: SpinnerService
  ) { }

  ngOnInit(): void {
    this.storedData = JSON.parse(window.localStorage.getItem('storedValue'))
    this.getPartnerResponse();
    this.getPartnerVault();
    this.getpartnerOrderToProcess();
    this.setLocation()
  }
  getPartnerResponse() {
    this.spinner.show();
    this.partnerservice.getPartnerInfo()
    .pipe(map((orders:Array<any>)=>{
      return orders.map((order:any)=>{
       let retObj = {...order}
       retObj.reservePeriodFrom = order.reservePeriodFrom ? order.reservePeriodFrom.split('Z').join(''):'';
       retObj.reservePeriodTo = order.reservePeriodTo ? order.reservePeriodTo.split('Z').join(''):'';
       return retObj;
     })
     }))
    .subscribe(res=>{
      this.spinner.hide();
      this.orderResult=res;
    })
  }

  getPartnerVault() {
    this.spinner.show();
    this.partnerservice.getPartnerVault()
    .pipe(map((orders:Array<any>)=>{
      return orders.map((order:any)=>{
       let retObj = {...order}
       retObj.reservePeriodFrom = order.reservePeriodFrom ? order.reservePeriodFrom.split('Z').join(''):'';
       retObj.reservePeriodTo = order.reservePeriodTo ? order.reservePeriodTo.split('Z').join(''):'';
       return retObj;
     })
     }))
    .subscribe(res=>{
      this.spinner.hide();
      for (let i = 0; i < res.length; i++) {
        if (res[i].currentStatus.toUpperCase() === 'LASTMILE_DELIVERED_BY_POSTMAN') {
          res[i].currentStatus = 'Parcel delivered to the safe'
        }
      }
      this.vaultResponse=res;
    })
  }

  getpartnerOrderToProcess() {
    this.spinner.show();
    this.partnerservice.getPartnerOrderToProcess()
    .pipe(map((orders:Array<any>)=>{
      return orders.map((order:any)=>{
       let retObj = {...order}
       retObj.reservePeriodFrom = order.reservePeriodFrom ? order.reservePeriodFrom.split('Z').join(''):'';
       retObj.reservePeriodTo = order.reservePeriodTo ? order.reservePeriodTo.split('Z').join(''):'';
       return retObj;
     })
     }))
    .subscribe(res=>{
      this.spinner.hide();
      for (let i = 0; i < res.length; i++) {
        if (res[i].currentStatus.toUpperCase() === 'LM_RESERVED') {
          res[i].currentStatus = 'Safe Reserved'
        }
      }
      this.orderToProcess=res
    })
  }

goToNewOrder(){
  this.router.navigate(['/partnertools/newOrder']);
}

goToPlacedOrder(){
  this.router.navigate(['/partnertools/ordersToProcess']);
}

goToVault(){
  this.router.navigate(['/partnertools/ordersPlacedInVault']);
}

setLocation() {
  let obj = {checkCapacity:1}
  this.spinner.show();
  this.partnerservice.fetchPartnerDeliverypointDetailswithPartnerId(obj).subscribe(
    (res: any) => {
      this.spinner.hide();
      if(res.hasCapacity){
        this.lockerNotFree=false
      }
      else{
        this.lockerNotFree=true
      }
      this.loadingData=false
    },
    err => {
      this.loadingData=false
      if(err.error.code==="DDS_NOT_FOUND"){
        this.lockerNotFree=true;
      }
      this.spinner.hide();
      console.error(err);
    }
  )
}


}
