<!-- <app-loader *ngIf="showSpinner"></app-loader> -->
<app-header></app-header>
<div class="container">
    <br />
    <div class="row">
        <div class="col-3">
            <app-partner-info-menu [partner]=partnerDetails></app-partner-info-menu>
        </div>
        
        <div class="col-9">
            <div class="head-name">{{'Deliverypoint schemas'|translate}}</div>
        
            <button data-toggle="modal" data-target="#addAcCodeModal" class="btn btn-info pull-right mb-3"
                style="cursor: pointer;"><i class="fa fa-plus" aria-hidden="true"></i> {{'AC Code'|translate}}</button>
             
            <table class="table table-sm table-bordered table-hover row-border hover mt-3" style="text-align:center;">
                <caption></caption>
                
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">{{'DP Id'|translate}}</th>
                        <th scope="col">{{'Locker Name'|translate}}</th>
                        <th scope="col">{{'NL Code'|translate}}</th>
                        <th scope="col">{{'LM Capacity'|translate}}</th>
                        <th scope="col">{{'Action'|translate}}</th>
                    </tr>
                </thead>
                <tbody *ngIf="dpdsByAcCodeData?.length > 0">
                    <tr *ngFor="let partner of dpdsByAcCodeData;let i=index">
                        <td (click)="getDeliverypointDetails(partner.id)" *ngIf="partner?.deliveryPointId"> {{partner.deliveryPointId}}</td>
                        <td *ngIf="partner?.deliveryPointId">{{partner.lockerName}}</td>
                        <td *ngIf="partner?.deliveryPointId">
                            <div *ngIf="!partner.isShowInput">{{partner.nlCode}}</div>
                            <div *ngIf="partner.isShowInput"><input [(ngModel)]="partner.nlCode" class="form-control"></div>
                        </td>
                        <td *ngIf="partner?.deliveryPointId">
                            <div *ngIf="!partner.isShowInput">{{partner.LMCapacity}}</div>
                            <div *ngIf="partner.isShowInput"><input [(ngModel)]="partner.LMCapacity" class="form-control"
                                    appLengthLimit [maxLength]="3"></div>
                        </td>
                        <td class="text-center" *ngIf="partner?.deliveryPointId">
                            <div *ngIf="partner.isShowInput">
                                <i class="fa fa-floppy-o px-2" aria-hidden="true" title="save"
                                    style="cursor: pointer;color: #252527;" (click)='saveTableData(partner)'></i>
                                <i class="fa fa-times-circle px-2" title="Cancel" style="cursor: pointer;color: #252527;"
                                    aria-hidden="true" (click)='resetTableData(partner)'></i>
                            </div>
                            <div *ngIf="!partner.isShowInput">
                                <i class="fa fa-pencil-square-o px-2" aria-hidden="true" title="Edit"
                                    style="cursor: pointer;color: #252527;" (click)='editTableData(partner)'></i>
                                <i class="fa fa-trash-o px-2" style="cursor: pointer;color: #f0373a;" 
                                data-toggle="modal" data-target="#deleteDeliverypointsModal" title="Delete"
                                    aria-hidden="true" ></i>
        
                            </div>
                        </td>
                    <div class="modal fade" tabindex="-1" id="deleteDeliverypointsModal" role="dialog" aria-labelledby="deleteDeliverypointsModal"
                        aria-hidden="true">
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h3 class="modal-title">{{'Delete'| translate}}</h3>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <p class="text-left">{{'Are you sure you want to delete deliverypoints'| translate}}</p>
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                              <button type="button" class="btn btn-danger" data-dismiss="modal" (click)='removeTableData(partner)'>Confirm</button>
                            </div>
                          </div>
                        </div>
                    </div>

                    </tr>
                </tbody>
                <tbody *ngIf="dpdsByAcCodeData?.length == 0">
                    <tr>
                        <td colspan="4" class="no-data-available">{{'No data!'|translate}}</td>
                    </tr>
                </tbody>
            </table>
            <br />
            <button class="btn btn-secondary  mr-1" (click)="goToPartnerDetails()">{{'Back'|translate}}</button>
        </div>
    </div>
</div>


<div class="modal fade" #addAcCodeModal tabindex="-1" id="addAcCodeModal" role="dialog"
    aria-labelledby="addAcCodeModalLabel" aria-hidden="true">
    <form [formGroup]="addAcCodeForm">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title" style="margin-bottom: -10px;">{{'Add AC Code' | translate}}</h3>
                    <button type="button" class="close" (click)="resetInputValues()" data-dismiss="modal"
                        aria-label="Close" #closeAcCodeModal>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-row">
                        <div class="form-group col-10">
                            <label>{{'AC Code'|translate}}</label>
                            <ng-multiselect-dropdown formControlName="lockerAcCode"
                                [placeholder]="'Select ac code'|translate" [settings]="dropdownSettings"
                                [data]="lockerNamedropdownList" [(ngModel)]="selectedItems"
                                (onSelect)="onItemSelect($event)">
                            </ng-multiselect-dropdown>
                            <!-- {{requiredField}}
                            <p *ngIf="!requiredField" class="err-msg">
                                Please select AC Code!
                            </p> -->
                            <small *ngIf="submitted &&  formValidators.lockerAcCode.errors?.required"
                                class="form-text text-muted col-sm-8">{{'Please select Ac code!' | translate}}
                            </small>
                        </div>
                    </div>
                    <div class="text-center">
                        <button class="btn btn-secondary mr-1" data-dismiss="modal"
                            (click)="resetInputValues()">{{'Cancel'|translate}}</button>
                        <button class="btn btn-primary" (click)="addAcCode()">{{'Add'|translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="modal fade" tabindex="-1" id="postalCodeModal" role="dialog" aria-labelledby="postalCodeModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Add Postal Code</h5>
                <button type="button" (click)="resetInputValues()" class="close" data-dismiss="modal" aria-label="Close"
                    #closePostalCodeModal>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form name="form" (ngSubmit)="postalCodeForm.form.valid && addPostalCode()" #postalCodeForm="ngForm"
                    novalidate>
                    <div class="form-row">
                        <div class="form-group col-10">
                            <label>Postal Code</label>
                            <input type="text" maxlength="4" name="postalCode" class="form-control"
                                [(ngModel)]="model.postalCode" #postalCode="ngModel" pattern="[0-9 ]{4}" required>
                            <div [hidden]="!postalCode.errors?.required">Postal Code is required!</div>
                            <div [hidden]="!postalCode.errors?.pattern">Please enter valid Postal Code!</div>
                        </div>
                    </div>
                    <div class="text-center">
                        <button class="btn btn-secondary mr-1" data-dismiss="modal"
                            (click)="resetInputValues()">Cancel</button>
                        <button class="btn btn-primary" [disabled]="postalCode.invalid">Add</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
