import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr'
import { AppService } from '../../../Service/app.service';
import { UserService } from '../../../Service/user.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import *as Papa from 'papaparse'

import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from 'src/app/Service/spinner.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-userlist',
  templateUrl: './userlist.component.html',
  styleUrls: ['./userlist.component.scss']
})
export class UserlistComponent implements OnDestroy,OnInit {
  id: any
  email: any
  result: any
  deleteId: any
  emailPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$'
  groups: any
  submitted: boolean
  parcelLockerNamedropdownList = [];
  userGroupdropdownList = [];
  userGroupselectedItems = [];
  orderTypedropdownSettings: IDropdownSettings;
  userGroupdropdownSettings: IDropdownSettings;
  showSpinner: boolean = true
  exportData: any
  groupM: any
  grpDetail = [];
  roleDetail: any
  finData: any
  public filterForm
  selectAll: any;
  unselectAll: any;
  searchText: any;
  headings: string[];
  dtOptions: DataTables.Settings = {};
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  constructor(
    private http: HttpClient,
    private appService: AppService,
    private userService: UserService,
    public router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private spinner: SpinnerService
  ) { }

  ngOnInit(): void {
    this.appService.languagetoggle$.subscribe((data: any) => {
      this.http.get(`/assets/i18n/${data}.json`).subscribe((data: any) => {
        this.selectAll = data.selectAll
        this.unselectAll = data.UnSelectAll
        this.searchText = data.Search
        this.headings = [data.Name, 'E-mail', data.userPartner, data.Groups, data.Lastloggedintime]
        this.userGroupdropdownSettings = {
          singleSelection: false,
          idField: 'id',
          textField: 'type',
          selectAllText: this.selectAll,
          unSelectAllText: this.unselectAll,
          itemsShowLimit: 5,
          allowSearchFilter: true,
          searchPlaceholderText: this.searchText
        };
      })
    })
    this.fetchUserGroups();

    this.filterForm = new FormGroup({
      Email: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
      Name: new FormControl('', []),
      userGroups: new FormControl('', [Validators.required]),
    })
    let userGroupList = []
    setTimeout(() => {
      for (let i = 0; i < this.groups.length; i++) {
        userGroupList[i] = { id: this.groups[i].id, type: this.groups[i].name };
      }
      this.userGroupdropdownList = userGroupList
      // console.log("this.parcelLockerNamedropdownList", this.parcelLockerNamedropdownList)
      // this.userGroupdropdownSettings = {
      //   singleSelection: false,
      //   idField: 'id',
      //   textField: 'type',
      //   selectAllText: this.selectAll,
      //   unSelectAllText: this.unselectAll,
      //   itemsShowLimit: 5,
      //   allowSearchFilter: true,
      //   searchPlaceholderText: this.searchText
      // };
    }, 2000)
    this.fetchRecords(this.validate())
  }
  onItemSelect(item: any) {
  }
  onSelectAll(items: any) {
  }
  get Filter() {
    return this.filterForm.controls
  }
  fetchUserGroups() {
    this.spinner.show();
    this.userService.fetchGroups().subscribe(
      res => {
        this.spinner.hide();
        this.groups = res;
      },err => {
        this.spinner.hide();
        console.error(err);
      }
    )
  }
  async fetchUserList(filterVal) {
    let start = 0;
    let limit = 500;
    let apiReturnData = [];
    let mergedResult = [];
    do{
      if (filterVal.email) filterVal.email = filterVal.email.toLowerCase()
      await this.userService.fetchManyUsers(filterVal, limit, start)
      .then((apiRes) => apiReturnData = apiRes)
      
      .catch((err) => {
        throw err;
      })
      mergedResult.push(...apiReturnData);
      start = start+limit;
    }while (apiReturnData.length !== 0)
      return mergedResult;
  }
  // fetchRecords(filterVal) {
  //   if (filterVal.email) filterVal.email = filterVal.email.toLowerCase()
  //   this.spinner.show();
  //   this.userService.fetchMany(filterVal).subscribe(
  //     res => {
  //       this.spinner.hide();
  //       if (typeof res[0] === 'undefined') {
  //         this.toastr.warning(this.translate.instant('no details found'), this.translate.instant('sorry'));
  //       }
  //       this.result = res;
  //       this.dtTrigger.next();
  //     },
  //     err => {
  //       this.spinner.hide();
  //       this.toastr.error(this.translate.instant('ListingUsersFailed'), this.translate.instant('Failed'));
  //       console.error(err);
  //     }
  //   )
  // }

  fetchRecords(filterVal) {
    return new Promise((resolve,reject) => {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 50,
        destroy: true,
        search: false,
        //Sorting orderdate by recent to oldest when list is loaded.
        order: [[1, 'desc']]
      };

      this.loading(true)
        .then(async () => await this.fetchUserList(filterVal))
        .then(res => {
             this.spinner.hide();
             this.result = res;
        if (typeof res[0] === 'undefined') {
          this.toastr.warning(this.translate.instant('no details found'), this.translate.instant('sorry'));
          this.result = [];
        }
        })
        .catch(err => {
                 this.spinner.hide();
        this.toastr.error(this.translate.instant('ListingUsersFailed'), this.translate.instant('Failed'));
        console.error(err);
        })
        .finally(() => {
          if (this.result != null && this.result?.length >= 0) {
            this.dtTrigger.next();
          }else{
            this.result = null;
            this.dtTrigger.next();
          }
          resolve(null);
        })
      })
  }
  validate() {
    let userGroup = []
    if (this.filterForm.value.userGroups) {
      if (this.filterForm.value.userGroups.length != 0) {
        for (let i = 0; i < this.filterForm.value.userGroups.length; i++) {
          userGroup.push(this.filterForm.value.userGroups[i].id)
        }
      }
    }
    if (userGroup.length === 0) {
      userGroup = undefined
    }
    return {
      email: this.filterForm.value.Email || undefined,
      name: this.filterForm.value.Name || undefined,
      groups: userGroup || undefined,
    }
  }

  onSubmit() {
    this.fetchRecords(this.validate())
  }
  onReset() {
    this.filterForm.reset()
    this.fetchRecords({})
  }
  deleteUser() {
    this.spinner.show();
    this.userService.deleteUser(this.deleteId).subscribe(
      res => {
        this.spinner.hide();
        this.toastr.success(this.translate.instant('User deleted successfully'),this.translate.instant('Success'));
        this.fetchRecords({})
      },
      err => {
        this.spinner.hide();
        this.toastr.error(this.translate.instant('Deleting User Failed !!'),this.translate.instant('Failed'));
        console.error(err);
      }
    )
  }
  saveDeleteId(id) {
    this.deleteId = id
  }
  getId(id: any) {
    this.router.navigate(['/userdetails/' + id]);
  }

  update(id: any) {
    this.router.navigate(['/updateuser/' + id]);
  }

  loading(state: boolean): Promise<any> {
    this.showSpinner = state
    if(state === true){
      this.spinner.show();
    }else{
      this.spinner.hide();
    }
    return Promise.resolve(state)
  }

  exportCode() {
    this.groupM = []
    this.finData = []
    for (let i = 0; i < this.result.length; i++) {
      this.grpDetail = []
      if (this.result[i].groups != null) {
        for (let j = 0; j < this.result[i].groups.length; j++) {
          let temp1 = this.result[i].groups[j].name

          this.grpDetail.push(temp1)
        }
      }
      // let temp = {
      //   "Id": this.result[i].id,
      //   "Name": this.result[i].name,
      //   "Email": this.result[i].email,
      //   "Roles": this.result[i].role.toString(),
      //   "Groups": this.grpDetail.toString(),
      //   "Last logged-in time": this.result[i].lastLoginTime
      // }
      let t = {
        "Name":  this.result[i].firstName +' '+ this.result[i].lastName,
        "Email": this.result[i].email,
        "Partner":this.result[i].partner.name,
        "Groups": this.grpDetail.toString(),
        "Last logged-in time": this.result[i].lastLoginTime
      }
      let temp = {}
      this.headings.forEach((newkey, index) => {
        const oldkey = Object.keys(t)[index];
        temp[newkey] = t[oldkey]
      })
      this.finData.push(temp)
    }
  }

  exportTable() {
    this.exportCode()
    // let dateInMs = new Date().getTime()
    // console.log(this.result,"GRRRRR")
    let exportData = this.finData.map((x: any) => Object.assign({}, x));
    let worksheet;
    // exportData.sort((a: any, b: any) => (a['name'].toLocaleLowerCase() > b['name'].toLowerCase() ? 1 : ((b['name'].toLocaleLowerCase() > a['name'].toLocaleLowerCase()) ? -1 : 0)))
    // console.log(exportData,"TTTTT")
    worksheet = XLSX.utils.json_to_sheet(exportData);
    worksheet["!cols"] = [{ wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 40 }, { wch: 30 }];
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet,);
    const Today = this.datePipe.transform(new Date(), 'dd-MM-yyyy')
    let downtimeReport = 'Users_' + Today + '.xls'
    XLSX.writeFile(workbook, downtimeReport);
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
}



