import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { FormControl, FormGroup } from '@angular/forms';
import { ParcelService } from '../../Service/parcel.service';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { AppService } from '../../Service/app.service';
import { LangtransService } from 'src/app/Service/langtrans.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-useroperations',
  templateUrl: './useroperations.component.html',
  styleUrls: ['./useroperations.component.scss']
})
export class UseroperationsComponent implements OnInit {
  eventTypedropdownList = [];
  eventTypedropdownSettings: IDropdownSettings;
  showSpinner: boolean = true
  eventTypeSelected = [];

  public userOperationForm
  result: any;
  list: any[];
  dropdownValue: any[];
  dataExport: any;
  operation: any;
  translateData: any;
  noFound: string;
  selectFields: string;
  isFromDate:boolean=false;
  isToDate:boolean=false;
  @ViewChild('dateFromInput') dateFromInput: ElementRef;
  @ViewChild('dateToInput') dateToInput: ElementRef;
  maxDate = new Date()
  constructor(
    private langtransService: LangtransService,
    private appService: AppService,
    private toastr: ToastrService,
    private ParcelService: ParcelService,
    private renderer: Renderer2,
    private datePipe: DatePipe,
  ) { }
  ngOnInit(): void {
    this.appService.languagetoggle$.subscribe((data: any) => {
      this.translateData = data
    })

    this.userOperationForm = new FormGroup({
      dateFrom: new FormControl('', []),
      dateTo: new FormControl('', []),
      eventType: new FormControl('', [])
    })
  }
  loading(state: boolean): Promise<any> {
    this.showSpinner = state
    return Promise.resolve(state)
  }
  onExportApiCall(obj) {
    const newArray = []
   this.ParcelService.fetchUserOperation(obj).subscribe((res: any) => {
      res.forEach(element => {
        const newObj = {
          Date: moment(element.eventTime).tz("Europe/Berlin").format('DD-MM-YYYY HH:mm:ss'),
          UserId: element.userId,
          Name: element.userName,
          Location: element.lockerName,
          AcCode: element.lockerAcCode,
          Barcode: element.itemBarcode,
          Event_Type: element.eventType
        }
        newArray.push(newObj)
      });
      if (newArray.length > 0) {
        let datevar = moment().tz("Europe/Berlin").format('DD-MM-YYYY')
        this.exportToExcel(newArray, 'user_operation_report_' + datevar)
        this.noFound = ''
      } else {
        this.noFound = 'NorecordsFound'
        this.selectFields = ''
        this.langaugeFetching(this.translateData, this.noFound)
      }
    })
  }
  onExport() {
    this.selectFields = '';
    this.noFound ='';
    this.isFromDate = false;
    this.isToDate = false;
    var obj = {
      eventType: this.userOperationForm.controls.eventType.value,
      dateFrom: this.datePipe.transform(this.userOperationForm.controls.dateFrom.value, 'yyyy-MM-dd'),
      dateTo: this.datePipe.transform(this.userOperationForm.controls.dateTo.value, 'yyyy-MM-dd'),
    }
    if ((!obj.dateFrom && !obj.dateTo) && obj.eventType) {
      this.onExportApiCall(obj)
      this.selectFields = ''
    }
    else if ((obj.dateFrom && obj.dateTo) && !obj.eventType) {
      this.onExportApiCall(obj)
      this.selectFields = ''
    }
    else if ((obj.dateFrom && obj.dateTo) && obj.eventType) {
      this.onExportApiCall(obj)
      this.selectFields = ''
    }
    else {
      if (!obj.dateFrom && !obj.dateTo && !obj.eventType){
        this.isFromDate = false;
        this.isToDate = false;
        this.selectFields = 'selectFieldUserOperation'
        this.langaugeFetching(this.translateData, this.selectFields)
      } else {
        if (!obj.dateFrom ){
          this.isFromDate = true;
          this.isToDate = false;
        } else if (!obj.dateTo){
          this.isFromDate = false;
          this.isToDate = true;
        } 
      }
    }
  }

  exportToExcel(data: any[], filename: string) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    // ws['!cols'] = headers.map(header => ({ width: 15 })); // Adjust column widths if needed
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Data');
    XLSX.writeFile(wb, `${filename}.xlsx`);

  }

  getToday(): string {
    return new Date().toISOString().split('T')[0]
  }

  onReset() {
    this.isFromDate = false;
    this.isToDate = false;
    this.noFound ='';
    this.selectFields='';
    this.userOperationForm.reset()
    this.userOperationForm.controls['eventType'].setValue('');
    this.userOperationForm.updateValueAndValidity();
  }
  langaugeFetching(lang, key) {
    this.langtransService.TranslateData(lang, key).subscribe(
      res => {
        if (this.noFound == 'NorecordsFound' && this.selectFields !== 'selectFieldUserOperation') {
          this.toastr.warning(res)
          this.selectFields='';
        }
        if (this.noFound !== 'NorecordsFound' && this.selectFields == 'selectFieldUserOperation') {
          this.noFound ='';
          this.toastr.error(res)
        }
      }
    )
  }
  openDatePicker(v) {
    if (v == 'dateFromInput'){
      this.isFromDate = false;
      this.renderer.selectRootElement(this.dateFromInput.nativeElement).click()
    }
    if (v == 'dateToInput'){
      this.isToDate = false;
      this.renderer.selectRootElement(this.dateToInput.nativeElement).click()
    }
  }
}

