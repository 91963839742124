<div class="mx-5 mt-5" [appViewController]="{partnerTool:'viewPartnerTool'}">
  <h2 style="color: red;"> {{'Overview' | translate}}</h2>

  <div class="row">
    <div class=" col col-md-6">

      <div *ngIf="!loadingData && !lockerNotFree" style="background-color: #d4e9b1;" class="px-3 py-3">
        <h5 style="font-size: 14px; font-weight: bold;">{{'Lockers Available'|translate}}</h5>
        <p>{{'Lockers are available for new orders. Click on one of the buttons below to create or place orders.'|translate}}</p>
      </div>

      <div *ngIf="!loadingData && lockerNotFree" style="background-color: red;" class="px-3 py-3">
        <h5 style="font-size: 14px; font-weight: bold;">{{'Sorry! lockers are not available'|translate}}</h5>
        <p>{{'Currently lockers are not available, try again later'|translate}}</p>
      </div>

      <div *ngIf="loadingData" style="background-color: lightgray;" class="px-3 py-3">
        <h5 style="font-size: 14px; font-weight: bold;">{{'checking For locker Availability'|translate}}</h5>
        <p>{{'Please wait we are checking on the availibility of locker'|translate}}</p>
      </div>
      <div class="mt-5">
        <table class="table table-bordered">
          <caption></caption>
          <thead>
            <tr>
              <th scope="col" colspan="2">{{'New Orders'|translate}}</th>

            </tr>
          </thead>
          <thead>
            <tr class="table-info">
              <th scope="col">{{'Date Order'|translate}}</th>
              <th scope="col">{{'Name'|translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let value of orderResult; let i = index">
              <td>{{value.reservePeriodFrom| date:'dd-MM-yyyy'}} - {{value.reservePeriodTo| date:'dd-MM-yyyy'}}</td>
              <td>Anonymous, anonymous customer</td>
            </tr>
            <tr>
              <td colspan="2">
                <button class="btn btn-danger" style="float:right;" (click)="goToNewOrder()">{{'See More'|translate}}</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class=" col col-md-6">
      <table class="table table-bordered">
        <caption></caption>
        <thead>
          <tr>
            <th scope="col" colspan="3">{{'toBeProcessed'|translate}}</th>

          </tr>
        </thead>
        <thead>
          <tr class="table-info">

            <th class="date-column" scope="col">{{'Name'|translate}}</th>
            <th scope="col">{{'Status'|translate}}</th>
            <th scope="col">{{'whenToPickUp'|translate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let value of orderToProcess; let i = index">
            <td>Anonymous, anonymous customer</td>
            <td>{{value.currentStatus}}</td>
            <td>{{value.reservePeriodFrom| date:'dd-MM-yyyy'}} - {{value.reservePeriodTo| date:'dd-MM-yyyy'}}</td>
          </tr>
          <tr>
            <td colspan="3">
              <button class="btn btn-danger" style="float:right;" (click)="goToPlacedOrder()">{{'See More'|translate}}</button>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="table table-bordered mt-5">
        <caption></caption>
        <thead>
          <tr>
            <th scope="col" colspan="3">{{'placedinLocker'|translate}}</th>
          </tr>
        </thead>
        <thead>
          <tr class="table-info">
            <th class="date-column" scope="col">{{'Name'|translate}}</th>
            <th scope="col">{{'Status'|translate}}</th>
            <th scope="col">{{'whenToPickUp'|translate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let value of vaultResponse; let i = index">
            <td>Anonymous, anonymous customer</td>
            <td>{{value.currentStatus}}</td>
            <td>{{value.reservePeriodFrom| date:'dd-MM-yyyy'}} - {{value.reservePeriodTo| date:'dd-MM-yyyy'}}</td>
          </tr>
          <tr>
            <td colspan="3">
              <button class="btn btn-danger" style="float:right;" (click)="goToVault()">{{'See More'|translate}}</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
