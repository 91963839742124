<app-loader *ngIf="showSpinner"></app-loader>
<app-header></app-header>
<div class="container">
    <br />
    <div class="row">
        <div class="col-3">
            <app-partner-info-menu [partner]=partnerDetails></app-partner-info-menu>
        </div>
        
        <div class="col-9">
            <h3>Postman codes generation</h3>
            <form [formGroup]="settingForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                  <label for="exampleFormControlSelect1">Method to generate codes</label>
                  <select
                    formControlName="method"
                    class="form-control">
                        <option value="standard">Standard: each postman have unique credentials.</option>
                        <option value="bh-postalcode">B&H: credentials are based on postal code</option>
                  </select>
                </div>
                <button class="btn btn-secondary  mr-1" (click)="goToPartnerDetails()">{{'Back'|translate}}</button>&nbsp;
                <button type="submit" class="btn btn-primary">Submit</button>
              </form>
        </div>
    </div>
</div>